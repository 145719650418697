import { PaymentType } from 'pages/activity/components/ActivityTable/types/transaction_flow';

export enum TransactionSpeed {
  NEXT_DAY = 'next_day',
  SAME_DAY = 'same_day',
}

export enum TransactionType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  RENT = 'Rent Payment',
}

export enum TransactionStatus {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  WAIT = 'wait',
}

export interface TransactionAttributes {
  type: TransactionType;
  speed: TransactionSpeed;
  status: TransactionStatus;
  amount: number;
  bank_name: string;
  date_created: string;
  date_completed: string;
  is_cancellable: boolean;
  settlement_date: string;
  account_last_digits: string;
  cancel_reason: string | null;
  date_to_execute: string | null;
  payment_type: PaymentType;
  recurring_action?: {
    interval: string;
    next_execution_date: Date;
    performing: boolean;
  };
}

export interface TransactionLimits {
  hasLimit: boolean;
  limitComment: string;
  maxAmount: number;
}

export const CANCELABLE_TRANSACTION_STATUSES = [
  TransactionStatus.CREATED,
  TransactionStatus.PENDING,
  TransactionStatus.WAIT,
];
