import useKyc from 'hooks/requests/kyc/useKyc';
import { useEffect } from 'react';
import { KYCIdentitiesAttributes } from 'types/kyc-identities-types';

const HELP_SCOUT_ATTRIBUTES: {
  [key: string]: (kycIdentity: KYCIdentitiesAttributes) => any;
} = {
  account_kind: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.account_kind,
  account_number: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.account_number,
  date_of_birth: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.date_of_birth,
  external_object_id: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.external_object_id,
  first_name: (kycIdentity: KYCIdentitiesAttributes) => kycIdentity?.first_name,
  middle_name: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.middle_name,
  has_basic_kyc_info: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.has_basic_kyc_info,
  is_allowed_to_trade: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.is_allowed_to_trade,
  kyc_comments: (kycIdentity: KYCIdentitiesAttributes) =>
    JSON.stringify(kycIdentity?.kyc_comments),
  last_name: (kycIdentity: KYCIdentitiesAttributes) => kycIdentity?.last_name,
  missing_personal_information: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.missing_personal_information,
  verification_status: (kycIdentity: KYCIdentitiesAttributes) =>
    kycIdentity?.verification_status,
};

const HelpScout = () => {
  const { kycIdentities: kycIdentity } = useKyc();

  useEffect(() => {
    if (!kycIdentity || !kycIdentity.attributes) return;

    const identifyObject = Object.keys(HELP_SCOUT_ATTRIBUTES).reduce(
      (acc: any, key) => {
        const value = HELP_SCOUT_ATTRIBUTES[key](kycIdentity.attributes);
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    window.Beacon('identify', identifyObject);
  }, [kycIdentity]);

  useEffect(() => {
    window.Beacon('init', process.env.NEXT_PUBLIC_HELPSCOUT_BEACON);
    window.Beacon('config', { hideFABOnMobile: true });
    return () => {
      window.Beacon('destroy');
    };
  }, []);

  return null;
};

export default HelpScout;
