import { FEED_ITEMS_SEARCH } from 'graphql/feed/queries/feedItemsSearch';
import { GQL_FETCHERS } from 'hooks/api/SWR/graphql/gqlFetchers';
import { useGraphqlMutation } from 'hooks/api/SWR/graphql/hooks/useGraphqlMutation';
import { useEffect } from 'react';
import { GraphqlEdge } from 'types/graphql-types';

export const useSearchPreviewFetcher = (searchTerm: string) => {
  const { data, isMutating, trigger } = useGraphqlMutation(
    GQL_FETCHERS.REAL_ESTATE_GQL_FETCHER,
    FEED_ITEMS_SEARCH,
    { first: 3, searchTerm }
  );

  useEffect(() => {
    if (!searchTerm) return;
    trigger();
  }, [searchTerm]);

  return {
    data: data?.homeFeedSearch?.edges.map((item: GraphqlEdge) => item.node),
    loading: isMutating,
  };
};
