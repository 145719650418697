import TransactionFormContainer from 'components/dialogs/TransferDialog/components/TransactionFormContainer';
import TransactionSummaryBottomSection from 'components/dialogs/TransferDialog/components/TransactionSummary/components/TransactionSummaryBottomSection';
import TransactionSummaryCircle from 'components/dialogs/TransferDialog/components/TransactionSummary/components/TransactionSummaryCircle';
import TransactionSummaryDetails from 'components/dialogs/TransferDialog/components/TransactionSummary/components/TransactionSummaryDetails';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { FlowComponentProps } from 'components/generics/flows/Flow';
import Money from 'components/generics/texts/Money';
import React from 'react';
import classes from './index.module.scss';

interface Props extends FlowComponentProps {
  error?: string;
  goBack: (numSteps?: number) => void;
}

const TransactionSummary = ({ onFinish, goBack, loading }: Props) => {
  const { amount } = useTransaction();

  return (
    <TransactionFormContainer className={classes.container}>
      <div className={classes.topSection}>
        <TransactionSummaryCircle className={classes.circle} />
        <TransactionSummaryDetails />
      </div>
      <Money
        value={Number(amount)}
        className={classes.money}
      />
      <TransactionSummaryBottomSection
        goBack={goBack}
        className={classes.bottomContainer}
        onFinish={onFinish}
        loading={loading}
      />
    </TransactionFormContainer>
  );
};

export default TransactionSummary;
