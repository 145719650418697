import { gql } from '@apollo/client';

export const PORTFOLIO_QUERY = gql`
  query PortfolioQuery {
    account {
      cash
      withdrawableCash
      pendingUnusedFunds
      shares
      equity
      netDeposits
      totalDividends
      avgMonthlyDividend
      properties {
        property {
          ticker
          propertyType
          address {
            houseNumber
            street
            city {
              name
            }
            state {
              code
              name
            }
            zipCode
          }
          photos {
            uri
          }
          coordinates {
            longitude
            latitude
          }
          ownershipStatus
          propertyType
          units {
            ticker
            photos {
              uri
            }
          }
        }
        shares
        allocatedShares
        equity
        gains
      }
      history {
        dividends {
          shares
          valueSnapshot {
            asOf
            value
          }
        }
        accountValue {
          asOf
          value
        }
        netDeposits {
          asOf
          value
        }
      }
      didFirstOrder
      didFirstDeposit
      didFirstBigOrder
      didFirstBigDeposit
      isDocumentsUploaded
      discountPromotionEndDate
    }
  }
`;
