declare global {
  interface Array<T> {
    addOrRemove(this: Array<T>, value: T): Array<T>;

    forcePushIndex(
      this: Array<T>,
      index: number,
      value: T,
      fillWith?: T
    ): Array<T>;
  }
}

Array.prototype.addOrRemove = function (this, value) {
  const index = this.indexOf(value);

  if (index > -1) {
    this.splice(index, 1);
  } else {
    this.push(value);
  }
  return this;
};

Array.prototype.forcePushIndex = function (
  this,
  index,
  value,
  fillWith = undefined
) {
  if (this.length > index) {
    this[index] = value;
  } else {
    while (this.length < index) this.push(fillWith);
    this.push(value);
  }
  return this;
};

export {};
