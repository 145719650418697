import axios from 'axios';
import {
  API_REQUEST_START,
  API_REQUEST_SUCCEED,
} from 'consts/analytics/jsonApiEvents';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useState } from 'react';
import {
  HookError,
  HookReturnType,
  RequestConfig,
  Response,
} from 'types/axios-types';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;

const useOldAxios = <BodyDataType>(): HookReturnType<BodyDataType> => {
  const [error, setError] = useState<HookError>();
  const [response, setResponse] = useState<Response>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchData = async (config: RequestConfig<BodyDataType>) => {
    setIsLoading(true);
    analytics.track(API_REQUEST_START, config);
    let result;
    try {
      result = await axios(config);
      setResponse(result);
      analytics.track(API_REQUEST_SUCCEED, result);
    } catch (error: unknown) {
      const resultedError = axios.isAxiosError(error)
        ? { axiosError: error }
        : { baseError: error };
      setError(resultedError);
    } finally {
      setIsLoading(false);
    }
    return result;
  };

  return {
    error,
    response,
    isLoading,
    fetchData,
  };
};

export default useOldAxios;
