import { useTransaction } from 'components/dialogs/TransferDialog/context';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import dateFormat from 'dateformat';
import useBankName from 'hooks/requests/financials/useBankName';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const ConfirmTransactionDetails = ({
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  const { bankName } = useBankName();
  const { amount, speedOptions } = useTransaction();
  return (
    <div className={className}>
      <div className={classes.row}>
        <Typography>Amount</Typography>
        <Money value={Number(amount)} />
      </div>
      <div className={classes.row}>
        <Typography>From</Typography>
        <Typography>{bankName}</Typography>
      </div>
      <div className={classes.row}>
        <Typography>Est Settlement</Typography>
        <Typography>
          {dateFormat(
            new Date(speedOptions?.settlementDateOrdinary as Date),
            'ddd, mmmm yy'
          )}
        </Typography>
      </div>
    </div>
  );
};

export default ConfirmTransactionDetails;
