import classNames from 'classnames';
import UserStatusCTA from 'components/features/CTAs/UserStatus';
import LandaTheme from 'components/layouts/LandaTheme';
import HeaderLogo from 'components/layouts/NavBar/components/HeaderLogo';
import UserSettingsMenu from 'components/layouts/NavBar/components/UserSettingsMenu';
import useAuth from 'context/Auth';
import { motion } from 'framer-motion';
import useResponsive from 'hooks/logics/useResponsive';
import SearchBar from 'pages/feed/components/SearchBar';
import {
  SearchBarProvider,
  useSearchBar,
} from 'pages/feed/components/SearchBar/context';
import React, { useEffect, useRef } from 'react';
import { THEMEABLE_COMPONENTS } from 'stores/LandaTheme/landaTheme';
import useLayoutStore from 'stores/Layout/layout';
import classes from './index.module.scss';
import HeaderMenu from './components/HeaderMenu';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';

type Props = React.HTMLAttributes<HTMLDivElement>;

const NavBar = ({ className }: Props) => {
  return (
    <SearchBarProvider>
      <NavBarWithProviders className={className} />
    </SearchBarProvider>
  );
};

const NavBarWithProviders = ({ className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const setHeight = useLayoutStore((state) => state.setNavBarHeight);
  const { isLoggedIn, initialized: authInitialized } = useAuth();
  const { open } = useSearchBar();
  const { userDetails } = useUserDetails();
  const { isMobile, isDesktop } = useResponsive();
  const openOnMobile = isMobile && open;

  useEffect(() => {
    if (!ref) return;
    setHeight(ref.current?.offsetHeight || 0);
  }, [ref]);

  return (
    <LandaTheme
      id={THEMEABLE_COMPONENTS.NAV_BAR}
      ref={ref}
      className={classNames(classes.navBar, className)}
    >
      <HeaderLogo
        className={classNames(
          classes.headerLogo,
          open && classes.searchBarOpen
        )}
      />
      <HeaderMenu />
      {authInitialized && (
        <motion.div className={classes.middleSection}>
          <SearchBar />
        </motion.div>
      )}
      {authInitialized && (
        <UserStatusCTA
          className={classNames(classes.cta, open && classes.searchBarOpen)}
        />
      )}
      {isDesktop && !openOnMobile && isLoggedIn && (
        <UserSettingsMenu
          className={classNames(
            classes.userSettings,
            open && classes.searchBarOpen
          )}
        />
      )}
    </LandaTheme>
  );
};

export default NavBar;
