import Select, { GroupBase, Props } from 'react-select';
import classes from './index.module.scss';

const LandaSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  return (
    <Select
      className={classes.select}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: 'var(--color-cloud)',
        }),
      }}
      {...props}
    />
  );
};

export default LandaSelect;
