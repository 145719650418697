import { gql } from '@apollo/client';

export const PORTFOLIO_TRANSACTION_LIMITS_QUERY = gql`
  query getPortfolioTransactionLimits {
    account {
      portfolioTransactionLimits {
        dayLimit
        weekLimit
        monthLimit
        yearLimit
      }
    }
  }
`;
