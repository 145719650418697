import { useEffect } from 'react';
import { useLandaApiLazyQuery } from '../../../../hooks/api/graphql/landa_api';
import { TRANSACTION_LIMIT_QUERY } from '../../../../graphql/account/queries/transactionLimitQuery';
import {
  TransactionLimits,
  TransactionType,
} from '../../../../pages/activity/components/ActivityTable/types/transaction';

const useTransactionLimits = (transactionType: TransactionType | undefined) => {
  const [request, { loading, data }] = useLandaApiLazyQuery(
    TRANSACTION_LIMIT_QUERY,
    {
      variables: {
        transactionType: transactionType,
      },
    }
  );

  useEffect(() => {
    if (!transactionType) return;

    request();
  }, [transactionType]);

  return {
    transactionLimits: data?.transactionLimit as TransactionLimits,
    loading,
  };
};

export default useTransactionLimits;
