import React, { HTMLAttributes } from 'react';

const RegularLayout = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <>
      <div style={{ height: '100vh' }}>{children}</div>
    </>
  );
};

export default RegularLayout;
