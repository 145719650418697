import { gql } from '@apollo/client';

export const ACCOUNT_DETAILS = gql`
  mutation AccountDetails(
    $profile_id: String!
    $connection_id: String!
    $connector_id: String!
    $user_token: String!
  ) {
    accountDetails(
      profileId: $profile_id
      connectionId: $connection_id
      connectorId: $connector_id
      userToken: $user_token
    ) {
      ok
    }
  }
`;
