import { merge } from 'lodash';
import { createAndResetOnLogout } from 'stores/utils';
import { UserDetailsTypes } from 'types/user-details-types';
import { persist } from 'zustand/middleware';

interface UserDetailsStore {
  userDetails?: UserDetailsTypes;
  setUserDetails: (userDetails: UserDetailsTypes) => void;
  setFCMToken: (fcmToken: string) => void;
}

const useUserDetailsStore = createAndResetOnLogout<UserDetailsStore>()(
  persist(
    (set) => ({
      userDetails: undefined,
      setUserDetails: (newUserDetails) => {
        set(({ userDetails }) => ({
          userDetails: merge(userDetails, newUserDetails),
        }));
      },
      setFCMToken: (fcmToken: string) => {
        set((state) => {
          if (!state.userDetails) return state;
          return {
            ...state,
            userDetails: {
              ...state.userDetails,
              fcmToken,
            },
          };
        });
      },
    }),
    { name: 'user' }
  )
);

export default useUserDetailsStore;
