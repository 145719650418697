import { gql } from '@apollo/client';

export const GET_CONFLICTING_ORDERS = gql`
  mutation ($realAmount: Float!) {
    getConflictingOrdersMutation(realAmount: $realAmount) {
      orders {
        id
        amount
        kind
        targetValue
        property {
          ticker
          address {
            street
            houseNumber
            city {
              name
            }
            state {
              code
            }
            zipCode
          }
          photos(limit: 1) {
            uri
          }
        }
      }
    }
  }
`;
