import { SegmentEvent } from '@segment/analytics-next';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import UAParser from 'ua-parser-js';

export const customizeTrack = (
  track: any,
  func?: (event: string | SegmentEvent, options?: object | null) => void
) => {
  analytics.track = (event: string | SegmentEvent, options?: object | null) => {
    func && func(event, options);
    return track(event, {
      ...options,
      platform: 'Web',
      ...new UAParser(navigator.userAgent).getResult(), //  Device details
    });
  };
};
