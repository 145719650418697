import CircleLandaLoader from 'components/animations/Loaders/CircleLandaLoader';
import TransactionBackgroundAnimation from 'components/dialogs/TransferDialog/components/TransactionLoading/components/BackgroundAnimation';
import useCreateTransaction from 'components/dialogs/TransferDialog/components/TransactionLoading/hooks/useCreateTransaction';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { FlowComponentProps } from 'components/generics/flows/Flow';
import React, { useEffect, useMemo } from 'react';
import classes from './index.module.scss';

const TransactionLoading = ({ onFinish }: FlowComponentProps) => {
  const [loading, setLoading] = React.useState(true);
  const { setResponse } = useTransaction();
  const { createTransaction, success, errorMessage } = useCreateTransaction();

  useEffect(() => {
    createTransaction().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        onFinish();
      }, 3500);
    }
  }, [loading, success]);

  useEffect(() => {
    setResponse({
      success,
      errorMessage,
    });
  }, [success, errorMessage]);

  const Animation = useMemo(() => <TransactionBackgroundAnimation />, []);

  return (
    <div className={classes.container}>
      {Animation}
      <CircleLandaLoader
        className={classes.loader}
        isLoading={loading}
        isSuccessful={success}
      />
    </div>
  );
};

export default TransactionLoading;
