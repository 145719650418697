type RGB = {
  red: number;
  green: number;
  blue: number;
};

export const arrayify: <T>(value: T | T[] | undefined) => T[] = (value) => {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
};

export const stringOrFunction: (
  value: string | CallableFunction,
  props: object
) => string = (value, props) => {
  if (typeof value === 'string') return value;
  return value(props);
};

export const checkIsHex = (target?: string) => {
  if (!target) return false;
  return /^#[A-z,0-9]{3}$|^#[A-z,0-9]{6}$/.test(target);
};

export const rgbToHex = ({ red, green, blue }: RGB) => {
  return (
    '#' + ((1 << 24) | (red << 16) | (green << 8) | blue).toString(16).slice(1)
  );
};

export const hexToRgb = (hex: string) => {
  const isHex = checkIsHex(hex);
  if (!isHex) throw new Error(`Value is not a HEX: ${hex}`);
  let result;
  if (hex.length - 1 === 6) {
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  }
  if (hex.length - 1 === 3) {
    result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
  }
  if (!result) throw new Error(`(Invalid HEX: ${hex}`);

  const [, red, green, blue] = result;
  return {
    r: parseInt(red, 16),
    g: parseInt(green, 16),
    b: parseInt(blue, 16),
  };
};

export const transparifyHexToRgba = (hex: string, opacity: string) =>
  `rgba(${Object.values(hexToRgb(hex)).join(', ')}, ${opacity})`;
