import classNames from 'classnames';
import { motion } from 'framer-motion';
import useResponsive from 'hooks/logics/useResponsive';
import SearchBarInput from 'pages/feed/components/SearchBar/components/SearchBarInput';
import SearchBarOverlay from 'pages/feed/components/SearchBar/components/SearchBarOverlay';
import SearchResults from 'pages/feed/components/SearchBar/components/SearchResults';
import SearchResultsContainer from 'pages/feed/components/SearchBar/components/SearchResultsContainer';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import { SEARCH_BAR_CONTAINER_ANIMATION_VARIANTS } from 'pages/feed/components/SearchBar/utils';
import React from 'react';
import classes from './index.module.scss';

const SearchBar = () => {
  const { open } = useSearchBar();
  const { isMobile } = useResponsive();

  return (
    <motion.div className={classNames(classes.container)}>
      <motion.div
        className={classes.innerContainer}
        initial={'closed'}
        animate={open ? 'opened' : 'closed'}
        variants={SEARCH_BAR_CONTAINER_ANIMATION_VARIANTS(isMobile)}
      >
        <SearchBarInput />
        <SearchResultsContainer>
          <SearchResults />
        </SearchResultsContainer>
      </motion.div>
      {open && <SearchBarOverlay />}
    </motion.div>
  );
};

export default SearchBar;
