import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  API_REQUEST_FAILED,
  API_REQUEST_START,
  API_REQUEST_SUCCEED,
} from 'consts/analytics/jsonApiEvents';
import { redactHeaders } from 'hooks/api/graphql/links';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { safeJsonParse } from 'utils/json_utils';

export const trackJsonStart = (
  options: AxiosRequestConfig,
  track?: boolean
) => {
  if (options.url && options.url.indexOf('/api/') !== -1 && track !== false) {
    analytics.track(API_REQUEST_START, {
      ...redactHeaders(options.headers),
      kind: 'drf',
      fullUrl: options.url,
      method: options.method || 'GET',
      url: `/${options.url.substring(
        options.url.lastIndexOf('/') + 1,
        options.url.length
      )}`,
      data: JSON.stringify(options.data && safeJsonParse(options.data)),
    });
  }
};

export const trackJsonResponse = (
  response: AxiosResponse,
  track?: boolean,
  fail?: boolean,
  url?: string
) => {
  if (track !== false) {
    analytics?.track?.(fail ? API_REQUEST_FAILED : API_REQUEST_SUCCEED, {
      ...(redactHeaders(response.headers) || {}),
      kind: 'drf',
      status: response.status,
      body: response.data,
      url: response?.config?.url || url,
    });
  }
};
