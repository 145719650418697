import classNames from 'classnames';
import Button from 'components/generics/buttons/Button';
import BackIcon from 'public/assets/icons/chevron_left.svg';
import { HTMLAttributes } from 'react';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  expand: boolean;
  toggleExpand: () => void;
}

const DrawerToggleButton = ({ expand, toggleExpand, className }: Props) => {
  return (
    <Button
      variant="text"
      onClick={toggleExpand}
      className={classNames(classes.drawerToggleButton, className)}
      aria-label="Toggle drawer"
    >
      <BackIcon
        className={classNames(classes.icon, expand && classes.expand)}
      />
    </Button>
  );
};

export default DrawerToggleButton;
