const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getStartOfMonth = () => {
  const now = new Date().toUTC();
  now.setHours(0, 0, 0, 0);
  now.setDate(1);
  return now;
};

const monthIcons = [
  '☃',
  '💝',
  '🍀',
  '🐰',
  '🌷',
  '🍦',
  '🇺🇸',
  '☀',
  '🍂',
  '🎃',
  '🦃',
  '🎅',
];

declare global {
  interface Date {
    getMonthName(this: Date): string;

    getMonthIcon(this: Date): string;

    toString(this: Date, format: string): string;

    getDiff(this: Date, otherDate: Date, duration: number): number;

    daysDiff(this: Date, otherDate?: Date): number;

    toUTC(this: Date): Date;

    addMonths(this: Date, num: number): Date;
  }
}

Date.prototype.getMonthName = function () {
  return monthNames[this.getMonth()];
};

Date.prototype.getMonthIcon = function () {
  return monthIcons[this.getMonth()];
};

Date.prototype.getDiff = function (otherDate, duration) {
  const thisDateTime = this.getTime();
  const otherDateTime = new Date().getTime();
  return Math.abs((thisDateTime - otherDateTime) / duration);
};

Date.prototype.daysDiff = function (otherDate) {
  const dayDuration = 1000 * 60 * 60 * 24;
  otherDate = otherDate || new Date();
  return this.getDiff(otherDate, dayDuration);
};

Date.prototype.toUTC = function () {
  return new Date(
    this.getUTCFullYear(),
    this.getUTCMonth(),
    this.getUTCDate(),
    this.getUTCHours(),
    this.getUTCMinutes(),
    this.getUTCSeconds()
  );
};

Date.prototype.addMonths = function (num) {
  return new Date(new Date(this).setMonth(this.getMonth() + num));
};

export {};
