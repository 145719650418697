import { gql } from '@apollo/client';

export const CREATE_MANUAL_FINANCIAL_CONNECTION = gql`
  mutation (
    $routingNumber: String!
    $accountNumber: String!
    $accountName: String!
    $accountType: String!
  ) {
    createManualFinancialConnection(
      routingNumber: $routingNumber
      accountNumber: $accountNumber
      accountName: $accountName
      accountType: $accountType
    ) {
      ok
    }
  }
`;
