import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DEFAULT_HEADERS } from 'hooks/api/SWR/headers';
import { identity, pickBy } from 'lodash';

export const axiosFetcher = async (options: AxiosRequestConfig) => {
  const defaultHeaders = {
    ...DEFAULT_HEADERS,
    ...(options.headers || {}),
  };
  const cleanedObject = pickBy(defaultHeaders, identity);
  try {
    return (await axios({ ...options, headers: cleanedObject })).data;
  } catch (e) {
    const axiosError = e as AxiosError;
    throw axiosError.response?.data;
  }
};
