import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  API_REQUEST_FAILED,
  API_REQUEST_START,
  API_REQUEST_SUCCEED,
} from 'consts/analytics/jsonApiEvents';
import { OperationDefinitionNode } from 'graphql/language/ast';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import omit from 'lodash/omit';

export const redactHeaders = (headers: any) => {
  const REDACT_KEYS = ['Authorization', 'x-api-key', 'X-LND-Fingerprint'];
  headers = headers || {};
  return omit(headers, REDACT_KEYS);
};

export const logLink = new ApolloLink((operation, forward) => {
  const currentContext = operation.getContext();
  const shouldTrack = currentContext.track !== false;
  const headers = redactHeaders(currentContext.headers);
  operation.setContext({ start: new Date() });

  const baseData = {
    kind: 'graphql',
    variables: operation.variables,
    graphqlName: operation.operationName,
    method: (operation.query.definitions[0] as OperationDefinitionNode)
      .operation,
  };

  shouldTrack &&
    analytics?.track(API_REQUEST_START, {
      ...baseData,
      ...headers,
    });

  return forward(operation).map((result) => {
    const currentContext = operation.getContext();
    const shouldTrack = currentContext.track !== false;
    const duration = (new Date().getTime() - currentContext.start) / 1000;

    shouldTrack &&
      analytics.track(API_REQUEST_SUCCEED, {
        ...(currentContext?.response?.headers?.map || {}),
        ...baseData,
        body: result,
        duration,
      });
    return result;
  });
});

export const errorLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    const currentContext = operation.getContext();
    const shouldTrack = currentContext.track !== false;
    const duration = (new Date().getTime() - currentContext.start) / 1000;

    shouldTrack &&
      analytics.track(API_REQUEST_FAILED, {
        ...(currentContext?.response?.headers?.map || {}),
        duration,
        kind: 'graphql',
        variables: operation.variables,
        graphqlName: operation.operationName,
        method: (operation.query.definitions[0] as OperationDefinitionNode)
          .operation,
        body: {
          graphQLErrors,
          networkError,
        },
      });
  }
);
