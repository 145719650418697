import { SegmentEvent } from '@segment/analytics-next';
import { Environment } from 'consts/environment';
import useAuth from 'context/Auth';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { customizeTrack } from 'hooks/integrations/mixpanel/utils';
import useMounted from 'hooks/logics/useMounted';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const useSingular = () => {
  const { SingularConfig, singularSdk } = require('singular-sdk');
  const mounted = useMounted();
  const { accountNumber } = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!mounted || process.env.NEXT_PUBLIC_ENV !== Environment.PRODUCTION)
      return;
    const config = new SingularConfig(
      process.env.NEXT_PUBLIC_SINGULAR_KEY,
      process.env.NEXT_PUBLIC_SINGULAR_SECRET,
      process.env.NEXT_PUBLIC_SINGULAR_PRODUCT_ID
    );
    singularSdk.init(config);
    setInitialized(true);
  }, [mounted]);

  useEffect(() => {
    if (!initialized || !accountNumber) return;
    singularSdk.login(accountNumber);
  }, [initialized, accountNumber]);

  useEffect(() => {
    if (!initialized || !!accountNumber) return;
    singularSdk.logout();
  }, [initialized, accountNumber]);

  useEffect(() => {
    if (!initialized) return;
    const func = (event: string | SegmentEvent, options?: object | null) => {
      const expendedProperties = cloneDeep(options) as any;
      singularSdk.event(event, expendedProperties);
      if (expendedProperties?.value) {
        singularSdk.revenue(
          event,
          'USD',
          expendedProperties.value,
          expendedProperties
        );
      }
    };
    customizeTrack(analytics.track, func);
  }, [initialized]);
};

export default singletonHook(undefined, useSingular);
