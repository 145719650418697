import classNames from 'classnames';
import ResolutionImage, {
  ResolutionProps,
} from 'components/generics/images/ResolutionImage';
import Typography from 'components/generics/texts/Typography';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import states from 'consts/states';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import { HTMLAttributes } from 'react';
import { usePropertiesStore } from 'stores/Properties/properties';
import { PropertyType } from 'types/property-types';
import {
  encodeTicker,
  propertyAddressPathGenerator,
} from 'utils/property_utils';
import classes from './index.module.scss';

const IMAGE_SIZE = { height: 3 * 16, width: 3 * 16 };
const IMAGE_RESOLUTION = { height: 8 * 16, width: 8 * 16 };

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'property'>,
    Partial<ResolutionProps> {
  ticker?: string;
  property?: PropertyType;
  disabled?: boolean;
}

const PropertyDetailsRow = ({
  ticker,
  property,
  disabled,
  className,
  imageSize,
  imageResolution,
  ...props
}: Props) => {
  const { closeSearch } = useSearchBar();
  const currentStoreProperty = usePropertiesStore(
    (state) => state.properties[ticker || '']
  );
  const currentProperty = property || currentStoreProperty;
  const propertyType = currentProperty.propertyType;
  const propertyTypeText = PROPERTY_TYPE_TO_TEXT[propertyType] || propertyType;

  const { navigate } = useLandaRouter();
  const path = propertyAddressPathGenerator(currentProperty?.address);

  const onClick = () => {
    if (disabled) return;

    closeSearch();
    navigate({
      pathname: `/property/${path}/${encodeTicker(currentProperty?.ticker)}`,
    });
  };

  if (!currentProperty) return <div />;
  return (
    <div
      onClick={onClick}
      className={classNames(
        classes.container,
        className,
        disabled && classes.disabledContainer
      )}
      {...props}
    >
      <ResolutionImage
        className={classes.image}
        alt={currentProperty.ticker}
        src={currentProperty.photos[0].uri}
        imageSize={imageSize || IMAGE_SIZE}
        imageResolution={imageResolution || IMAGE_RESOLUTION}
      />
      <div className={classes.detailsContainer}>
        <Typography className={classes.title}>
          {currentProperty.address.houseNumber} {currentProperty.address.street}
        </Typography>
        <Typography className={classes.subtitle}>
          {propertyTypeText}, {states[currentProperty.address.state.code].name}
        </Typography>
      </div>
    </div>
  );
};

export default PropertyDetailsRow;
