import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql/language';
import useLandaLazyQuery, {
  OptionsType,
} from 'hooks/api/graphql/functions/useLandaLazyQuery';
import { useEffect } from 'react';

const useLandaQuery = <TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  outerOptions: OptionsType<TData, TVariables> & { skip?: boolean }
) => {
  const [queryFunction, props] = useLandaLazyQuery<TData, TVariables>(
    query,
    outerOptions
  );

  useEffect(() => {
    if (!outerOptions.skip) {
      queryFunction();
    }
  }, [outerOptions.skip]);

  return props;
};

export default useLandaQuery;
