import classNames from 'classnames';
import classes from 'components/features/CTAs/UserStatus/components/NotLoggedInStatus/index.module.scss';
import Button, { ButtonProps } from 'components/generics/buttons/Button';
import { GET_STARTED } from 'consts/analytics/onBoardingEvents';
import { RESIDENTS_SIGNUP_ROUTE, SIGNUP_ROUTE } from 'consts/routes/urls';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { useRouter } from 'next/router';
import React from 'react';

const NotLoggedInStatus = ({ className }: ButtonProps): JSX.Element | null => {
  const { navigate } = useLandaRouter();
  const isResident = useRouter().pathname.includes('/residents');

  return (
    <Button
      className={classNames(classes.button, classes.signUpButton, className)}
      onClick={() => {
        analytics.track(GET_STARTED);

        if (isResident) {
          navigate(RESIDENTS_SIGNUP_ROUTE);
        } else {
          navigate(SIGNUP_ROUTE);
        }
      }}
    >
      Get Started
    </Button>
  );
};

export default NotLoggedInStatus;
