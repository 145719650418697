import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import React from 'react';
import { RecurringActionInterval } from 'types/recurring-action-types';

export const TRANSACTION_TYPE_OPTIONS = [
  {
    label: 'Deposit',
    value: TransactionType.DEPOSIT,
  },
  {
    label: 'Withdrawal',
    value: TransactionType.WITHDRAWAL,
  },
];

export const TRANSACTION_FREQUENCY_OPTIONS = [
  {
    label: 'Just once',
    value: RecurringActionInterval.JUST_ONCE,
  },
  {
    label: 'Monthly',
    value: RecurringActionInterval.MONTHLY,
  },
];
