import { Environment } from 'consts/environment';

export const DEFAULT_HEADERS = {
  'X-LND-AppVersion': '1.0.0',
  'X-LND-Origin': 'Web-Investors',
  'X-LND-Environment':
    process.env.NEXT_PUBLIC_ENV === Environment.PRODUCTION
      ? 'production'
      : 'development',
  'X-LND-OS': 'browser',
  'X-LND-TimeZoneOffset': new Date().getTimezoneOffset().toString(),
};

export const RESIDENT_HEADERS = {
  'X-LND-AppVersion': '1.0.0',
  'X-LND-Origin': 'Residents',
  'X-LND-Environment':
    process.env.NEXT_PUBLIC_ENV === Environment.PRODUCTION
      ? 'production'
      : 'development',
  'X-LND-OS': 'browser',
  'X-LND-TimeZoneOffset': new Date().getTimezoneOffset().toString(),
  Resident: 'false',
};
