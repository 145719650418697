export const SEARCH_RESULTS_CONTAINER_VARIANTS = {
  opened: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

export const SEARCH_RESULTS_CONTAINER_EXIT = {
  opacity: 0,
};
