import { gql } from '@apollo/client';

export const CREATE_TRANSACTION_MUTATION = gql`
  mutation (
    $amount: Float!
    $speed: String!
    $recurringInterval: String!
    $transactionType: String!
  ) {
    createTransactionMutation(
      amount: $amount
      speed: $speed
      recurringInterval: $recurringInterval
      transactionType: $transactionType
    ) {
      ok
    }
  }
`;
