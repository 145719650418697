import RegularLayout from 'components/layouts/RegularLayout';
import {
  ACTIVITY_ROUTE,
  FEED_ROUTE,
  HOME_ROUTE,
  IPO_ROUTE,
  KYC_ROUTE,
  KYC_VERIFY_ID_ROUTE,
  LEND_ROUTE,
  LOGIN_ROUTE,
  MARKET_ROUTE,
  NOT_FOUND_ROUTE,
  PLACE_ORDER_ROUTE,
  PORTFOLIO_ROUTE,
  PROFILE_ROUTE,
  PROPERTY_ROUTE,
  RESIDENTS_FEED_ROUTE,
  RESIDENTS_KYC_REVIEW_ROUTE,
  RESIDENTS_KYC_ROUTE,
  RESIDENTS_PROPERTY_ROUTE,
  RESIDENTS_RENT_PAY_ROUTE,
  RESIDENTS_RENT_SIGN_RENT_ROUTE,
  RESIDENTS_SIGNUP_ROUTE,
  SIGNUP_ROUTE,
} from 'consts/routes/urls';
import React from 'react';
import IntroLayout from './IntroLayout';
import NavBarLayout from './NavBarLayout';

export type LayoutItem = {
  layout: React.FC;
  props: object;
};

const NavBarLayoutBase = (isMobile: boolean, isTenant: boolean) => ({
  layout: NavBarLayout,
  props: {
    withFooter: isMobile && isTenant ? false : true,
  },
});

const NavBarWithoutFooter = (isMobile: boolean, isTenant: boolean) => ({
  layout: NavBarLayout,
  props: {
    withFooter: isMobile && isTenant ? false : true,
  },
});

const NavBarWithoutDrawer = {
  layout: NavBarLayout,
  props: {
    withDrawer: false,
  },
};

const NavBarWithoutDrawerFooter = (isMobile: boolean, isTenant: boolean) => ({
  layout: NavBarLayout,
  props: {
    withDrawer: isMobile ? true : false,
    withFooter: isMobile && isTenant ? false : true,
  },
});

export const DEFAULT_LAYOUT = React.Fragment;
export const ROUTES_TO_LAYOUTS = (
  isMobile: boolean,
  isTenant: boolean
): { [key: string]: React.FC | LayoutItem } => ({
  [HOME_ROUTE]: NavBarWithoutFooter(isMobile, isTenant),
  [LOGIN_ROUTE]: RegularLayout,
  [SIGNUP_ROUTE]: RegularLayout,
  [KYC_ROUTE]: IntroLayout,
  [KYC_VERIFY_ID_ROUTE]: RegularLayout,
  [ACTIVITY_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [PROPERTY_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [RESIDENTS_PROPERTY_ROUTE]: NavBarWithoutDrawerFooter(isMobile, isTenant),
  [LEND_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [PORTFOLIO_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [PLACE_ORDER_ROUTE]: NavBarWithoutDrawer,
  [FEED_ROUTE]: NavBarWithoutFooter(isMobile, isTenant),
  [RESIDENTS_FEED_ROUTE]: NavBarWithoutDrawerFooter(isMobile, isTenant),
  [MARKET_ROUTE]: NavBarWithoutFooter(isMobile, isTenant),
  [PROFILE_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [IPO_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [NOT_FOUND_ROUTE]: NavBarLayoutBase(isMobile, isTenant),
  [RESIDENTS_SIGNUP_ROUTE]: RegularLayout,
  [RESIDENTS_RENT_PAY_ROUTE]: NavBarWithoutDrawerFooter(isMobile, isTenant),
  [RESIDENTS_RENT_SIGN_RENT_ROUTE]: NavBarWithoutDrawer,
  [RESIDENTS_KYC_ROUTE]: IntroLayout,
  [RESIDENTS_KYC_REVIEW_ROUTE]: IntroLayout,
});
