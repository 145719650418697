export enum BankConnection {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export enum BankDetailsRows {
  ROUTING_NUMBER = 'routingNumber',
  ACCOUNT_NUMBER = 'accountNumber',
  ACCOUNT_NAME = 'accountName',
}
