export enum USER_STATUS {
  BANK_CONNECT_TO_CLAIM_FREE_SHARE = 'bank_connect_to_claim_free_share', // No functional
  ACCOUNT_BEFORE_KYC = 'account_before_kyc', // Redirect user to KYC flow
  ACCOUNT_PENDING = 'account_pending', // No need
  ACCOUNT_SUSPENDED = 'account_suspended', // Show the message "We are so sorry so your account was suspended, please contact our customer support to solve this issue"
  ACCOUNT_DOCUMENT = 'account_document', // No need
  ACCOUNT_RETRY = 'account_retry',
  BANK_PENDING = 'bank_pending', // Show the users that we are reviewing bank connection, show the approximate date of review, and button to contact support
  BANK_REQUIRES_VERIFICATION = 'bank_requires_verification', // We need to show micro deposit modal to verify two amount
  BANK_INCORRECT_NUMBER = 'bank_incorrect_number',
  BANK_EXPIRED = 'bank_expired', // Show users about expired bank and button to connect bank account
  BANK_LOCKED = 'bank_locked', // Show > in right side and Contact Customer Support in the left side
  CLAIM_FREE_SHARE = 'claim_free_share', // No functional
  DEFAULT = 'default', // Show No Bank Connection and Connect Bank button
}

export enum SERVER_BANK_VERIFICATION_STATUS {
  VERIFIED = 'verified',
  LOCKED = 'locked',
  EXPIRED = 'expired',
  INVALID_ACCOUNT_NUMBER = 'invalid_account_number',
  PENDING_FRAUD_VERIFICATION = 'pending_fraud_verification',
  PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
  PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
  FAILED = 'failed',
  INITIATED = 'initiated',
}
