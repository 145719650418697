import { USER_STATUS } from 'consts/User/status';
import { LANDA_DIALOGS } from '../../../dialogs/utils';

export const BUTTON_OPTIONS: { [key: string]: string } = {
  [USER_STATUS.BANK_PENDING]: 'Enter micro Deposit →',
  [USER_STATUS.DEFAULT]: 'Connect Bank',
};

export const BUTTON_ON_CLICK_DIALOG: { [key: string]: LANDA_DIALOGS } = {
  [USER_STATUS.BANK_PENDING]: LANDA_DIALOGS.MICRO_DEPOSIT,
  [USER_STATUS.DEFAULT]: LANDA_DIALOGS.MANUAL_BANK_CONNECT,
};
