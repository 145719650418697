import { FOOTER_LINKS } from 'components/footer/utils';
import Link from 'next/link';
import React from 'react';
import classes from './index.module.scss';

const FooterLinks = () => {
  return (
    <div className={classes.container}>
      {FOOTER_LINKS.map(({ title, link }) => {
        return (
          <Link
            href={link}
            className={classes.gridItem}
            key={link}
          >
            {title}
          </Link>
        );
      })}
    </div>
  );
};

export default FooterLinks;
