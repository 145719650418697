import { useTransaction } from 'components/dialogs/TransferDialog/context';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { TransactionSpeed } from 'pages/activity/components/ActivityTable/types/transaction';
import SendUp from 'public/assets/icons/send_up.svg';
import React from 'react';
import { RecurringActionInterval } from 'types/recurring-action-types';
import classes from './index.module.scss';

type Props = {
  onFinish: () => void;
};

const ConfirmTransactionButtons = ({ onFinish }: Props) => {
  const {
    setSpeed,
    fastDayText,
    ordinaryDayText,
    speedOptions,
    transactionFrequency,
  } = useTransaction();

  const onAdvance = (speed: TransactionSpeed) => {
    setSpeed(speed);
    onFinish();
  };

  const showSameDayOptionInner =
    transactionFrequency === RecurringActionInterval.JUST_ONCE &&
    speedOptions?.showSameDayOption;

  return (
    <div className={classes.container}>
      {showSameDayOptionInner && (
        <Button
          className={classes.fast}
          onClick={() => onAdvance(TransactionSpeed.SAME_DAY)}
        >
          <div className={classes.fastMiddle}>
            <SendUp />
            {fastDayText}
          </div>
          <Typography className={classes.fastRight}>
            ${speedOptions?.sameDayPricing} Fee
          </Typography>
        </Button>
      )}
      <Button
        variant={showSameDayOptionInner ? 'secondary' : 'primary'}
        className={classes.regular}
        onClick={() => onAdvance(TransactionSpeed.NEXT_DAY)}
      >
        <span className={classes.standard}>Standard</span>{' '}
        <span className={classes.day}>({ordinaryDayText})</span>
      </Button>
    </div>
  );
};

export default ConfirmTransactionButtons;
