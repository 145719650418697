import { findNextIndex } from 'components/generics/flows/Flow/utils';
import Transition from 'components/transitions/Transition';
import { AnimatePresence } from 'framer-motion';
import React, {
  HTMLAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface FlowComponentProps {
  onFinish: (data?: any) => void;
  goBack: () => void;
  data: any;
  loading: boolean;
  tenant?: boolean;
}

export type FlowComponent<T> = React.FC<T>;

export type FlowEdge<T> = {
  component: FlowComponent<T>;
  condition?: (data: any) => boolean;
};

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  initialData?: any;
  components: FlowEdge<T & FlowComponentProps>[];
  onFinish?: (data: any) => void;
  initialProps?: T;
}

const Flow = <T,>({
  initialData,
  components,
  onFinish,
  initialProps = {} as T,
  className,
}: Props<T>) => {
  const [loading, setLoading] = useState(false);
  const [stack, setStack] = useState<number[]>([0]);
  const data = useRef(initialData);

  const CurrentComponent = useMemo(
    () => components[stack[stack.length - 1]].component,
    [components, stack]
  );

  const goNext = useCallback(
    async (innerData?: any) => {
      data.current = { ...data.current, ...innerData };
      const newIndex = findNextIndex(
        stack[stack.length - 1],
        components,
        data.current
      );
      if (!newIndex) {
        setLoading(true);
        await onFinish?.(data.current);
        setLoading(false);
        return;
      }
      setStack((prev) => [...prev, newIndex]);
    },
    [components, data.current, onFinish, stack]
  );

  const goBack = useCallback((numOfPages = 1) => {
    setStack((prev) => {
      if (prev.length <= numOfPages) return prev;
      const copy = [...prev];
      for (let i = 0; i < numOfPages; i++) {
        copy.pop();
      }
      return copy;
    });
  }, []);

  return (
    <AnimatePresence mode="wait">
      <Transition
        key={stack[stack.length - 1]}
        className={className}
      >
        <CurrentComponent
          data={data.current}
          onFinish={goNext}
          goBack={goBack}
          loading={loading}
          {...initialProps}
        />
      </Transition>
    </AnimatePresence>
  );
};

export default Flow;
