import { URLS } from '../consts/api/urls';
import { ClientsType } from './createClient';
import { DEFAULT_HEADERS, RESIDENT_HEADERS } from '../hooks/api/SWR/headers';

export const AUTH_API = 'AUTH';
export const AUTH_API_TENANT = 'AUTH_TENANT';
export const LANDA_API = 'API';
export const CONTENT_API = 'CONTENT_API';
export const REAL_ESTATE_API = 'REAL_ESTATE';
export const APP_SETTINGS_API = 'APP_SETTINGS';
export const LANDA_SUPER_GRAPH = 'SUPER_GRAPH';

export const CLIENTS: { [key: string]: ClientsType } = {
  [REAL_ESTATE_API]: {
    ENDPOINT: URLS.REAL_ESTATE_ENDPOINT,
    HEADERS: DEFAULT_HEADERS,
    AUTH_REQUIRED: false,
  },
  [AUTH_API]: {
    ENDPOINT: URLS.AUTH_ENDPOINT,
    HEADERS: {
      'x-api-key': process.env.NEXT_PUBLIC_AUTH_API_KEY,
      ...DEFAULT_HEADERS,
    },
    AUTH_REQUIRED: false,
  },
  [AUTH_API_TENANT]: {
    ENDPOINT: URLS.AUTH_ENDPOINT,
    HEADERS: {
      'x-api-key': process.env.NEXT_PUBLIC_AUTH_API_KEY,
      ...RESIDENT_HEADERS,
    },
    AUTH_REQUIRED: false,
  },
  [LANDA_API]: {
    ENDPOINT: URLS.API_ENDPOINT,
    AUTH_REQUIRED: true,
    HEADERS: DEFAULT_HEADERS,
  },
  [CONTENT_API]: {
    ENDPOINT: URLS.API_ENDPOINT,
    URL_SUFFIX: '/content/graphql',
    AUTH_REQUIRED: true,
  },
  [APP_SETTINGS_API]: {
    ENDPOINT: URLS.APP_SETTINGS_ENDPOINT,
    AUTH_REQUIRED: false,
    HEADERS: {
      'x-api-key': process.env.NEXT_PUBLIC_APP_SETTINGS_API_KEY,
      ...DEFAULT_HEADERS,
    },
  },
  [LANDA_SUPER_GRAPH]: {
    ENDPOINT: URLS.API_ENDPOINT,
    URL_SUFFIX: '/supergraph',
    AUTH_REQUIRED: false,
    TOKEN_PREFIX: '',
    HEADERS: {
      ...DEFAULT_HEADERS,
    },
  },
};
