import classNames from 'classnames';
import Typography from 'components/generics/texts/Typography';
import SettingsMenu from 'components/layouts/NavBar/components/UserSettingsMenu/components/SettingsMenu';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import ArrowIcon from 'public/assets/icons/chevron_left.svg';
import { HTMLAttributes, useMemo, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import classes from './index.module.scss';

const UserSettingsMenu = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { userDetails } = useUserDetails();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const firstLetter = useMemo(() => {
    if (userDetails?.firstName?.[0]) {
      return userDetails?.firstName?.[0]?.toUpperCase();
    } else if (userDetails?.email) {
      return userDetails?.email?.[0]?.toUpperCase();
    }
  }, [userDetails]);

  return (
    <>
      {open && (
        <div
          onClick={toggleOpen}
          className={classes.transparentBackdrop}
        />
      )}
      <div className={classNames(classes.container, className)}>
        <div
          className={classes.toggleContainer}
          onClick={toggleOpen}
        >
          <Typography className={classes.firstLetterLabel}>
            {firstLetter ? (
              firstLetter
            ) : (
              <PuffLoader
                color={'var(--color-fog)'}
                size={36}
              />
            )}
          </Typography>
          <ArrowIcon className={classes.arrow} />
        </div>
        <SettingsMenu
          open={open}
          toggleOpen={toggleOpen}
        />
      </div>
    </>
  );
};

export default UserSettingsMenu;
