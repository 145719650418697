import { Inter, Literata } from '@next/font/google';
import classNames from 'classnames';

const interFont = Inter({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  variable: '--font-inter',
  subsets: ['latin-ext'],
});

const literataFont = Literata({
  weight: ['200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['italic'],
  variable: '--font-literata',
  subsets: ['latin-ext'],
});

export const fontsClassName = classNames(
  interFont.variable,
  literataFont.variable
);
