import { create } from 'zustand';

export type DARK_THEME = 'dark';
export type LIGHT_THEME = 'light';
export type THEME_OPTIONS = DARK_THEME | LIGHT_THEME;

export enum THEMEABLE_COMPONENTS {
  NAV_BAR = 'NAV_BAR',
  DRAWER = 'DRAWER',
  SIGN_UP_FLOW = 'SIGN_UP_FLOW',
  LOGIN_FLOW = 'LOGIN_FLOW',
  FOOTER = 'FOOTER',
}

export interface LandaTheme {
  themesStatus: { [key: string]: THEME_OPTIONS };
  setTheme: (component: THEMEABLE_COMPONENTS, theme: THEME_OPTIONS) => void;
  setThemes: (component: THEMEABLE_COMPONENTS[], theme: THEME_OPTIONS) => void;
}

export const useLandaTheme = create<LandaTheme>((set) => ({
  themesStatus: {
    [THEMEABLE_COMPONENTS.NAV_BAR]: 'light',
    [THEMEABLE_COMPONENTS.DRAWER]: 'light',
    [THEMEABLE_COMPONENTS.SIGN_UP_FLOW]: 'light',
    [THEMEABLE_COMPONENTS.LOGIN_FLOW]: 'light',
    [THEMEABLE_COMPONENTS.FOOTER]: 'light',
  },
  setTheme: (component: THEMEABLE_COMPONENTS, theme: THEME_OPTIONS) =>
    set((state) => ({
      ...state,
      themesStatus: {
        ...state.themesStatus,
        [component]: theme,
      },
    })),
  setThemes: (component: THEMEABLE_COMPONENTS[], theme: THEME_OPTIONS) =>
    set((state) => ({
      ...state,
      themesStatus: {
        ...state.themesStatus,
        ...component.reduce((acc, curr) => ({ ...acc, [curr]: theme }), {}),
      },
    })),
}));
