import classNames from 'classnames';
import useLandaDialogs from 'components/dialogs/hooks/useLandaDialogs';
import EscIcon from 'public/assets/icons/esc.svg';
import { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const TransactionFormContainer = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  const { closeDialog } = useLandaDialogs();

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.iconContainer}>
        <EscIcon
          onClick={closeDialog}
          className={classes.icon}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default TransactionFormContainer;
