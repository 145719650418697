import Footer from 'components/footer';
import Head from 'components/next/Head';
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { DEFAULT_LAYOUT, ROUTES_TO_LAYOUTS } from './config';
import useUserDetails from '../../hooks/requests/user/hooks/useUserDetails';
import { useRouter } from 'next/router';
import useResponsive from 'hooks/logics/useResponsive';

interface LayoutProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  route: string;
  children: ReactNode;
}

type LayoutPropsProps = {
  [key: string]: any;
};

export default function AppLayout({ route, children, ...props }: LayoutProps) {
  const { isMobile }: any = useResponsive();
  const { isTenant }: any = useUserDetails();

  const { Layout, layoutProps } = useMemo(() => {
    const layoutObject = ROUTES_TO_LAYOUTS(isMobile, isTenant);
    let layout = layoutObject[route];
    let layoutProps: LayoutPropsProps = {};
    if (typeof layout === 'object') {
      layoutProps = layout.props;
      layout = layout.layout;
    }
    if (!layout) return { Layout: DEFAULT_LAYOUT, layoutProps };
    return { Layout: layout, layoutProps };
  }, [route, isMobile, isTenant]);

  // useEffect(() => {
  //   if (
  //     userDetails?.isTenant &&
  //     !router?.route?.includes("/residents") &&
  //     router?.route !== "/_error" &&
  //     router?.route !== "/feed" &&
  //     router?.route !== "/property*"
  //
  //   ) {
  //     router.replace("/residents/rent-pay");
  //   }
  // }, [userDetails?.isTenant]);

  return (
    <>
      <Head />
      <main {...props}>
        <Layout {...layoutProps}>{children}</Layout>
        {layoutProps.withFooter !== false && <Footer />}
      </main>
    </>
  );
}
