import useTransactionAnalytics from 'components/dialogs/TransferDialog/components/TransactionLoading/hooks/useTransactionAnalytics';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { CREATE_TRANSACTION_MUTATION } from 'graphql/transactions/mutations/createTransactionMutation';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import { useState } from 'react';

const useCreateTransaction = () => {
  const [loading, setLoading] = useState(false);
  const { transactionType, amount, speed, transactionFrequency } =
    useTransaction();

  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const { reportStarted, reportError, reportSuccess } =
    useTransactionAnalytics(transactionType);

  const [createTransactionMutation] = useLandaApiMutation(
    CREATE_TRANSACTION_MUTATION
  );

  const createTransaction = async () => {
    setLoading(true);
    reportStarted();
    try {
      const res = await createTransactionMutation({
        variables: {
          amount: parseFloat(amount as string),
          transactionType,
          recurringInterval: transactionFrequency,
          speed,
        },
      });
      if (res?.data?.createTransactionMutation?.ok) {
        reportSuccess();
        setSuccess(true);
      } else {
        reportError();
        setSuccess(false);
      }
    } catch (err: any) {
      const extractedError = err?.networkError?.result?.errors?.[0]?.message;
      setErrorMessage(extractedError);
      reportError(err as string);
      setSuccess(false);
    }
    setLoading(false);
  };

  return { loading, createTransaction, success, errorMessage };
};

export default useCreateTransaction;
