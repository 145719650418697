import Script from 'next/script';
import React from 'react';

const LandaScripts = () => {
  return (
    <>
      <Script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js" />
      <Script
        id={'helpscout-beacon'}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: process.env.helpscout as string,
        }}
      ></Script>
    </>
  );
};

export default LandaScripts;
