import { ReactElement } from 'react';
import { arrayify } from 'utils/helpers';

interface Props {
  children?: (boolean | ReactElement | ReactElement[])[];
}

const CoalesceContainer = ({ children }: Props): ReactElement => {
  for (const child of arrayify(children)) {
    if (child) return child as ReactElement;
  }
  return <></>;
};

export default CoalesceContainer;
