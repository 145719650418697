import React, { HTMLAttributes } from 'react';
import Button from '../../../../generics/buttons/Button';
import { RESIDENTS_RENT_PAY_ROUTE } from '../../../../../consts/routes/urls';
import { useLandaRouter } from '../../../../../hooks/logics/useLandaRouter';
import classes from 'components/layouts/NavBar/index.module.scss';
import classNames from 'classnames';

const PayRentButton = ({ className }: HTMLAttributes<HTMLButtonElement>) => {
  const { navigate } = useLandaRouter();
  return (
    <Button
      onClick={() => navigate(RESIDENTS_RENT_PAY_ROUTE)}
      variant={'secondary'}
      className={classNames(classes.navBarButtonPay, className)}
    >
      Pay Rent
    </Button>
  );
};

export default PayRentButton;
