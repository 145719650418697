import { BeatLoader as SpinnerBeatLoader } from 'react-spinners';
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props';

const BeatLoader = (props: LoaderSizeMarginProps) => {
  return (
    <SpinnerBeatLoader
      color="var(--color-concrete)"
      speedMultiplier={0.5}
      size={10}
      {...props}
    />
  );
};

export default BeatLoader;
