import { FC } from 'react';
import Modal from 'components/containers/Modal/Modal';
import classNames from 'classnames';
import IconButton from 'components/generics/buttons/IconButton';
import Typography from 'components/generics/texts/Typography';
import Button from 'components/generics/buttons/Button';
import { QuilttButton, QuilttProvider } from '@quiltt/react';
import classes from './index.module.scss';
import { useConnectBankAccountModal } from 'hooks/logics/useConnectBankAccountModal';
import useLandaDialogs from 'components/dialogs/hooks/useLandaDialogs';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import { BUTTON_ON_CLICK_DIALOG } from 'components/features/CTAs/ConnectBank/consts';
import { USER_STATUS } from 'consts/User/status';

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading?: boolean;
  isKYC?: boolean;
}

const connectorId = process.env.NEXT_PUBLIC_QUILTT_CONNECTOR_ID || '';

const BankConnectionModal: FC<Props> = ({ isKYC, onClose, modalOpen }) => {
  const { openDialog } = useLandaDialogs();
  const { userDetails } = useUserDetails();

  const buttonDialog =
    (userDetails && BUTTON_ON_CLICK_DIALOG[userDetails?.userStage]) ||
    BUTTON_ON_CLICK_DIALOG[USER_STATUS.DEFAULT];

  const {
    quilttDisabled,
    isLoading,
    sessionToken,
    handleExitSuccess,
    errorMessage,
  } = useConnectBankAccountModal({
    isEnableRequest: modalOpen,
    isKYC,
    onClose,
  });

  const handleOpenManualBankAConnection = () => {
    openDialog(buttonDialog);
  };

  return (
    <Modal
      className={classNames(classes.modalContainer)}
      isOpen={modalOpen}
      onClose={onClose}
    >
      {!isLoading && (
        <QuilttProvider token={sessionToken}>
          <div className={classes.topContainer}>
            <IconButton
              className={classes.closeIcon}
              onClick={onClose}
              variant="close"
            />
            <Typography className={classes.text}>
              Select your way to connect bank account
            </Typography>
          </div>
          <div className={classNames(classes.childrenContainer)}>
            <Button
              onClick={handleOpenManualBankAConnection}
              disabled={quilttDisabled}
              className={classes.connectBankButton}
            >
              Connect Manually
            </Button>
            <QuilttButton
              disabled={quilttDisabled}
              connectorId={connectorId}
              onExitSuccess={handleExitSuccess}
              className={classNames(
                classes.connectBankButton,
                quilttDisabled && classes.disabledButton
              )}
            >
              <Typography className={classes.button}>
                Automate Bank Connection
              </Typography>
            </QuilttButton>
          </div>
          {errorMessage && (
            <div className={classes.errorMessage}>
              <Typography>{errorMessage}</Typography>
            </div>
          )}
        </QuilttProvider>
      )}
    </Modal>
  );
};

export default BankConnectionModal;
