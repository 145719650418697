import { gql } from '@apollo/client';
export const QUILTT_SESSION = gql`
  mutation {
    createAccountDetailsToken {
      quilttToken {
        token
        userId
      }
    }
  }
`;
