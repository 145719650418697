import DiscordIcon from 'public/assets/icons/discord.svg';
import FacebookIcon from 'public/assets/icons/facebook.svg';
import InstagramIcon from 'public/assets/icons/instagram.svg';
import LinkedInIcon from 'public/assets/icons/linkedin.svg';
import MediumIcon from 'public/assets/icons/medium.svg';
import RedditIcon from 'public/assets/icons/reddit.svg';
import TwitterIcon from 'public/assets/icons/twitter.svg';

export const BASE_MARKETING_LINK = 'https://landa.app/';

export const FOOTER_LINKS = [
  {
    title: 'About',
    link: 'https://landa.app/about',
  },
  {
    title: 'Contact us',
    link: 'https://landa.app/contact-us',
  },
  {
    title: 'Careers',
    link: 'https://landa.app/jobs',
  },
  {
    title: 'Legal',
    link: 'https://landa.app/legal',
  },
  {
    title: 'Help Center',
    link: 'https://landa.app/help-center',
  },
  {
    title: 'Residents',

    link: 'https://landa.app/residents',
  },
  {
    title: 'Business Model',
    link: 'https://landa.app/business-model',
  },
  {
    title: 'Education center',
    link: 'https://landa.app/real-estate-blocks',
  },
  {
    title: 'Blog',
    link: 'https://landa.app/blog',
  },
  {
    title: 'Dictionary',
    link: 'https://landa.app/dictionary',
  },
];

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/landa-real-estate-investing/id1455248672';
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.landax.mobile&hl=en&gl=US&pli=1';

export const SOCIAL_NETWORKS_ITEMS = [
  {
    ItemLink: 'https://stories.landa.app/',
    Icon: MediumIcon,
    title: 'Medium',
  },
  {
    ItemLink: 'https://www.reddit.com/r/Landa',
    Icon: RedditIcon,
    title: 'Reddit',
  },
  {
    ItemLink: 'https://discord.com/invite/ShvYXNVcnB',
    Icon: DiscordIcon,
    title: 'Discord',
  },
  {
    ItemLink: 'https://twitter.com/landa_app',
    Icon: TwitterIcon,
    title: 'Twitter',
  },
  {
    ItemLink: 'https://www.instagram.com/landa.app/',
    Icon: InstagramIcon,
    title: 'Instagram',
  },
  {
    ItemLink: 'https://www.facebook.com/groups/372859600847805',
    Icon: FacebookIcon,
    title: 'Facebook',
  },
  {
    ItemLink: 'https://www.linkedin.com/company/landa',
    Icon: LinkedInIcon,
    title: 'LinkedIn',
  },
];

export const QR_CODE_LINK =
  'download?utm_source=landa.app&utm_medium=qr&utm_campaign=marketing-website';

export const FINRA_LINK = 'https://www.finra.org/';
export const SIPC_LINK = 'https://www.sipc.org/';
export const DALMORE_BROKER_CHECK_LINK =
  'https://brokercheck.finra.org/firm/summary/136352';
export const RIALTO_BROKER_CHECK_LINK =
  'https://brokercheck.finra.org/firm/summary/283477';
export const LANDA_LEGAL_LINK = 'https://landa.app/legal';
