import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import {
  THEMEABLE_COMPONENTS,
  useLandaTheme,
} from 'stores/LandaTheme/landaTheme';

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: THEMEABLE_COMPONENTS;
}

const LandaTheme = forwardRef(
  ({ id, children, ...props }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { themesStatus } = useLandaTheme();

    return (
      <div
        data-theme={themesStatus[id]}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default LandaTheme;
