import { SelectOption } from 'types';

const states = {
  AL: {
    name: 'Alabama',
    location: { latitude: 32.31823, longitude: -86.902298 },
  },
  AK: {
    name: 'Alaska',
    location: { latitude: 66.160507, longitude: -153.369141 },
  },
  AS: {
    name: 'American Samoa',
    location: { latitude: -14.275632, longitude: -170.702042 },
  },
  AZ: {
    name: 'Arizona',
    location: { latitude: 34.048927, longitude: -111.093735 },
  },
  AR: {
    name: 'Arkansas',
    location: { latitude: 34.799999, longitude: -92.199997 },
  },
  CA: {
    name: 'California',
    location: { latitude: 36.778259, longitude: -119.417931 },
  },
  CO: {
    name: 'Colorado',
    location: { latitude: 39.113014, longitude: -105.358887 },
  },
  CT: {
    name: 'Connecticut',
    location: { latitude: 41.599998, longitude: -72.699997 },
  },
  DE: { name: 'Delaware', location: { latitude: 39.0, longitude: -75.5 } },
  DC: {
    name: 'District Of Columbia',
    location: { latitude: 38.942142, longitude: -77.025955 },
  },
  FM: {
    name: 'Federated States Of Micronesia',
    location: { latitude: 6.8874813, longitude: -158.215071 },
  },
  FL: {
    name: 'Florida',
    location: { latitude: 27.994402, longitude: -81.760254 },
  },
  GA: {
    name: 'Georgia',
    location: { latitude: 33.247875, longitude: -83.441162 },
  },
  GU: {
    name: 'Guam',
    location: { latitude: 13.444304, longitude: 144.793732 },
  },
  HI: {
    name: 'Hawaii',
    location: { latitude: 19.741755, longitude: -155.844437 },
  },
  ID: {
    name: 'Idaho',
    location: { latitude: 44.068203, longitude: -114.742043 },
  },
  IL: { name: 'Illinois', location: { latitude: 40.0, longitude: -89.0 } },
  IN: {
    name: 'Indiana',
    location: { latitude: 40.273502, longitude: -86.126976 },
  },
  IA: {
    name: 'Iowa',
    location: { latitude: 42.032974, longitude: -93.581543 },
  },
  KS: { name: 'Kansas', location: { latitude: 38.5, longitude: -98.0 } },
  KY: {
    name: 'Kentucky',
    location: { latitude: 37.839333, longitude: -84.27002 },
  },
  LA: {
    name: 'Louisiana',
    location: { latitude: 30.39183, longitude: -92.329102 },
  },
  ME: {
    name: 'Maine',
    location: { latitude: 45.367584, longitude: -68.972168 },
  },
  MH: {
    name: 'Marshall Islands',
    location: { latitude: 7.131474, longitude: 171.184478 },
  },
  MD: {
    name: 'Maryland',
    location: { latitude: 39.045753, longitude: -76.641273 },
  },
  MA: {
    name: 'Massachusetts',
    location: { latitude: 42.407211, longitude: -71.382439 },
  },
  MI: {
    name: 'Michigan',
    location: { latitude: 44.182205, longitude: -84.506836 },
  },
  MN: {
    name: 'Minnesota',
    location: { latitude: 46.39241, longitude: -94.63623 },
  },
  MS: { name: 'Mississippi', location: { latitude: 33.0, longitude: -90.0 } },
  MO: {
    name: 'Missouri',
    location: { latitude: 38.573936, longitude: -92.60376 },
  },
  MT: {
    name: 'Montana',
    location: { latitude: 46.96526, longitude: -109.533691 },
  },
  NE: { name: 'Nebraska', location: { latitude: 41.5, longitude: -100.0 } },
  NV: {
    name: 'Nevada',
    location: { latitude: 39.876019, longitude: -117.224121 },
  },
  NH: { name: 'New Hampshire', location: { latitude: 44.0, longitude: -71.5 } },
  NJ: {
    name: 'New Jersey',
    location: { latitude: 39.833851, longitude: -74.871826 },
  },
  NM: {
    name: 'New Mexico',
    location: { latitude: 34.307144, longitude: -106.018066 },
  },
  NY: { name: 'New York', location: { latitude: 43.0, longitude: -75.0 } },
  NC: {
    name: 'North Carolina',
    location: { latitude: 35.782169, longitude: -80.793457 },
  },
  ND: {
    name: 'North Dakota',
    location: { latitude: 47.650589, longitude: -100.437012 },
  },
  MP: {
    name: 'Northern Mariana Islands',
    location: { latitude: 15.1063896, longitude: 145.7065244 },
  },
  OH: {
    name: 'Ohio',
    location: { latitude: 40.367474, longitude: -82.996216 },
  },
  OK: {
    name: 'Oklahoma',
    location: { latitude: 36.084621, longitude: -96.921387 },
  },
  OR: { name: 'Oregon', location: { latitude: 44.0, longitude: -120.5 } },
  PW: { name: 'Palau', location: { latitude: 7.51498, longitude: 134.58252 } },
  PA: {
    name: 'Pennsylvania',
    location: { latitude: 41.203323, longitude: -77.194527 },
  },
  RI: {
    name: 'Rhode Island',
    location: { latitude: 41.700001, longitude: -71.5 },
  },
  SC: {
    name: 'South Carolina',
    location: { latitude: 33.836082, longitude: -81.163727 },
  },
  SD: { name: 'South Dakota', location: { latitude: 44.5, longitude: -100.0 } },
  TN: {
    name: 'Tennessee',
    location: { latitude: 35.860119, longitude: -86.660156 },
  },
  TX: { name: 'Texas', location: { latitude: 31.0, longitude: -100.0 } },
  UT: {
    name: 'Utah',
    location: { latitude: 39.41922, longitude: -111.950684 },
  },
  VT: { name: 'Vermont', location: { latitude: 44.0, longitude: -72.699997 } },
  VA: {
    name: 'Virginia',
    location: { latitude: 37.926868, longitude: -78.024902 },
  },
  WA: {
    name: 'Washington State',
    location: { latitude: 47.751076, longitude: -120.740135 },
  },
  WV: { name: 'West Virginia', location: { latitude: 39.0, longitude: -80.5 } },
  WI: { name: 'Wisconsin', location: { latitude: 44.5, longitude: -89.5 } },
  WY: {
    name: 'Wyoming',
    location: { latitude: 43.07597, longitude: -107.290283 },
  },
};

export const stateSelectOptions: Array<SelectOption> = Object.entries(
  states
).map(([key, value]) => ({
  value: key,
  label: value.name,
}));

export default states;
