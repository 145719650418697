import classNames from 'classnames';
import { BASE_MARKETING_LINK, QR_CODE_LINK } from 'components/footer/utils';
import Typography from 'components/generics/texts/Typography';
import QRLogo from 'public/assets/images/qr-icon.png';
import React, { HTMLAttributes } from 'react';
import { QRCode } from 'react-qrcode-logo';
import classes from './index.module.scss';

const ScanToDownload = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(classes.container, className)}>
      <Typography className={classes.text}>Scan to download</Typography>
      <div className={classes.qr}>
        <QRCode
          value={BASE_MARKETING_LINK + QR_CODE_LINK}
          logoImage={QRLogo.src}
          bgColor="#F9F7F1"
        />
      </div>
    </div>
  );
};

export default ScanToDownload;
