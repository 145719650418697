import classNames from 'classnames';
import Backdrop from 'components/containers/Modal/Backdrop';
import classes from 'components/containers/Modal/index.module.scss';
import { APP_CONTAINER } from 'context/Fonts';
import { AnimatePresence } from 'framer-motion';
import useMounted from 'hooks/logics/useMounted';
import { useOnKeyPress } from 'hooks/logics/useOnKeyPress';
import { KeyCodes } from 'hooks/logics/useOnKeyPress/utils';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export interface ModalProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  isBackdropTransparent?: boolean;
  lockClose?: boolean;
}

const LandaModal = ({
  children,
  className,
  isOpen,
  onClose,
  isBackdropTransparent = false,
  lockClose = false,
  ...props
}: Partial<ModalProps>) => {
  const innerOnClose = () => {
    if (!lockClose && onClose) {
      onClose();
    }
  };
  useOnKeyPress(innerOnClose as () => void, KeyCodes.Escape);
  const mounted = useMounted();
  if (!mounted) return null;
  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <Backdrop
          onMouseDown={innerOnClose}
          isTransparent={isBackdropTransparent}
        >
          <div
            {...props}
            onMouseDown={(event) => event.stopPropagation()}
            className={classNames(classes.modal, className)}
          >
            {children}
          </div>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.getElementById(APP_CONTAINER) as Element
  );
};

export default LandaModal;
