import { AxiosRequestConfig } from 'axios';
import { authedAxiosFetcher } from 'hooks/api/SWR/axios/fetchers/authedAxiosFetcher';
import { axiosFetcher } from 'hooks/api/SWR/axios/fetchers/axiosFetcher';

export type LandaAxiosFetcher = (options: AxiosRequestConfig) => Promise<any>;

export const AXIOS_FETCHERS = {
  AXIOS_FETCHER: axiosFetcher,
  AUTHED_AXIOS_FETCHER: authedAxiosFetcher,
};
