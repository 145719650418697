import * as Sentry from '@sentry/nextjs';
import { Environment } from 'consts/environment';
import useKyc from 'hooks/requests/kyc/useKyc';
import * as process from 'process';
import { useEffect } from 'react';

const useSentry = () => {
  const { kycIdentities } = useKyc();
  const email = kycIdentities?.attributes?.email;
  const hasEmail = !!kycIdentities?.attributes?.email;

  useEffect(() => {
    if (!hasEmail || process.env.NEXT_PUBLIC_ENV === Environment.DEVELOPMENT)
      return;
    Sentry.setUser({ email });
  }, [hasEmail]);
};

export default useSentry;
