import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { UrlObject } from 'url';

export const useLandaRouter = () => {
  const router = useRouter();
  const goBack = useCallback(() => router.back(), [router]);
  const params = router.query;
  const navigate = useCallback(
    (url: UrlObject | string, as?: UrlObject | string) => {
      router.push(url, as);
    },
    [analytics, router]
  );

  return {
    router,
    goBack,
    navigate,
    params,
  };
};
