import { ShakeRef } from 'components/containers/ShakeableComponent';
import usePortfolioTransactionLimits from 'hooks/requests/portfolio/usePortfolioTransactionLimits';
import useUserCash from 'hooks/requests/portfolio/useUserCash';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import { RefObject, useEffect, useState } from 'react';
import { MAX_TRANSACTION_AMOUNT, transactionValidator } from 'utils/validators';

export const INITIAL_AMOUNT_DETAILS: {
  amount?: string;
  valid: boolean;
  errorMessage: string;
  secondaryMessage?: string;
} = {
  amount: undefined,
  valid: false,
  errorMessage: '',
  secondaryMessage: '',
};

const useTransactionAmount = (
  transactionType: TransactionType,
  shakeRef: RefObject<ShakeRef>
) => {
  const [amountDetails, setAmountDetails] = useState(INITIAL_AMOUNT_DETAILS);
  const { portfolioTransactionLimits, loading: nextLoading } =
    usePortfolioTransactionLimits();
  const { withdrawableCash, usableCash } = useUserCash();

  const maxAmount =
    transactionType === TransactionType.WITHDRAWAL
      ? usableCash
      : portfolioTransactionLimits?.dayLimit;

  const onValueChange = (value?: string) => {
    const { isValid, errorMessage, secondaryMessage } = transactionValidator(
      value,
      {
        maxAmount: maxAmount,
        portfolioLimits: portfolioTransactionLimits,
        withdrawableCash: withdrawableCash,
        usableCash: usableCash,
      }
    );

    if (value === '0') value = undefined;

    setAmountDetails((prev) => ({
      amount: !isValid && value ? prev.amount : value,
      valid: isValid,
      errorMessage: errorMessage,
      secondaryMessage: secondaryMessage,
    }));
    if (!isValid && value !== undefined) shakeRef.current?.shake();
  };

  useEffect(() => {
    onValueChange(undefined);
  }, [transactionType]);

  return {
    onValueChange,
    amount: amountDetails.amount,
    valid: amountDetails.valid,
    errorMessage: amountDetails.errorMessage,
    secondaryMessage: amountDetails.secondaryMessage,
    nextLoading,
  };
};

export default useTransactionAmount;
