import BaseInputError from 'components/generics/inputs/BaseInputError';
import { InputHTMLAttributes } from 'react';

interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
  noBorder?: boolean;
  errorMessage?: string;
  inputClassName?: string;
  withPlaceHolderTransition?: boolean;
}

const NumberInput = ({
  errorMessage,
  noBorder,
  inputClassName,
  withPlaceHolderTransition,
  ...props
}: NumberInputProps) => {
  return (
    <BaseInputError
      type={'number'}
      mask={''}
      maskChar={''}
      errorMessage={errorMessage}
      noBorder={noBorder}
      inputClassName={inputClassName}
      withPlaceHolderTransition={withPlaceHolderTransition}
      {...props}
    />
  );
};

export default NumberInput;
