import classNames from 'classnames';
import Typography from 'components/generics/texts/Typography';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { ReactNode, useEffect, useState } from 'react';
import { SVGComponent } from 'types';
import classes from './index.module.scss';

interface Props {
  name: string;
  route?: string;
  expand: boolean;
  disabled?: boolean;
  Icon?: SVGComponent;
  rightComponent?: ReactNode;
  onClick?: () => void;
  component?: ReactNode;
  link?: string;
}

const DrawerItem = ({
  name,
  route,
  expand,
  Icon,
  rightComponent,
  disabled,
  onClick,
  link,
}: Props) => {
  const { router, navigate } = useLandaRouter();

  const handleNavigate = () =>
    (route && navigate(route)) || (link && navigate(link));

  return (
    <button
      disabled={disabled}
      onClick={onClick ?? handleNavigate}
      className={classNames(
        classes.drawerItem,
        route === router.pathname && classes.active
      )}
    >
      <div className={classes.leftSection}>
        {Icon && <Icon className={classes.icon} />}
        <Typography
          className={classNames(classes.label, expand && classes.open)}
        >
          {name}
        </Typography>
      </div>
      {expand && rightComponent}
    </button>
  );
};

export default DrawerItem;
