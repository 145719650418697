export const WITHDRAWAL_STARTED = 'Withdrawal Create Started';
export const DEPOSIT_STARTED = 'Deposit Create Started';

export const WITHDRAWAL_SUCCESS = 'Withdrawal Create Success';
export const DEPOSIT_SUCCESS = 'Deposit Create Success';

export const WITHDRAWAL_ERROR = 'Withdrawal Create Error';
export const DEPOSIT_ERROR = 'Deposit Create Error';

export const DID_FIRST_DEPOSIT = 'Did First Deposit';
export const DID_FIRST_BIG_DEPOSIT = 'Did First Big Deposit';
