import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import {
  SEARCH_BAR_EXIT,
  SEARCH_BAR_VARIANTS,
} from 'pages/feed/components/SearchBar/components/SearchBarInput/utils';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import SearchIcon from 'public/assets/icons/search.svg';
import React, { useEffect, useMemo, useRef } from 'react';
import classes from './index.module.scss';

const SearchBarInput = () => {
  const { open, openSearch, onChange } = useSearchBar();
  const ref = useRef<HTMLInputElement>(null);
  const debounceOnChange = useMemo(() => debounce(onChange, 500), []);

  useEffect(() => {
    if (open) {
      ref.current?.focus();
    }
  }, [open]);

  return (
    <div className={classes.container}>
      <SearchIcon
        className={classes.searchIcon}
        onClick={openSearch}
      />
      {open && (
        <motion.div
          variants={SEARCH_BAR_VARIANTS}
          exit={SEARCH_BAR_EXIT}
          onFocus={openSearch}
          key={'searchBar'}
        >
          <input
            ref={ref}
            type={'text'}
            onChange={debounceOnChange}
            className={classes.searchBar}
            placeholder="Search"
          />
        </motion.div>
      )}
    </div>
  );
};

export default SearchBarInput;
