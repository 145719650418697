import { gql } from '@apollo/client';

export const TRANSACTION_LIMIT_QUERY = gql`
  query transactionLimit($transactionType: String!) {
    transactionLimit(transactionType: $transactionType) {
      hasLimit
      limitComment
      maxAmount
    }
  }
`;
