export const SMS_URL = 'https://sms.landa.app/download';
export const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/';
export const ASSETS_URL = 'https://landa-email-assets.s3.amazonaws.com/';
export const SMART_DOWNLOAD_URL = 'https://landa.sng.link/D7gf3/ddu6/iqqk';

export const URLS: { [key: string]: string } = {
  REAL_ESTATE_ENDPOINT: `${process.env.NEXT_PUBLIC_API_URL}api/real_estate_graphql`,
  API_ENDPOINT: `${process.env.NEXT_PUBLIC_API_URL}api`,
  STOCKS_ENDPOINT: `${process.env.NEXT_PUBLIC_STOCKS_URL}api/v1/stock`,
  AUTH_ENDPOINT: `${process.env.NEXT_PUBLIC_AUTH_URL}`,
  BANKING_ENDPOINT: `${process.env.NEXT_PUBLIC_BANKING_URL}`,
  APP_SETTINGS_ENDPOINT: `${process.env.NEXT_PUBLIC_APP_SETTINGS_URL}`,
};
