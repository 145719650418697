import { ConnectorSDKOnExitSuccessCallback } from '@quiltt/react';
import useFinancials from 'hooks/requests/financials/useFinancials';
import useBankStateSectionData from 'pages/profile/components/ProfileTabContent/Bank&Transfers/components/BankStateSection/useBankStateSectionData';
import useGlobalLoader from 'stores/GlobalLoader/globalLoader';
import { useLandaRouter } from '../useLandaRouter';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import {
  CONNECT_BANK_FAILED,
  CONNECT_BANK_START,
  CONNECT_BANK_SUCCESS,
} from 'consts/analytics/bankConnectionEvents';

interface UseConnectBankAccountModal {
  isEnableRequest?: boolean;
  isKYC?: boolean;
  onClose: () => void;
}

export const useConnectBankAccountModal = ({
  isEnableRequest,
  isKYC,
  onClose,
}: UseConnectBankAccountModal) => {
  const { request } = useFinancials();
  const { setBankLoading } = useGlobalLoader();
  const {
    accountDetails,
    sessionToken,
    sessionLoading,
    quilttDisabled,
    errorMessage,
  } = useBankStateSectionData(isEnableRequest);
  const { navigate } = useLandaRouter();

  const handleExitSuccess: ConnectorSDKOnExitSuccessCallback = async (
    metadata
  ) => {
    analytics.track(CONNECT_BANK_START);
    if (metadata && sessionToken) {
      try {
        setBankLoading(true);
        await accountDetails({
          variables: {
            profile_id: metadata.profileId,
            connector_id: metadata.connectorId,
            connection_id: metadata.connectionId,
            user_token: sessionToken,
          },
        });
        analytics.track(CONNECT_BANK_SUCCESS);
        onClose();
        request?.();
        if (isKYC) {
          navigate('/profile?tab=BankAndTransfer');
        }
        setBankLoading(false);
      } catch (error) {
        analytics.track(CONNECT_BANK_FAILED);
        setBankLoading(false);
        console.log(error);
      }
    }
  };

  return {
    sessionToken,
    quilttDisabled,
    isLoading: sessionLoading,
    handleExitSuccess,
    errorMessage,
  };
};
