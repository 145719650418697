import useAuth from 'context/Auth';
import { PORTFOLIO_QUERY } from 'graphql/portfolio/portfolioQuery';
import { useLandaAuthedApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useRef } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { usePortfolioStore } from 'stores/Portfolio/portfolio';
import { PortfolioType } from 'types/portfolio-types';

const init: {
  portfolio?: PortfolioType;
  loading: boolean;
  request?: () => void;
} = {
  portfolio: undefined,
  loading: false,
  request: undefined,
};

const usePortfolio = () => {
  const { isLoggedIn } = useAuth();
  const { portfolio, setPortfolio } = usePortfolioStore();
  const [innerRequest, { loading }] = useLandaAuthedApiLazyQuery(
    PORTFOLIO_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const isActive = useRef(true);

  const request = () => {
    if (!isLoggedIn || !isActive.current) return;
    innerRequest().then((res) => {
      setPortfolio(res?.data?.account);
    });
  };

  useEffect(() => {
    isActive.current = true;
    if (isLoggedIn) request();

    return () => {
      // Cleanup when component unmounts
      isActive.current = false;
    };
  }, [isLoggedIn]);

  return {
    request,
    portfolio,
    loading,
  };
};

export default singletonHook(init, usePortfolio);
