import { gql } from '@apollo/client';

export const FEED_ITEMS_SEARCH = gql`
  query feedItemsSearch($first: Int!, $after: String, $searchTerm: String!) {
    homeFeedSearch(first: $first, after: $after, searchTerm: $searchTerm) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      reachedEnd
      edges {
        cursor
        node {
          __typename
          ... on PropertyType {
            ticker
            address {
              houseNumber
              street
              city {
                name
              }
              state {
                code
              }
              zipCode
            }
            photos {
              uri
            }
            propertyType
          }
          ... on CityType {
            name
            isComingSoon
            isNew
            photos {
              uri
            }
            tickers
          }
        }
      }
    }
  }
`;
