import { Analytics } from '@firebase/analytics';
import { SegmentEvent } from '@segment/analytics-next';
import { Environment } from 'consts/environment';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { customizeTrack } from 'hooks/integrations/mixpanel/utils';
import * as process from 'process';
import { useEffect, useState } from 'react';

const useFirebase = () => {
  const [initialized, setInitialized] = useState(false);
  const [fireBaseAnalytics, setFireBaseAnalytics] = useState<
    Analytics | undefined
  >(undefined);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV !== Environment.PRODUCTION) return;
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    setFireBaseAnalytics(getAnalytics(app));
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;
    const func = (event: string | SegmentEvent, options?: object | null) => {
      const eventName = (event as string).replaceAll(' ', '_');
      if (fireBaseAnalytics) {
        logEvent(fireBaseAnalytics, eventName, options ? options : {});
      }
    };
    customizeTrack(analytics.track, func);
  }, [initialized]);
};

export default useFirebase;
