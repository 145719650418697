import classNames from 'classnames';
import FooterBottom from 'components/footer/components/FooterBottom';
import FooterTop from 'components/footer/components/FooterTop';
import React from 'react';
import {
  THEMEABLE_COMPONENTS,
  useLandaTheme,
} from 'stores/LandaTheme/landaTheme';
import classes from './index.module.scss';

const Footer = () => {
  const { themesStatus } = useLandaTheme();
  const isDark = themesStatus[THEMEABLE_COMPONENTS.FOOTER] === 'dark';
  return (
    <div className={classNames(classes.container, isDark && classes.dark)}>
      <div className={classes.contentContainer}>
        <FooterTop className={classes.topSection} />
        <FooterBottom className={classes.bottomSection} />
      </div>
    </div>
  );
};

export default Footer;
