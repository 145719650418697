declare global {
  interface String {
    capitalize(this: string, count?: number): string;

    capitalizeFirstLetter(this: string): string;

    addCommaToNumbers(this: string): string;

    isEmpty(this: string): boolean;

    removeSnakeCase(this: string): string;

    camelToSpaces(this: string): string;

    reverseWords(this: string, separator?: string): string;
  }
}

String.prototype.removeSnakeCase = function (this) {
  return this.replace(/_/g, ' ');
};

String.prototype.capitalize = function (this, count) {
  const array = this.split(' ');

  const result = array
    .splice(0, count || array.length)
    .map((item) => item.substring(0, 1).toUpperCase() + item.substring(1));

  result.push(array.join(' '));
  return result.join(' ');
};

String.prototype.capitalizeFirstLetter = function (this) {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.isEmpty = function (this) {
  return /^\s*$/.test(this);
};

String.prototype.addCommaToNumbers = function (this) {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

String.prototype.camelToSpaces = function (this) {
  return this.replaceAll(/([A-Z])/g, ' $1');
};

String.prototype.reverseWords = function (this, separator = ' ') {
  return this.split(separator).reverse().join(separator);
};

export {};
