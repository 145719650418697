import { create } from 'zustand';

interface LayoutStoreProps {
  bankLoading: boolean;
  setBankLoading: (loading: boolean) => void;
}

const useGlobalLoader = create<LayoutStoreProps>((set) => {
  return {
    bankLoading: false,
    setBankLoading: (loading: boolean) => {
      set(() => ({
        bankLoading: loading,
      }));
    },
  };
});

export default useGlobalLoader;
