import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { globalToken } from 'context/Auth/global-token';
import { DEFAULT_HEADERS } from 'hooks/api/SWR/headers';
import { identity, pickBy } from 'lodash';

export const authedAxiosFetcher = async (options: AxiosRequestConfig) => {
  if (!globalToken) return;
  const defaultHeaders = {
    Authorization: `Token ${globalToken}`,
    ...DEFAULT_HEADERS,
    ...(options.headers || {}),
  };

  const cleanedObject = pickBy(defaultHeaders, identity);
  try {
    return (await axios({ ...options, headers: cleanedObject })).data;
  } catch (e) {
    const axiosError = e as AxiosError;
    throw axiosError.response?.data;
  }
};
