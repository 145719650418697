import Transition from 'components/transitions/Transition';
import { CSSProperties } from 'react';
import { SyncLoader } from 'react-spinners';

interface Props {
  isLoading?: boolean;
  color?: string;
  cssOverride?: CSSProperties;
  speedMultiplier?: 1;
  withClass?: boolean;
}

const DotsLoader = ({
  color = 'var(--color-concrete)',
  isLoading,
  speedMultiplier,
  cssOverride,
}: Props) => {
  if (!isLoading) return null;

  return (
    <Transition>
      <SyncLoader
        size={10}
        color={color}
        speedMultiplier={speedMultiplier}
        cssOverride={cssOverride}
      />
    </Transition>
  );
};

export default DotsLoader;
