import BankIcon from 'public/assets/icons/bank.svg';
import HelpIcon from 'public/assets/icons/help.svg';
import SignoutIcon from 'public/assets/icons/signout.svg';
import UserBaseIcon from 'public/assets/icons/user_base.svg';

export const USER_SETTING_MENUS = [
  {
    text: 'My Account',
    Icon: UserBaseIcon,
    onMenuClick: 'Account',
  },
  {
    text: 'Bank & Transfers',
    Icon: BankIcon,
    onMenuClick: 'BankAndTransfer',
  },
  {
    text: 'Help and Support',
    Icon: HelpIcon,
    onMenuClick: 'Support',
  },
  {
    text: 'Sign Out',
    Icon: SignoutIcon,
    onMenuClick: 'logout',
  },
];
