import classNames from 'classnames';
import {
  DetailedHTMLProps,
  ElementType,
  HTMLAttributes,
  ReactElement,
} from 'react';
import classes from './index.module.scss';

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  selectedClassName?: string;
  unselectedClassName?: string;
  leftComponent?: ReactElement;
  isSelected?: boolean;
  Icon?: ElementType;
}

const Chip = ({
  className,
  isSelected,
  selectedClassName,
  unselectedClassName,
  leftComponent,
  Icon,
  children,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        classes.chip,
        className,
        isSelected
          ? selectedClassName
            ? selectedClassName
            : classes.selected
          : unselectedClassName
      )}
      {...props}
    >
      <>
        {leftComponent}
        {Icon && <Icon className={classes.icon} />}
        {children}
      </>
    </div>
  );
};

export default Chip;
