import { useSearchPreviewFetcher } from 'pages/feed/components/SearchBar/context/hooks';
import { SearchBarContextType } from 'pages/feed/components/SearchBar/context/utils';
import { createContext, ReactNode, useContext, useState } from 'react';
import { InputEvent } from 'types';

const SearchBarContext = createContext<SearchBarContextType>(
  {} as SearchBarContextType
);

type Props = {
  children: ReactNode;
};

export const SearchBarProvider = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data, loading } = useSearchPreviewFetcher(searchTerm);

  const onChange = (e: InputEvent) => setSearchTerm(e.target.value);
  const openSearch = () => setOpen(true);
  const closeSearch = () => setOpen(false);

  return (
    <SearchBarContext.Provider
      value={{
        open,
        openSearch,
        closeSearch,
        onChange,
        searchTerm,
        data,
        loading,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
};

export const useSearchBar = () => {
  return useContext(SearchBarContext);
};
