import classNames from 'classnames';
import AppStoreGoogleLinks from 'components/footer/components/FooterTop/components/AppStoreGoogleLinks';
import FooterLinks from 'components/footer/components/FooterTop/components/FooterLinks';
import ScanToDownload from 'components/footer/components/FooterTop/components/ScanToDownload';
import SocialNetworksLinks from 'components/footer/components/FooterTop/components/SocialNetworksLinks';
import LogoIcon from 'public/assets/icons/landa_logo.svg';
import FullLogo from 'public/assets/icons/landa_text_logo.svg';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const FooterTop = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.logoContainer}>
        <LogoIcon className={classes.signLogo} />
        <FullLogo className={classes.textLogo} />
      </div>
      <div className={classes.linksContainer}>
        <ScanToDownload className={classes.scan} />
        <FooterLinks />
      </div>
      <div className={classes.socialContainer}>
        <AppStoreGoogleLinks />
        <SocialNetworksLinks />
      </div>
    </div>
  );
};

export default FooterTop;
