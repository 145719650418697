import { SEARCH_ROWS } from 'pages/feed/components/SearchBar/components/SearchPreviewItem/utils';
import { CityType, PropertyType } from 'types/property-types';

interface Props {
  item: { __typename: string } & PropertyType & CityType;
}

const SearchPreviewItem = ({ item }: Props) => {
  const DynamicComponent = SEARCH_ROWS[item.__typename](item);
  return <>{DynamicComponent}</>;
};

export default SearchPreviewItem;
