export const DEPOSIT_CHIP_OPTIONS = [
  {
    label: 250,
    value: '250',
  },
  {
    label: 500,
    value: '500',
  },
  {
    label: 1000,
    value: '1000',
  },
];
