import Skeleton from 'react-loading-skeleton';
import classes from './index.module.scss';

export const SkeletonLine = ({ ...props }) => {
  return <Skeleton {...props} />;
};

const FeedPropertyCardLoader = () => {
  return (
    <div>
      <div className={classes.square}>
        <Skeleton
          height={'100%'}
          width={'100%'}
        />
      </div>
      <div className={classes.bottomSection}>
        <div>
          <SkeletonLine width={104} />
          <SkeletonLine width={73} />
        </div>
        <div className={classes.rightBottomSection}>
          <SkeletonLine width={52} />
          <SkeletonLine width={66} />
          <SkeletonLine width={87} />
        </div>
      </div>
    </div>
  );
};

export default FeedPropertyCardLoader;
