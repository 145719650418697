import React, { useCallback, useState } from 'react';
import classes from './index.module.scss';
import EscIcon from '../../../public/assets/icons/esc.svg';
import Typography from '../../generics/texts/Typography';
import NumberInput from '../../generics/inputs/NumberInput';
import Button from '../../generics/buttons/Button';
import { useLandaApiMutation } from '../../../hooks/api/graphql/landa_api';
import { CREATE_MICRO_DEPOSIT } from '../../../graphql/mutations/createMicroDeposit';
import Modal from 'components/containers/Modal/Modal';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};

const MicroDepositDialog = ({ isOpen, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [createMicroDeposit] = useLandaApiMutation(CREATE_MICRO_DEPOSIT);
  const [showError, setShowError] = useState('');
  const [firstDepositAmount, setFirstDepositAmount] = useState('');
  const [secondDepositAmount, setSecondDepositAmount] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    setShowError('');
    try {
      await createMicroDeposit({
        variables: {
          firstDeposit: parseFloat(firstDepositAmount),
          secondDeposit: parseFloat(secondDepositAmount),
        },
      });
      onClose?.();
      setLoading(false);
    } catch (error: any) {
      setShowError(error.message);
      setLoading(false);
    }
  };

  const onDissmiss = useCallback(() => {
    setFirstDepositAmount(''), setSecondDepositAmount('');
    onClose?.();
  }, []);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={classes.modal_container}
    >
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <EscIcon
            onClick={onDissmiss}
            className={classes.icon}
          />
        </div>
        <div className={classes.content}>
          <Typography className={classes.title}>Hey!</Typography>
          <Typography>
            We have sent two micro-deposits to your account. Please enter the
            transactions amounts you received to verify your account.
          </Typography>

          <NumberInput
            placeholder={'First Deposit Amount'}
            name={'first_deposit'}
            value={firstDepositAmount}
            onChange={(event) => setFirstDepositAmount(event.target.value)}
            className={classes.row}
            type="number"
          />
          <NumberInput
            placeholder={'Second Deposit Amount'}
            name={'second_deposit'}
            value={secondDepositAmount}
            onChange={(event) => setSecondDepositAmount(event.target.value)}
            className={classes.row}
            type="number"
          />
          {showError && (
            <Typography className={classes.errorText}>{showError}</Typography>
          )}
          <Button
            onClick={onSubmit}
            disabled={!firstDepositAmount || !secondDepositAmount}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MicroDepositDialog;
