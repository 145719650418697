import classNames from 'classnames';
import BaseInput from 'components/generics/inputs/BaseInput';
import Typography from 'components/generics/texts/Typography';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import classes from './index.module.scss';

export interface BaseInputErrorProps
  extends InputHTMLAttributes<HTMLInputElement> {
  noBorder?: boolean;
  errorMessage?: string;
  mask: string;
  maskChar: string;
  placeholder?: string;
  placeholderClass?: string;
  inputClassName?: string;
  withPlaceHolderTransition?: boolean;
}

const BaseInputError = ({
  errorMessage,
  noBorder,
  className,
  mask,
  maskChar,
  placeholder = '',
  placeholderClass = '',
  inputClassName,
  value,
  withPlaceHolderTransition,
  ...props
}: BaseInputErrorProps) => {
  const [blurred, setBlurred] = useState(true);
  const [showError, setShowError] = useState(false);
  const stringValue = value?.toString();

  useEffect(() => {
    if (
      stringValue?.length !== undefined &&
      stringValue?.length > 0 &&
      blurred
    ) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [stringValue, blurred]);

  return (
    <div className={className}>
      <BaseInput
        mask={mask}
        maskChar={maskChar}
        placeholder={placeholder}
        placeholderClassName={[classes.placeholder, placeholderClass]}
        withPlaceHolderTransition={withPlaceHolderTransition}
        className={classNames(
          classes.input,
          noBorder && classes.noBorder,
          inputClassName
        )}
        onBlur={() => setBlurred(true)}
        onFocus={() => setBlurred(false)}
        value={value}
        {...props}
      />
      {errorMessage && showError && (
        <Typography
          className={classNames(
            classes.errorMessage,
            errorMessage && showError && classes.show
          )}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default BaseInputError;
