import { reduce } from 'lodash';

declare global {
  interface Number {
    floorToCents(this: number): number;

    shorten(this: number): string | number;
  }
}

Number.prototype.shorten = function (digits = 0) {
  const units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (this <= -decimal || this >= decimal) {
      return +(this / decimal).toFixed(digits) + units[i];
    }
  }

  return this;
};

Number.prototype.floorToCents = function () {
  return Math.floor(this * 100) / 100;
};

export const roundDecimal = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const roundToThousands = (num: number) => {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
};

export const abbreviateNumber = (amount: number, separate = false): any => {
  let newAmount = amount;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  let suffix = '';

  while (newAmount >= 1000) {
    newAmount /= 1000;
    suffixNum++;
  }

  suffix = suffixes[suffixNum];

  if (separate) {
    return { amount: parseFloat(newAmount.toString()), suffix };
  }
  return newAmount + suffix;
};

export const toPercentageDisplay = (value?: number) => {
  return value?.toFixed(2);
};

export const safeSum = (...args: Array<number | null | undefined>): number => {
  return reduce(args, (acc, val) => acc + (val || 0), 0);
};

export const safeSubtract = (
  a: number | null | undefined,
  b: number | null | undefined
) => {
  return (a || 0) - (b || 0);
};

export const safeDivide = (
  a: number | null | undefined,
  b: number | null | undefined
) => {
  return (a || 0) / (b || 1);
};

export const safeMultiply = (
  ...args: Array<number | null | undefined>
): number => {
  return reduce(args, (acc, val) => acc * (val || 1), 1);
};

export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export {};
