import { USER_STATUS } from 'consts/User/status';
export const getBankStateProps = (
  bankName: string | undefined,
  isBankConnected: boolean,
  userState: USER_STATUS,
  accountLastDigits: string | undefined,
  openBankDialog: () => void,
  openMicroDepositDialog: () => void,
  openDisconnectDialog: () => void,
  contactUsLink: () => void
) => {
  let bankTitle;
  let buttonText;
  let disabled;
  let onClick;
  let accountDigits;
  let disconnectClick;

  switch (userState) {
    case USER_STATUS.ACCOUNT_SUSPENDED:
      bankTitle = 'Account Suspended';
      buttonText = 'Contact Customer Service';
      accountDigits = undefined;
      disabled = false;
      onClick = contactUsLink;
      break;
    case USER_STATUS.ACCOUNT_RETRY:
      bankTitle = 'Bank Connection Retry';
      buttonText = 'Review and Resubmit';
      accountDigits = undefined;
      onClick = openBankDialog;
      disabled = false;
      break;
    case USER_STATUS.BANK_EXPIRED:
      bankTitle = 'Bank Connection Expired';
      buttonText = 'Reconnect Bank';
      accountDigits = undefined;
      onClick = openBankDialog;
      disabled = false;
      break;
    case USER_STATUS.BANK_LOCKED:
      bankTitle = 'Connection Locked for Security Reasons';
      buttonText = 'Contact Customer Service';
      accountDigits = undefined;
      disabled = false;
      onClick = contactUsLink;
      break;
    case USER_STATUS.BANK_REQUIRES_VERIFICATION:
      bankTitle = 'Bank Connection Processing';
      buttonText = 'Enter Micro-Deposit';
      accountDigits = undefined;
      disabled = false;
      onClick = openMicroDepositDialog;
      disconnectClick = openDisconnectDialog;
      break;
    case USER_STATUS.BANK_PENDING:
      bankTitle = 'Bank Connection Pending Approval. Usually Takes 3-5 Days';
      buttonText = 'Contact Customer Service';
      accountDigits = undefined;
      disabled = true;
      onClick = contactUsLink;
      break;
    case USER_STATUS.DEFAULT:
      if (!isBankConnected) {
        bankTitle = 'No Bank Connection';
        buttonText = 'Connect Bank';
        accountDigits = undefined;
        onClick = openBankDialog;
        disabled = false;
      } else {
        bankTitle = bankName;
        buttonText = 'Disconnect ' + accountLastDigits;
        accountDigits = accountLastDigits;
        disabled = false;
        onClick = openDisconnectDialog;
      }
      break;
    default:
      if (!isBankConnected) {
        bankTitle = 'No Bank Connection';
        buttonText = 'Connect Bank';
        accountDigits = undefined;
        onClick = openBankDialog;
        disabled = false;
      } else {
        bankTitle = bankName;
        buttonText = 'Disconnect ' + accountLastDigits;
        accountDigits = accountLastDigits;
        disabled = false;
        onClick = openDisconnectDialog;
      }
  }

  if (!isBankConnected) {
    if (userState === USER_STATUS.BANK_REQUIRES_VERIFICATION) {
      bankTitle = 'Bank connection processing';
      buttonText = 'Pending';
      accountDigits = undefined;
      disabled = true;
      onClick = undefined;
    } else {
      bankTitle = 'No Bank Connection';
      buttonText = 'Connect Bank';
      accountDigits = undefined;
      disabled = false;
      onClick = openBankDialog;
    }
  }

  return {
    bankTitle,
    accountDigits,
    buttonText,
    disabled,
    onClick,
    disconnectClick,
    userState,
  };
};
