import { LOGOUT_PRESS } from 'consts/analytics/onBoardingEvents';
import { setGlobalToken } from 'context/Auth/global-token';
import { AuthContextType } from 'context/Auth/types';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { resetAllStores } from 'stores/utils';

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const tokensGetter = () => {
    const storageToken = token || localStorage.getItem('token') || '';
    setGlobalToken(storageToken);
    !token && setToken(storageToken);
    !accountNumber &&
      setAccountNumber(localStorage.getItem('accountNumber') || '');
  };

  const tokensSetter = (tokenKey: string, accountNumber: string) => {
    setGlobalToken(tokenKey);
    setToken(tokenKey);
    setAccountNumber(accountNumber);
    localStorage.setItem('accountNumber', accountNumber);
    localStorage.setItem('token', tokenKey);
  };

  const loginSignupSuccess = async (
    tokenKey: string,
    accountNumber: string
  ) => {
    setIsLoading(true);
    tokensSetter(tokenKey, accountNumber);
    setIsLoading(false);
  };

  const logout = () => {
    setTimeout(() => {
      const { singularSdk } = require('singular-sdk');
      analytics.track(LOGOUT_PRESS);
      analytics.reset();
      singularSdk.logout();
      resetAllStores();
      localStorage.clear();
    }, 1000);
    tokensSetter('', '');
  };

  useEffect(() => {
    tokensGetter();
    setInitialized(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        loginSignupSuccess,
        initialized,
        token,
        accountNumber,
        isLoggedIn: !!token,
        logout,
      }}
    >
      {initialized ? children : null}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
