import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql/language';
import useLandaLazyQuery, {
  OptionsType,
} from 'hooks/api/graphql/functions/useLandaLazyQuery';
import { useCallback, useState } from 'react';

export type Edge<TData> = {
  cursor: string;
  node: TData;
};

export type PaginationData<TData> = {
  reachedEnd: boolean;
  edges: Edge<TData>[];
  pageInfo?: {
    hasNextPage?: boolean;
  };
};

export type PaginationResult<TData> = {
  [key: string]: PaginationData<TData>;
};

export type dataManipulatorType<TData> = (
  data: PaginationResult<TData>
) => PaginationData<TData>;

const useLandaPagination = <TData, TVariables extends OperationVariables>(
  query: DocumentNode,
  outerOptions: OptionsType<PaginationResult<TData>, TVariables>,
  dataManipulator: dataManipulatorType<TData>,
  limit = 20,
  cursorBased = true
) => {
  const [data, setData] = useState<TData[]>([]);
  const [loading, setLoading] = useState(false);
  const [after, setAfter] = useState<string | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [reachedEnd, setReachedEnd] = useState(false);

  const updateCursor = (data: PaginationData<TData>) => {
    if (data.reachedEnd) {
      setReachedEnd(data.reachedEnd);
    } else {
      if (cursorBased) {
        setAfter(data.edges[data.edges.length - 1].cursor);
      } else {
        setPageNumber((prev) => prev + 1);
      }
    }
  };

  const paginationVariables = cursorBased
    ? {
        after,
        first: limit,
      }
    : {
        pageNumber: pageNumber,
        pageSize: limit,
      };

  const [queryFunction] = useLandaLazyQuery<
    PaginationResult<TData>,
    TVariables | any
  >(query, outerOptions);

  const fetchNextPage = useCallback(() => {
    setLoading(true);
    queryFunction({
      variables: {
        ...paginationVariables,
        ...(outerOptions.variables ? outerOptions.variables : {}),
      },
      onCompleted: (data: PaginationResult<TData>) => {
        const newData = dataManipulator(data);
        updateCursor(newData);
        setData((prevData) => [
          ...prevData,
          ...newData.edges.map((e) => e.node),
        ]);
        setLoading(false);
      },
      onError: (e) => {
        setLoading(false);
      },
    });
  }, [
    paginationVariables,
    queryFunction,
    reachedEnd,
    after,
    outerOptions,
    dataManipulator,
    limit,
  ]);

  return {
    data,
    fetchNextPage,
    loading,
    hasMore: !reachedEnd,
  };
};

export default useLandaPagination;
