import classNames from 'classnames';
import DotsLoader from 'components/animations/Loaders/DotsLoader';
import classes from 'components/generics/buttons/Button/index.module.scss';
import { BUTTON_CLICKED } from 'consts/analytics/onBoardingEvents';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import ArrowIcon from 'public/assets/icons/chevron_left.svg';
import { HTMLAttributes, MouseEvent, useCallback } from 'react';

type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'accent'
  | 'hollow'
  | 'white';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({
  className,
  children,
  onClick,
  loading,
  disabled,
  variant = 'primary',
  ...props
}: ButtonProps) => {
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (loading) return;
      onClick && onClick(event);
      analytics.track(`${BUTTON_CLICKED}`);
    },
    [loading, children, analytics, onClick]
  );

  return (
    <button
      {...props}
      className={classNames(
        classes.button,
        classes[`variant-${variant}`],
        {
          [classes.disabled]: disabled,
        },
        className
      )}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {!loading ? children : <DotsLoader isLoading={loading} />}
      {variant === 'hollow' && <ArrowIcon className={classes.arrow} />}
    </button>
  );
};

export default Button;
