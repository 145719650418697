import { GET_CONFLICTING_ORDERS } from 'graphql/orders/mutations/ConflictingOrdersMutation';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import { useCallback } from 'react';
import { ConflictingOrderType } from 'types/order-types';

const useConflictingOrders = () => {
  const [getConflictingOrdersMutation] = useLandaApiMutation(
    GET_CONFLICTING_ORDERS
  );

  return useCallback(async (amount: number) => {
    try {
      const data = await getConflictingOrdersMutation({
        variables: { realAmount: amount },
      });
      return (
        data.data.getConflictingOrdersMutation.orders ||
        ([] as ConflictingOrderType[])
      );
    } catch (e) {
      return [] as ConflictingOrderType[];
    }
  }, []);
};

export default useConflictingOrders;
