export const SEARCH_BAR_CONTAINER_ANIMATION_VARIANTS = (
  isMobile?: boolean
) => ({
  opened: {
    flex: isMobile ? 1 : 0.4,
    transition: {
      staggerChildren: 0.2,
    },
  },
  closed: {
    flex: 0,
    transition: {
      duration: 0.4,
    },
  },
});
