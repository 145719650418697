import { PropertyType as ServerPropertyType } from 'consts/enums/property';
import {
  LeaseType,
  PropertyAddress,
  PropertyType,
  UnitType,
} from 'types/property-types';

export const propertyAddressPathGenerator = (address: PropertyAddress) => {
  const { street, houseNumber, city, state, zipCode } = address;
  return `${street}-${houseNumber}-${city.name}-${state.code}-${zipCode}`.replace(
    /\s+/g,
    '-'
  );
};

export const propertyAddressPathDecoder = (path: string) => {
  const [street, houseNumber, city, state, zipCode] = path.split('-');
  return {
    street,
    houseNumber,
    city: { name: city },
    state: { code: state },
    zipCode,
  } as PropertyAddress;
};

export const encodeTicker = (ticker: string) => ticker.replace(/@/g, '_');
export const decodeTicker = (ticker: string) => ticker.replace(/_/g, '@');

export const getOrderedUnits = (units: UnitType[]) => {
  const unitsWithId: UnitType[] = [];
  units?.forEach((unit) => {
    const splitTicker = unit?.ticker?.split('-');
    unitsWithId.push({ id: splitTicker?.[splitTicker?.length - 1], ...unit });
  });
  unitsWithId.sort(
    (firstItem, secondItem) =>
      ((firstItem.id || 0) as number) - ((secondItem.id || 0) as number)
  );

  return unitsWithId;
};

export const getOrderedLeases = (leases?: LeaseType[]) => {
  return leases?.sort(
    (firstItem, secondItem) =>
      new Date(secondItem?.endDate).getTime() -
      new Date(firstItem?.endDate).getTime()
  );
};

export const isMultiUnit = (property: Partial<PropertyType>) => {
  return (
    property.propertyType === ServerPropertyType.DUPLEX ||
    property.propertyType === ServerPropertyType.MFR
  );
};

export const getPropertyGoogleMapsLocation = (address: PropertyAddress) => {
  const { street, houseNumber, city, state } = address;
  return houseNumber + '+' + street + ',' + city.name + ',' + state.code;
};

export const getRent = (property: Partial<PropertyType>) => {
  const unitsCount = property?.unitsCount;
  const vacantUnitsCount = property?.vacantUnitsCount;

  const isVacant = unitsCount === vacantUnitsCount;

  return !isVacant && property.monthlyRent
    ? property.monthlyRent
    : property?.units?.reduce(
        (accumulate, unit) => (unit?.proposedRent || 0) + accumulate,
        0
      );
};
