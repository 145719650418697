import { APP_SETTINGS_API } from 'graphql/config';
import { DocumentNode } from 'graphql/language';
import useApolloClient from 'hooks/api/graphql/clients/useApolloClient';
import useLandaLazyQuery, {
  OptionsType,
} from 'hooks/api/graphql/functions/useLandaLazyQuery';

export const useAppSettingsLazyQuery = (
  query: DocumentNode,
  options?: OptionsType
) => {
  const apolloClient = useApolloClient(APP_SETTINGS_API);
  return useLandaLazyQuery(query, { ...(options || {}), client: apolloClient });
};
