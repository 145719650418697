export const BUTTON_CLICKED = 'Button Clicked';
export const LOGIN_FAILED = 'Login Failed';
export const SIGNUP_FAILED = 'Signup Failed';
export const CODE_VERIFY_ERROR = 'Code Verify Error';
export const SIGNUP_EMAIL_SENT = 'Signup Email Sent';
export const SIGNUP_SMS_SENT = 'Signup SMS Sent';
export const LOGOUT_PRESS = 'Logout Press';
export const USER_DETAILS_UPDATED = 'User Details Updated';
export const USER_DETAILS_ERROR = 'User Details Error';
export const BACK_BUTTON_CLICKED = 'Back Button Clicked';
export const GET_STARTED = 'Get Started Clicked';
export const SIGN_UP_PROCESS_COMPLETE = 'SignUp Process Complete';
export const LOGIN_PROCESS_COMPLETE = 'Login Process Complete';
