import useFirebase from 'hooks/integrations/firebase/useFirebase';
import useMixpanel from 'hooks/integrations/mixpanel/useMixpanel';
import useSentry from 'hooks/integrations/sentry/useSentry';
import useSingular from 'hooks/integrations/singular/useSingular';

const LandaIntegrations = () => {
  useSingular();
  useMixpanel();
  useFirebase();
  useSentry();
  return null;
};

export default LandaIntegrations;
