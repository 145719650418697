import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { RECURRING_ACTIONS_FOR_PORTFOLIO_QUERY } from 'graphql/account/queries/recurringActionsQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { RecurringAction } from 'types/recurring-action-types';

const init: {
  recurringActions: RecurringAction[];
  performingRecurringActions: RecurringAction[];
  loading: boolean;
  request?: () => Promise<QueryResult> | undefined;
} = {
  recurringActions: [],
  performingRecurringActions: [],
  loading: false,
  request: undefined,
};

const useRecurringActions = () => {
  const { isLoggedIn } = useAuth();
  const [recurringActions, setRecurringActions] = useState<RecurringAction[]>(
    []
  );
  const [request, { loading, data }] = useLandaApiLazyQuery(
    RECURRING_ACTIONS_FOR_PORTFOLIO_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      setRecurringActions(data?.account?.recurringActions);
    }
  }, [data]);

  const performingRecurringActions = recurringActions?.filter(
    (recurringAction) => recurringAction.performing
  );

  return {
    performingRecurringActions,
    recurringActions,
    loading,
    request,
  };
};

export default singletonHook(init, useRecurringActions);
