import { FlowEdge } from 'components/generics/flows/Flow/index';

export const findNextIndex = <T>(
  currentIndex: number,
  components: FlowEdge<T>[],
  data: any
) => {
  for (let i = currentIndex + 1; i < components.length; i++) {
    const condition = components[i]?.condition;
    if (!condition || condition(data)) {
      return i;
    }
  }
  return null;
};
