import { URLS } from 'consts/api/urls';
import { request, Variables } from 'graphql-request';
import { DocumentNode } from 'graphql/language';
import { DEFAULT_HEADERS } from 'hooks/api/SWR/headers';
import { identity, pickBy } from 'lodash';

const PUBLIC_GRAPHQL_URL = `${URLS.API_ENDPOINT}/public_graphql`;

export const graphqlFetcher = ([query, variables, headers]: [
  DocumentNode,
  object,
  object
]) => {
  const defaultHeaders = {
    ...DEFAULT_HEADERS,
    ...headers,
  };
  const cleanedObject = pickBy(defaultHeaders, identity);
  return request(
    PUBLIC_GRAPHQL_URL,
    query,
    variables as Variables,
    cleanedObject
  );
};
