import React, { useEffect, useState } from 'react';
import BaseDialog from '../BaseDialog';
import { LANDA_DIALOGS } from '../utils';
import useLandaDialogs from '../hooks/useLandaDialogs';
import Typography from '../../generics/texts/Typography';
import EscIcon from '../../../public/assets/icons/esc.svg';
import classes from './index.module.scss';
import LandaSelect from '../../generics/toggles/LandaSelect';
import { BankConnection, BankDetailsRows } from './types/bank_connection';
import { BANK_CONNECTION_OPTIONS, BANK_DETAILS_ROWS } from './utils';
import NumberInput from '../../generics/inputs/NumberInput';
import Button from '../../generics/buttons/Button';
import { useValidateSSN } from '../../../hooks/requests/kyc/useValidateSSN';
import { useLandaApiMutation } from '../../../hooks/api/graphql/landa_api';
import { CREATE_MANUAL_FINANCIAL_CONNECTION } from '../../../graphql/mutations/createManualFinancialConnection';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';

type RowType = {
  id: BankDetailsRows;
  placeholder: string;
  type: string;
  validation: any;
  maxLength: number;
  needEncryption: boolean;
  errorMessage: string;
};
const ManualConnectBankDialog = () => {
  const { closeDialog } = useLandaDialogs();
  const { encrypt } = useValidateSSN();
  const { request } = useUserDetails();
  const [accountType, setAccountType] = useState(BankConnection.CHECKING);
  const [disabled, setDisabled] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    [BankDetailsRows.ROUTING_NUMBER]: '',
    [BankDetailsRows.ACCOUNT_NUMBER]: '',
    [BankDetailsRows.ACCOUNT_NAME]: '',
  });
  const [validationStates, setValidationStates] = useState({
    [BankDetailsRows.ROUTING_NUMBER]: false,
    [BankDetailsRows.ACCOUNT_NUMBER]: false,
    [BankDetailsRows.ACCOUNT_NAME]: false,
  });
  const [touchedStates, setTouchedStates] = useState({
    [BankDetailsRows.ROUTING_NUMBER]: false,
    [BankDetailsRows.ACCOUNT_NUMBER]: false,
    [BankDetailsRows.ACCOUNT_NAME]: false,
  });
  const [createManualFinancialConnection, { error }] = useLandaApiMutation(
    CREATE_MANUAL_FINANCIAL_CONNECTION
  );

  const onTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: RowType
  ) => {
    if (row.maxLength && row?.maxLength < event.target.value.length) return;

    const isValid = row.validation(event.target.value);

    setValidationStates((prevState) => ({
      ...prevState,
      [row.id]: isValid,
    }));

    setBankDetails((prevState) => ({
      ...prevState,
      [row.id]: event.target.value,
    }));

    setTouchedStates((prevState) => ({
      ...prevState,
      [row.id]: true,
    }));
  };

  const onBlur = (row: RowType) => {
    setTouchedStates((prevState) => ({
      ...prevState,
      [row.id]: true,
    }));
  };

  const handleClose = () => {
    setTouchedStates({
      [BankDetailsRows.ROUTING_NUMBER]: false,
      [BankDetailsRows.ACCOUNT_NUMBER]: false,
      [BankDetailsRows.ACCOUNT_NAME]: false,
    });
    closeDialog();
  };

  useEffect(() => {
    const isAllValid = Object.values(validationStates).every((value) => value);

    setDisabled(!isAllValid || !accountType);
  }, [validationStates, accountType]);

  const onSubmission = async () => {
    setIsLoading(true);
    const sendData: { [key in BankDetailsRows]: string } = {
      [BankDetailsRows.ROUTING_NUMBER]: '',
      [BankDetailsRows.ACCOUNT_NUMBER]: '',
      [BankDetailsRows.ACCOUNT_NAME]: '',
    };
    BANK_DETAILS_ROWS.forEach((row) => {
      let data: void | string = bankDetails[row.id];
      if (row.needEncryption) {
        data = encrypt(data);
      }

      if (typeof data === 'string') {
        sendData[row.id] = data;
      }
    });

    try {
      const res = await createManualFinancialConnection({
        variables: {
          ...sendData,
          accountType,
        },
      });
      await request?.();
      setIsLoading(false);
      if (res?.data?.createManualFinancialConnection?.ok) {
        closeDialog();
      } else {
        setShowError(true);
      }
    } catch (e) {
      setIsLoading(false);
      setShowError(true);
    }
  };

  return (
    <BaseDialog name={LANDA_DIALOGS.MANUAL_BANK_CONNECT}>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <EscIcon
            onClick={handleClose}
            className={classes.icon}
          />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Typography className={classes.title}>
              Enter Bank Details
            </Typography>
            {BANK_DETAILS_ROWS.map((row: RowType) => (
              <div
                key={row.id}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <div className={classes.row}>
                  <Typography className={classes.left}>
                    {row.placeholder}
                  </Typography>
                  <NumberInput
                    type={row.type}
                    placeholder={row.placeholder}
                    name={row.id}
                    value={bankDetails[row.id]}
                    onChange={(event) => onTextChange(event, row)}
                    onBlur={() => onBlur(row)}
                    className={classes.right}
                  />
                </div>
                {!row.validation(bankDetails[row.id]) &&
                  touchedStates[row.id] && (
                    <p style={{ color: 'red' }}>{row.errorMessage}</p>
                  )}
              </div>
            ))}
            <div className={classes.row}>
              <Typography className={classes.left}>Account Type</Typography>
              <LandaSelect
                defaultValue={BANK_CONNECTION_OPTIONS[0]}
                className={classes.right}
                onChange={(option) =>
                  setAccountType(option?.value || BankConnection.CHECKING)
                }
                options={BANK_CONNECTION_OPTIONS}
              />
            </div>
            {showError && (
              <Typography className={classes.errorMessage}>
                {error?.message}
              </Typography>
            )}
            <Button
              className={classes.button}
              disabled={disabled}
              onClick={onSubmission}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </BaseDialog>
  );
};

export default ManualConnectBankDialog;
