import classes from './index.module.scss';

interface Props {
  searchTerm: string;
}

const SearchEmptyState = ({ searchTerm }: Props) => {
  return (
    <div className={classes.container}>
      {`We could not find anything for "${searchTerm}".`}
    </div>
  );
};

export default SearchEmptyState;
