export enum KeyCodes {
  Backspace = 'Backspace',
  Tab = 'Tab',
  Enter = 'Enter',
  Shift = 'Shift',
  Ctrl = 'Control',
  Alt = 'Alt',
  Pause = 'Pause',
  CapsLock = 'CapsLock',
  Escape = 'Escape',
  Space = ' ',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  End = 'End',
  Home = 'Home',
  LeftArrow = 'ArrowLeft',
  UpArrow = 'ArrowUp',
  RightArrow = 'ArrowRight',
  DownArrow = 'ArrowDown',
  Insert = 'Insert',
  Delete = 'Delete',
}
