import TransactionFormContainer from 'components/dialogs/TransferDialog/components/TransactionFormContainer';
import Button from 'components/generics/buttons/Button';
import { FlowComponentProps } from 'components/generics/flows/Flow';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import CirclePropertyImage from 'components/properties/CirclePropertyImage';
import { CANCEL_CONFLICTING_ORDERS_MUTATION } from 'graphql/orders/mutations/CancelConflictingOrdersMutation';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import { useState } from 'react';
import { ConflictingOrderType } from 'types/order-types';
import classes from './index.module.scss';

const ConflictingOrders = ({ data, onFinish }: FlowComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [cancelConflictingOrdersMutation] = useLandaApiMutation(
    CANCEL_CONFLICTING_ORDERS_MUTATION
  );
  const ordersIds = data.conflictingOrders.map(
    (order: ConflictingOrderType) => order.id
  );

  const cancelOrdersHandler = async () => {
    setLoading(true);
    const res = await cancelConflictingOrdersMutation({
      variables: { ordersIds },
    });
    if (res.data.cancelConflictingOrders.ok) {
      onFinish();
    }
  };
  return (
    <TransactionFormContainer className={classes.container}>
      <Typography className={classes.title}>
        Pending order will cancel
      </Typography>
      <div className={classes.rowsContainer}>
        {data.conflictingOrders?.map((order: ConflictingOrderType) => (
          <div
            key={order.id}
            className={classes.row}
          >
            <div className={classes.leftSection}>
              <CirclePropertyImage
                className={classes.image}
                uri={order.property.photos[0].uri}
              />
              <div>
                <Typography className={classes.address}>
                  {order.property.address.houseNumber +
                    ' ' +
                    order.property.address.street}
                </Typography>
                <Typography>
                  {order.kind.capitalize()} ∙ {order.amount}
                </Typography>
              </div>
            </div>
            <Money
              className={classes.money}
              value={order.targetValue}
            />
          </div>
        ))}
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          loading={loading}
          className={classes.withdraw}
          onClick={cancelOrdersHandler}
        >
          Withdraw anyway
        </Button>
        <Button
          disabled={loading}
          className={classes.cancel}
          onClick={() => {
            data.onClose && data.onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </TransactionFormContainer>
  );
};

export default ConflictingOrders;
