import Button from 'components/generics/buttons/Button';
import useAuth from 'context/Auth';
import { AnimatePresence, motion } from 'framer-motion';
import classes from './index.module.scss';
import Typography from 'components/generics/texts/Typography';
import SpanTypography from 'components/generics/texts/SpanTypography';
import { useMemo } from 'react';
import { PuffLoader } from 'react-spinners';
import { USER_SETTING_MENUS } from '../../consts';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import usePersonalInformation from 'hooks/requests/kyc/usePersonalInformation';

const INITIAL = { opacity: 0 };
const ANIMATE = { opacity: 1 };
const TRANSITION = { duration: 0.25 };

interface Props {
  open: boolean;
  toggleOpen: () => void;
}

const SettingsMenu = ({ open, toggleOpen }: Props) => {
  const { logout } = useAuth();
  const { navigate } = useLandaRouter();
  const { personalInformation } = usePersonalInformation();

  const firstLetter = useMemo(() => {
    if (personalInformation?.name?.[0]) {
      return personalInformation?.name?.[0]?.toUpperCase();
    } else if (personalInformation?.email) {
      return personalInformation?.email?.[0]?.toUpperCase();
    }
  }, [personalInformation]);

  const onMenuClickHandler = (menu: string) => {
    switch (menu) {
      case 'logout':
        logout();
        break;
      case 'Account':
      case 'Support':
      case 'BankAndTransfer':
        toggleOpen();
        navigate({
          pathname: '/profile',
          query: { tab: menu },
        });
        break;
    }
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <motion.div
            className={classes.container}
            transition={TRANSITION}
            initial={INITIAL}
            animate={ANIMATE}
            exit={INITIAL}
          >
            <Typography className={classes.heading}>
              <Typography className={classes.avatar}>
                {firstLetter ? (
                  firstLetter
                ) : (
                  <PuffLoader
                    color={'var(--color-fog)'}
                    size={36}
                  />
                )}
              </Typography>
              <Typography
                variant="h4"
                className={classes.fullName}
              >
                {personalInformation?.name}
              </Typography>
              <SpanTypography className={classes.address}>
                {personalInformation?.address?.displayAddress}
              </SpanTypography>
              <SpanTypography className={classes.email}>
                {personalInformation?.email}
              </SpanTypography>
            </Typography>

            <Typography className={classes.menuBody}>
              {USER_SETTING_MENUS.map(({ text, Icon, onMenuClick }, index) => (
                <Button
                  key={index.toString()}
                  variant={'text'}
                  onClick={() => onMenuClickHandler(onMenuClick)}
                  className={classes.menuButton}
                >
                  {Icon && <Icon className={classes.menuButtonIcon} />}
                  {text}
                </Button>
              ))}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SettingsMenu;
