import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { USER_CASH_QUERY } from 'graphql/portfolio/userCashQuery';
import { useLandaAuthedApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const init: {
  cash?: number;
  usableCash?: number;
  withdrawableCash?: number;
  loading: boolean;
  request?: () => Promise<QueryResult> | undefined;
} = {
  cash: undefined,
  usableCash: undefined,
  withdrawableCash: undefined,
  loading: false,
  request: undefined,
};

const useUserCash = () => {
  const { isLoggedIn } = useAuth();
  const [cash, setCash] = useState<number>();
  const [usableCash, setUsableCash] = useState<number>();
  const [withdrawableCash, setWithdrawableCash] = useState<number>();
  const [request, { loading }] = useLandaAuthedApiLazyQuery(USER_CASH_QUERY, {
    fetchPolicy: 'network-only',
  });

  const innerRequest = async () => {
    const res = await request();
    if (res.data) {
      setCash(res.data?.account?.settledFunds);
      setUsableCash(res.data?.account?.pendingUnusedFunds);
      setWithdrawableCash(res.data?.account?.withdrawableCash);
    }
    return res;
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    innerRequest();
  }, [isLoggedIn]);

  return {
    cash,
    usableCash,
    withdrawableCash,
    loading,
    request: innerRequest,
  };
};

export default singletonHook(init, useUserCash, { unmountIfNoConsumers: true });
