import { motion } from 'framer-motion';
import React, { HTMLAttributes } from 'react';
import { randomInteger } from 'utils/number.extensions';
import classes from './index.module.scss';

const MovingBall = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const x = Array(8)
    .fill(0)
    .map(() => randomInteger(-300, 300));
  const y = Array(8)
    .fill(0)
    .map(() => randomInteger(-300, 300));

  return (
    <motion.div
      className={className}
      animate={{ x, y }}
      transition={{
        duration: 15,
        times: [0, 0.2, 0.3, 0.4, 0.7, 0.8, 0.9, 1],
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 1,
      }}
    />
  );
};

const TransactionBackgroundAnimation = () => {
  return (
    <div className={classes.outerContainer}>
      <MovingBall className={classes.ball} />
      <MovingBall className={classes.ball2} />
      <MovingBall className={classes.ball} />
      <MovingBall className={classes.ball2} />
      <MovingBall className={classes.ball} />
    </div>
  );
};

export default TransactionBackgroundAnimation;
