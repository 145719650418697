import classNames from 'classnames';
import { DEPOSIT_CHIP_OPTIONS } from 'components/dialogs/TransferDialog/components/ChooseAmount/components/TransactionChips/utils';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import Chip from 'components/generics/buttons/Chip';
import Money from 'components/generics/texts/Money';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const TransactionChips = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { amount, onValueChange } = useTransaction();

  return (
    <div className={classNames(classes.container, className)}>
      {DEPOSIT_CHIP_OPTIONS.map((option) => (
        <Chip
          key={option.label}
          className={classNames(
            classes.chip,
            amount === option.value && classes.active
          )}
          onClick={() => onValueChange(option.value)}
        >
          <Money
            value={option.label}
            className={classes.textContainer}
          >
            {option.label}
          </Money>
        </Chip>
      ))}
    </div>
  );
};

export default TransactionChips;
