import classNames from 'classnames';
import {
  DALMORE_BROKER_CHECK_LINK,
  FINRA_LINK,
  LANDA_LEGAL_LINK,
  SIPC_LINK,
} from 'components/footer/utils';
import Typography from 'components/generics/texts/Typography';
import Link from 'next/link';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const FooterBottom = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(classes.container, className)}>
      <Typography className={classes.paragraph}>
        All Rights Reserved © Landa Holdings, Inc
      </Typography>
      <Typography className={classes.paragraph}>
        {`Landa Holdings, Inc. ("Landa Holdings") operates the www.landa.app website (the "Site") and the Landa mobile application (the "App")(collectively, the “Platform”). Landa Holdings manages Landa App LLC, Landa App 2 LLC, Landa App 3 LLC (“Landa Issuers”), and raises funds and facilitates the issuance of shares for the Landa Issuers entities through the Platform pursuant to Regulation A under the Securities Act of 1933 (“Regulation A”).`}
      </Typography>
      <Typography>
        Landa Management LLC, a wholly-owned subsidiary of Landa Holdings,
        manages Landa Financing LLC (“Landa Financing”), and raises funds and
        facilitates the issuance of shares for Landa Financing through the
        Platform pursuant to Regulation A.
      </Typography>
      <Typography className={classes.paragraph}>
        {`Neither Landa Holdings nor any of its affiliates are  a registered broker-dealer or investment advisor. All securities-related
        activity for the Landa Entities and Landa Financing are conducted through Dalmore Group LLC (“Dalmore”), a registered broker-dealer and member of `}
        <Link
          className={classes.link}
          href={FINRA_LINK}
        >
          FINRA
        </Link>
        /
        <Link
          className={classes.link}
          href={SIPC_LINK}
        >
          SIPC
        </Link>
        {`. You can review Dalmore's `}
        <Link
          className={classes.link}
          href={DALMORE_BROKER_CHECK_LINK}
        >
          brokercheck for Dalmore
        </Link>
        . Landa is not a bank.
      </Typography>
      <Typography className={classes.paragraph}>
        All securities offered by the Landa Entities and Landa Financing are
        deemed “Shares”. Investing in Shares involves risks, and there is always
        the potential of losing money when you invest in Shares. Past
        performance is no guarantee of future results. Investments in Shares are
        speculative and involve substantial risks to consider before investing,
        outlined in the respective offering materials and including, but not
        limited to, illiquidity, lack of diversification, and complete loss of
        capital. An investment in Shares constitutes only an investment in a
        particular offering and not in Landa Holdings, Landa App, or any of the
        underlying asset(s). Investors should carefully review the risks located
        in the respective offering materials for a more comprehensive discussion
        of risks.
      </Typography>
      <Typography className={classes.paragraph}>
        You should speak with your financial advisor, accountant, and/or
        attorney when evaluating any Shares. None of Landa Holdings or its
        affiliates, Dalmore, or Rialto makes any recommendations or provides
        advice about Shares, and no communication, through this website or in
        any other medium, should be construed as a recommendation or investment
        advice. Nothing on the Platform should be construed as investment or tax
        advice, or a solicitation or offer, or a recommendation to buy or sell
        any securities. The Platform may make forward-looking statements. You
        should not rely on these statements but should carefully evaluate the
        offering materials in assessing any investment opportunity, including
        the complete set of risk factors that are provided as part of the
        offering circular for your consideration. We urge you to review the
        applicable offering circular, available{' '}
        <Link
          className={classes.link}
          href={LANDA_LEGAL_LINK}
        >
          here
        </Link>
        .
      </Typography>
      <Typography className={classes.paragraph}>
        {`All securities offered by the Landa Entities and Landa Financing are deemed “Shares”. Investing in Shares involves risks, and there is always the potential of losing money when you invest in Shares. Past performance is no guarantee of future results. Investments in Shares are speculative and involve substantial risks to consider before investing, outlined in the respective offering materials and including, but not limited to, illiquidity, lack of diversification, and complete loss of capital. An investment in Shares constitutes only an investment in a particular offering and not in Landa Holdings, Landa App, or any of the underlying asset(s). Investors should carefully review the risks located in the respective offering materials for a more comprehensive discussion of risks.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`No money or other consideration will be solicited (and if sent, will not be accepted), and no offer to buy Shares of a particular offering can be accepted, and no part of the purchase price can be received until an offering statement filed with the Securities and Exchange Commission (the "SEC") relating to those Shares has been qualified by the SEC. Any such offer may be withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given after the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any kind.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`Investment overviews on the Platform contain summaries intended for informational purposes only and are not complete. For detailed information, we urge you to refer to the applicable offering circular filed with the SEC. Neither Landa Holdings nor any of its affiliates offers refunds after an investment has been made.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`You should speak with your financial advisor, accountant, and/or attorney when evaluating any Shares. None of Landa Holdings or its affiliates, Dalmore makes any recommendations or provides advice about Shares, and no communication, through this website or in any other medium, should be construed as a recommendation or investment advice. Nothing on the Platform should be construed as investment or tax advice, or a solicitation or offer, or a recommendation to buy or sell any securities. The Platform may make forward-looking statements. You should not rely on these statements but should carefully evaluate the offering materials in assessing any investment opportunity, including the complete set of risk factors that are provided as part of the offering circular for your consideration. We urge you to review the applicable offering circular, available `}
        <Link
          className={classes.link}
          href={LANDA_LEGAL_LINK}
        >
          here
        </Link>
        .
      </Typography>
      <Typography className={classes.paragraph}>
        {`All Shares will be issued in electronic form only and will not be listed or quoted on any securities exchange. After a Series’ offering has concluded, we expect that the Public Private Execution Network Alternative Trading System, or PPEX ATS (the “Secondary Trading Platform”), which is registered with the SEC and operated by North Capital Private Securities Corporation (“North Capital”), will be the only venue available for the resale of Shares. Resales of Shares will be conducted by Dalmore, as a broker-dealer member of the Secondary Trading Platform; provided, however, any such resale of Shares will be subject to federal and state securities laws and the restrictions in the Series’ Operating Agreement, and there can be no assurance that an active market for any Shares will develop on the Secondary Trading Platform, that the Secondary Trading Platform will be available to allow resales of Shares to residents of all states, or that the Secondary Trading Platform will be available at all. For these reasons, investors must be prepared to hold their Shares indefinitely.`}
      </Typography>
      <Typography className={classes.paragraph}>{`Disclaimers`}</Typography>
      <Typography className={classes.paragraph}>
        {`Landa Holdings maintains this website for informational purposes only. All offerings of Series’ Shares (and information about each Series and its underlying property) are conducted exclusively on the Landa Mobile App.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`All images and figures shown are for illustrative purposes only and are not actual customer or model returns. Landa is not a cryptocurrency app, all monetary values are in U.S dollars and available only to U.S. investors.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`20 West 22nd Street, New York, NY, 10010.`}
      </Typography>
      <Typography className={classes.paragraph}>
        {`All Rights Reserved © Landa Holdings, Inc`}
      </Typography>
    </div>
  );
};

export default FooterBottom;
