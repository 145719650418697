import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const mobileConfig = '(max-width: 480px)';
const tabletConfig = '( min-width: 481px, max-width: 768px )';
const desktopConfig = '( min-width: 769px )';

const init: {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
} = {
  isMobile: undefined,
  isTablet: undefined,
  isDesktop: undefined,
};

const useBetterMediaQuery = (mediaQueryString: string) => {
  const [matches, setMatches] = useState<boolean>();

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(mediaQueryList.matches);
    listener();
    mediaQueryList.addEventListener('change', listener); // updated from .addListener
    return () => mediaQueryList.removeEventListener('change', listener); // updated from .removeListener
  }, [mediaQueryString]);

  return matches;
};

const useResponsive = () => {
  const isMobile = useBetterMediaQuery(mobileConfig);
  const isTablet = useBetterMediaQuery(tabletConfig);
  const isDesktop = useBetterMediaQuery(desktopConfig);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default singletonHook(init, useResponsive);
