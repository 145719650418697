import Modal from 'components/containers/Modal/Modal';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { UNFREEZE_ACCOUNT } from 'graphql/user/mutations/unfreezeAccount';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import useKyc from 'hooks/requests/kyc/useKyc';
import { HTMLAttributes, useState } from 'react';
import classes from './index.module.scss';

type Props = HTMLAttributes<HTMLButtonElement>;

const AccountFrozenButton = ({ className }: Props) => {
  const [unfreezeAccount, { loading }] = useLandaApiMutation(UNFREEZE_ACCOUNT);
  const { request } = useKyc();
  const [modalOpen, setModalOpen] = useState(false);

  const onUnfreezeClick = async () => {
    await unfreezeAccount();
    request && (await request());
    setModalOpen(false);
  };

  return (
    <>
      <Button
        className={className}
        onClick={() => setModalOpen(true)}
      >
        Account Deactivated
      </Button>
      <Modal
        className={classes.modal}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Typography className={classes.title}>
          Your account is deactivated.
        </Typography>
        <Typography className={classes.subtitle}>
          We&apos;ve completed your delete account request.
        </Typography>
        <Typography className={classes.subtitle}>
          You can still access your documents in the profile tab. We take the
          security of all collected data extremely seriously, and we don&apos;t
          intend to use this data for anything beyond the fulfillment of our
          regulatory requirements.
        </Typography>
        <Button
          loading={loading}
          className={classes.button}
          onClick={onUnfreezeClick}
        >
          Re-activate your account
        </Button>
      </Modal>
    </>
  );
};

export default AccountFrozenButton;
