import BeatLoader from 'components/animations/Loaders/BeatLoader';
import CoalesceContainer from 'components/containers/CoalesceContainer';
import SearchEmptyState from 'pages/feed/components/SearchBar/components/SearchEmptyState';
import SearchPreviewItem from 'pages/feed/components/SearchBar/components/SearchPreviewItem';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import React from 'react';
import classes from './index.module.scss';

const SearchResults = () => {
  const { data, loading, searchTerm } = useSearchBar();
  return (
    <div className={classes.container}>
      <CoalesceContainer>
        {loading && <BeatLoader />}
        {!!searchTerm && !data?.length && (
          <SearchEmptyState searchTerm={searchTerm} />
        )}
        {data?.map((item) => (
          <SearchPreviewItem
            key={item.ticker}
            item={item}
          />
        ))}
      </CoalesceContainer>
    </div>
  );
};

export default SearchResults;
