export const DEFAULT_MAP_MAX_BOUNDS: [[number, number], [number, number]] = [
  [-100, 27],
  [-60, 42],
];

export const DEFAULT_MAP_ZOOM: [number] = [4];
export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/streets-v12';
export const DEFAULT_MAP_CLUSTER_RADIUS = 10;

export const DEFAULT_FLY_TO_OPTIONS = {
  speed: 0.4,
};

export const DEFAULT_MAP_CENTER: [number, number] = [-80, 35];
export const DEFAULT_MAP_OFFSET = [0, -30];

export const DEFAULT_MAX_ZOOM = 10;
export const PROPERTY_PAGE_MAX_ZOOM = 14;
