import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { globalToken } from 'context/Auth/global-token';
import { trackJsonResponse, trackJsonStart } from 'hooks/api/axios/analytics';
import { useState } from 'react';

export type RequestType = (
  innerConfig?: string | AxiosRequestConfig
) => Promise<AxiosResponse>;
type AxiosResponseType = { data?: any; loading: boolean; error?: any };
type AxiosFunctionalityResponse = [RequestType, AxiosResponseType];

export const NonAxiosRequest: RequestType = async () =>
  new Promise(() => {
    return {};
  });

export const generateAxiosConfig = (config: string | AxiosRequestConfig) => {
  if (typeof config === 'string') return { url: config };
  return config;
};

export const useAxios = (
  baseURL?: string,
  options?: AxiosRequestConfig
): AxiosInstance => {
  return axios.create({ baseURL, ...options });
};

export const useAxiosFunctionality = (
  axiosClient: AxiosInstance,
  config: string | AxiosRequestConfig,
  options?: any
): AxiosFunctionalityResponse => {
  const [data, setData] = useState();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const request = async (innerConfig: string | AxiosRequestConfig = {}) => {
    const axiosConfig = generateAxiosConfig(config);
    const innerAxiosConfig = generateAxiosConfig(innerConfig);
    const configWithHeaders = {
      ...axiosConfig,
      ...innerAxiosConfig,
      headers: {
        Authorization: `Token ${globalToken}`,
        ...axiosConfig.headers,
        ...innerAxiosConfig.headers,
      },
    };

    try {
      trackJsonStart(configWithHeaders, options?.track);
      setLoading(true);
      const response = await axiosClient.request(configWithHeaders);
      trackJsonResponse(response, options?.track, false, configWithHeaders.url);
      setLoading(false);
      setData(response?.data);
      return response;
    } catch (e) {
      const axiosError = e as AxiosError;
      const response = axiosError.response as AxiosResponse;
      trackJsonResponse(response, options?.track, true, configWithHeaders.url);
      setLoading(false);
      setError(response?.data);
      throw e;
    }
  };

  return [request, { data, error, loading }];
};
