import * as Sentry from '@sentry/nextjs';

export const safeJsonParse = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    Sentry.captureException(error, { extra: { data: data } });
  }
  return {};
};
