import { motion, Variants } from 'framer-motion';
import { Key, ReactNode } from 'react';

const DEFAULT_INITIAL = { opacity: 0 };
const DEFAULT_ANIMATE = { opacity: 1 };
const DEFAULT_TRANSITION = { duration: 0.2 };
const DEFAULT_EXIT = { opacity: 0 };

type Props = {
  children: ReactNode;
  initial?: object | string;
  animate?: object | string;
  transition?: object;
  whileHover?: object;
  whileTap?: object;
  className?: string;
  onKey?: Key;
  onClick?: (event: any) => any;
  exit?: object | string;
  variants?: Variants;
};
const Transition = ({
  children,
  initial = DEFAULT_INITIAL,
  animate = DEFAULT_ANIMATE,
  transition = DEFAULT_TRANSITION,
  onKey,
  className,
  onClick,
  variants,
  exit = DEFAULT_EXIT,
  ...props
}: Props) => {
  return (
    <motion.div
      {...props}
      initial={initial}
      animate={animate}
      transition={transition}
      className={className}
      key={onKey}
      onClick={onClick}
      exit={exit}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

export default Transition;
