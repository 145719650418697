import Image from 'next/image';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  uri: string;
  height?: number;
  width?: number;
  style?: any;
}

const CirclePropertyImage = ({
  uri,
  height,
  width,
  className,
  style,
}: Props) => {
  if (!uri) return <></>;

  return (
    <Image
      className={className}
      alt={'activity-item-img'}
      height={height || 40}
      width={width || 40}
      src={uri}
      style={{ borderRadius: 999, ...style }}
    />
  );
};

export default CirclePropertyImage;
