import { gql } from '@apollo/client';

export const USER_DETAILS_QUERY = gql`
  query getUserDetails {
    account {
      rentUser {
        rentUserId
        activeLease {
          unitTicker
        }
      }
      userDetails {
        userId
        pushNotificationsEnabled
        doneOnBoarding
        firstName
        middleName
        lastName
        email
        username
        notificationSettings {
          pushToken
        }
        dateJoined
        isActiveTenant
        signedLatestTosAndPp
        featuresSettings {
          agreementEnabled
          eligibleForFreeShare
          allowedToMimicUser
          waiveTradingFees
        }
        wasReferred
        referralCode
        appInfoHash
        appInfoId
        isTenant
        needsToSignSta
        userStage
        accountNumber
      }
    }
  }
`;
