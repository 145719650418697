import { gql } from '@apollo/client';

export type AppSettings = {
  freeShareProgramActive: boolean;
  referralProgramActive: boolean;
  isDuringMaintenance: boolean;
  minBuyOrderActive: boolean;
  minBuyOrderSharesAmount: number;
  minBuyOrderMoneyAmount: number;
  ssnTemplateId: string;
  blackFridayActive: boolean;
  tradingFeeActive: boolean;
  tradingFeeRate: number;
  taxCTAActive: boolean;
  minimumDebtShareAmount: number;
  minimumDebtPrice: number;
  firstBigTransferThreshold: number;
  firstBigOrderThreshold: number;
  isLandaPromotionActive: boolean;
  isLandaPromotionLendActive: boolean;
  landaPromotionBlackFriday: boolean;
  landaPromotionStartDate: string;
  landaPromotionEndDate: string;
  landaPromotionDiscountPercentage: number;
  landaPromotionDiscountThreshold: number;
  primaryHoursCancellationPeriod: number;
};

export const DEFAULT_SETTINGS = {
  freeShareProgramActive: false,
  referralProgramActive: false,
  isDuringMaintenance: false,
  minBuyOrderActive: false,
  minBuyOrderSharesAmount: 0,
  minBuyOrderMoneyAmount: 0,
  ssnTemplateId: '',
  blackFridayActive: false,
  tradingFeeActive: false,
  tradingFeeRate: 0,
  taxCTAActive: false,
  minimumDebtShareAmount: 0,
  minimumDebtPrice: 0,
  firstBigTransferThreshold: 250,
  firstBigOrderThreshold: 250,
  isLandaPromotionActive: false,
  isLandaPromotionLendActive: false,
  landaPromotionBlackFriday: false,
  landaPromotionStartDate: '2023-07-17 00:00',
  landaPromotionEndDate: '2023-07-18 00:00',
  landaPromotionDiscountPercentage: 0.05,
  landaPromotionDiscountThreshold: 500,
  primaryHoursCancellationPeriod: 1,
};

export const query = gql`query getAppSettings {
    getAppSettings(id: "${process.env.NEXT_PUBLIC_APP_SETTINGS_ENV}") {
        freeShareProgramActive
        referralProgramActive
        isDuringMaintenance
        minBuyOrderActive
        minBuyOrderSharesAmount
        minBuyOrderMoneyAmount
        ssnTemplateId
        blackFridayActive
        tradingFeeActive
        tradingFeeRate
        taxCTAActive
        minimumDebtShareAmount
        minimumDebtPrice
        firstBigTransferThreshold
        firstBigOrderThreshold
        isLandaPromotionActive
        isLandaPromotionLendActive
        landaPromotionBlackFriday
        landaPromotionStartDate
        landaPromotionEndDate
        landaPromotionDiscountPercentage
        landaPromotionDiscountThreshold
        primaryHoursCancellationPeriod
    }
}`;

export const init: { appSettings: AppSettings; loading: boolean } = {
  appSettings: DEFAULT_SETTINGS,
  loading: true,
};
