import Transition from 'components/transitions/Transition';
import { useLottie } from 'lottie-react';
import CircleLandaAnimation from 'public/assets/animations/circle_landa_loader.json';
import ErrorLandaAnimation from 'public/assets/animations/error_landa_loader.json';
import SuccessLandaAnimation from 'public/assets/animations/success_landa_loader.json';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  isSuccessful: boolean;
}

const CircleLandaLoader = ({ isLoading, isSuccessful, ...props }: Props) => {
  const options = {
    animationData: isLoading
      ? CircleLandaAnimation
      : isSuccessful
      ? SuccessLandaAnimation
      : ErrorLandaAnimation,
    loop: isLoading,
  };

  const { View } = useLottie(options);

  return <Transition {...props}>{View}</Transition>;
};

export default CircleLandaLoader;
