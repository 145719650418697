import { AnimatePresence, motion } from 'framer-motion';
import classes from './index.module.scss';
import Link from 'next/link';
import Typography from 'components/generics/texts/Typography';

const INITIAL = { opacity: 0 };
const ANIMATE = { opacity: 1 };
const TRANSITION = { duration: 0.25 };

interface SubMenuItem {
  id: number;
  name: string;
  link: string;
}

interface Props {
  items: SubMenuItem[];
}

const SubMenu = ({ items }: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        className={classes.container}
        transition={TRANSITION}
        initial={INITIAL}
        animate={ANIMATE}
        exit={INITIAL}
      >
        <Typography className={classes.subMenu}>
          {items.map((item: SubMenuItem, index: number) => (
            <Link
              href={item?.link}
              key={index}
              className={classes.subMenuItem}
            >
              {item?.name}
            </Link>
          ))}
        </Typography>
      </motion.div>
    </AnimatePresence>
  );
};
export default SubMenu;
