import classNames from 'classnames';
import { FEED_ROUTE } from 'consts/routes/urls';
import Link from 'next/link';
import LogoIcon from 'public/assets/icons/landa_logo.svg';
import FullLogo from 'public/assets/icons/landa_text_logo.svg';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const HeaderLogo = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(classes.container, className)}>
      <Link
        href={FEED_ROUTE}
        aria-label="Landa Logo"
        className={classes.logoContainer}
      >
        <LogoIcon className={classes.logoIcon} />
        <FullLogo className={classes.textLogo} />
      </Link>
    </div>
  );
};

export default HeaderLogo;
