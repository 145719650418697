import classNames from 'classnames';
import Transition from 'components/transitions/Transition';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import classes from 'components/containers/Modal/index.module.scss';

interface BackdropProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  className?: string;
  isTransparent?: boolean;
}

const Backdrop = ({ className, isTransparent, ...props }: BackdropProps) => {
  return (
    <Transition
      className={classNames(
        classes.backdrop,
        isTransparent && classes.noBackdrop,
        className
      )}
      {...props}
    >
      {props.children}
    </Transition>
  );
};

export default Backdrop;
