var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"landa-web@1.0.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  release:
    process.env.NEXT_PUBLIC_RELEASE_NAME +
    '@' +
    process.env.NEXT_PUBLIC_RELEASE_VERSION,
  environment: process.env.NEXT_PUBLIC_ENV === 'prod' ? 'production' : 'qa',
  enabled: process.env.NEXT_PUBLIC_ENV !== 'dev',
});
