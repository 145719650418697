import useAuth from 'context/Auth';
import { PORTFOLIO_TRANSACTION_LIMITS_QUERY } from 'graphql/orders/queries/portfolioTransactionLimitsQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useState } from 'react';
import { PortfolioTransactionLimitsType } from 'types/portfolio-types';

const usePortfolioTransactionLimits = () => {
  const [request, { loading, data }] = useLandaApiLazyQuery(
    PORTFOLIO_TRANSACTION_LIMITS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [portfolioTransactionLimits, setPortfolioTransactionLimits] =
    useState<PortfolioTransactionLimitsType>();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      setPortfolioTransactionLimits(data?.account?.portfolioTransactionLimits);
    }
  }, [data]);

  return {
    portfolioTransactionLimits,
    loading,
    request,
  };
};

export default usePortfolioTransactionLimits;
