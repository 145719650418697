import {
  HEADER_HELP_SUB_MENU,
  HEADER_LEARN_SUB_MENU,
  HEADER_MENU,
} from 'consts/enums/headerMenu';
import { LANDA_APP } from 'consts/routes/urls';
import ArrowIcon from 'public/assets/icons/chevron_left.svg';

export const HEADER_MENUS = [
  {
    text: HEADER_MENU.ABOUT_US,
    subMenu: [],
  },
  {
    text: HEADER_MENU.HELP,
    Icon: ArrowIcon,
    subMenu: [
      { id: 1, name: HEADER_HELP_SUB_MENU.LEGAL, link: `${LANDA_APP}/legal` },
      {
        id: 2,
        name: HEADER_HELP_SUB_MENU.SEC_FILINGS,
        link: `${LANDA_APP}/reports/sec-filings`,
      },
      {
        id: 3,
        name: HEADER_HELP_SUB_MENU.CONTACT_US,
        link: `${LANDA_APP}/contact-us`,
      },
      {
        id: 4,
        name: HEADER_HELP_SUB_MENU.CAREERS,
        link: `${LANDA_APP}/careers`,
      },
    ],
  },
  {
    text: HEADER_MENU.LEARN,
    Icon: ArrowIcon,
    subMenu: [
      {
        id: 1,
        name: HEADER_LEARN_SUB_MENU.EDUCATION_CENTER,
        link: `${LANDA_APP}/education-center`,
      },
      {
        id: 2,
        name: HEADER_LEARN_SUB_MENU.HELP_CENTER,
        link: `${LANDA_APP}/help-center`,
      },
      { id: 3, name: HEADER_LEARN_SUB_MENU.BLOG, link: `${LANDA_APP}/blog` },
      {
        id: 4,
        name: HEADER_LEARN_SUB_MENU.DICTIONARY,
        link: `${LANDA_APP}/dictionary`,
      },
      {
        id: 5,
        name: HEADER_LEARN_SUB_MENU.MORE_REAL_ESTATE,
        link: `${LANDA_APP}/learn-more-more-real-estate`,
      },
    ],
  },
];

export const LINKS = {
  ABOUT_US: `${LANDA_APP}/about`,
};
