import { DocumentNode } from 'graphql/language';
import { LandaGQLFetcher } from 'hooks/api/SWR/graphql/gqlFetchers';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

export const useGraphqlMutation = (
  fetcher: LandaGQLFetcher,
  query?: DocumentNode,
  variables?: object,
  headers?: object,
  SWRConfig?: SWRMutationConfiguration<any, Error>
) => {
  const { trigger, ...props } = useSWRMutation(
    [query, variables, headers],
    fetcher,
    SWRConfig
  );
  return { trigger: (extraArgs?: any) => trigger(extraArgs || {}), ...props };
};
