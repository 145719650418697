import useFinancials from 'hooks/requests/financials/useFinancials';
import { useMemo } from 'react';

const DIGITS_BACKEND_MASK = '****';

const useBankName = () => {
  const { financialIdentities, financialConnections, loadingBankConnection } =
    useFinancials();
  const defaultExternalFinancialConnectionId =
    financialIdentities?.relationships?.default_external_financial_connection
      ?.data?.id;
  const defaultExternalFinancialConnection = financialConnections?.find(
    (financialConnection) =>
      financialConnection.id === defaultExternalFinancialConnectionId
  );

  const connectionAttributes = defaultExternalFinancialConnection?.attributes;

  return useMemo(() => {
    const bankName = connectionAttributes?.bank_name;
    let accountLastDigits;
    if (connectionAttributes?.account_last_digits) {
      if (connectionAttributes?.account_last_digits === DIGITS_BACKEND_MASK) {
        accountLastDigits = DIGITS_BACKEND_MASK;
      } else {
        accountLastDigits = ' **' + connectionAttributes?.account_last_digits;
      }
    }
    return {
      loadingBankConnection: loadingBankConnection,
      bankName,
      accountLastDigits,
    };
  }, [connectionAttributes, loadingBankConnection]);
};

export default useBankName;
