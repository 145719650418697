import { URLS } from 'consts/api/urls';
import { RequestMethods } from 'consts/enums/requests';
import { Environment } from 'consts/environment';
import { AXIOS_FETCHERS } from 'hooks/api/SWR/axios/axiosFetchers';
import { PayloadType } from 'hooks/logics/useMessaging/utils';
import { merge } from 'lodash';
import hash from 'object-hash';
import useUserDetailsStore from 'stores/UserDetails/userDetails';
import { UserDetailsTypes } from 'types/user-details-types';
import UAParser from 'ua-parser-js';

export const onFCMUserStageChanged = (payload: PayloadType) => {
  const mergingObj = {
    userDetails: {
      user_stage: payload.user_stage,
    },
  };
  useUserDetailsStore.setState((state) => merge(state, mergingObj));
};

const getAppInfo = async () => {
  const parser = new UAParser(navigator.userAgent);
  const deviceDetails = parser.getResult();
  const data = {
    operating_system: 'web',
    carrier: deviceDetails?.device?.vendor,
    device_id: deviceDetails?.device?.model,
    device_brand: deviceDetails?.device?.type || 'web',
    environment:
      process.env.NEXT_PUBLIC_ENV === Environment.PRODUCTION
        ? 'Production'
        : 'Debug',
    os_version: `OS: ${deviceDetails?.os?.name} Browser: ${deviceDetails?.browser?.name}`,
    latest_app_version: '1.0.0',
    latest_build_number: '1',
    unique_device_id: deviceDetails?.device?.model,
    mac_address: deviceDetails?.device?.model,
  };
  return { ...data, app_info_hash: hash(data) };
};

export const CreateOrUpdateAppInfo = async (userDetails?: UserDetailsTypes) => {
  const appInfo = await getAppInfo();

  if (
    userDetails?.appInfoHash &&
    userDetails.appInfoHash == appInfo?.app_info_hash
  )
    return;

  let url = `${URLS.API_ENDPOINT}/app_info`;
  let method = RequestMethods.POST;

  if (
    userDetails?.appInfoHash &&
    userDetails?.appInfoHash !== appInfo?.app_info_hash
  ) {
    url += `/${userDetails?.appInfoId}`;
    method = RequestMethods.PATCH;
  }

  await AXIOS_FETCHERS.AUTHED_AXIOS_FETCHER({
    url,
    method,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
    data: {
      data: {
        type: 'app_info',
        attributes: appInfo,
        id: userDetails?.appInfoId,
      },
    },
  });
};
