import { gql } from '@apollo/client';

export const USER_CASH_QUERY = gql`
  query userCashQuery {
    account {
      pendingUnusedFunds
      withdrawableCash
      settledFunds
    }
  }
`;
