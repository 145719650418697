import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { CurrencyInputProps } from 'react-currency-input-field/src/components/CurrencyInputProps';
import classes from './index.module.scss';

const MoneyInput = ({ className, ...props }: CurrencyInputProps) => {
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    setTimeout(() => {
      target.setSelectionRange(target.value.length, target.value.length);
    }, 10);
  };

  return (
    <CurrencyInput
      className={classNames(classes.container, className)}
      prefix={'$'}
      onFocus={onFocus}
      id="input-example"
      name="input-name"
      placeholder={'$0.00'}
      {...props}
    />
  );
};

export default MoneyInput;
