import { gql } from '@apollo/client';

export const TRANSACTION_REQUEST_PARAMETERS_MUTATION = gql`
  mutation getTransactionRequestParametersMutation($type: String!) {
    getTransactionRequestParametersMutation(type: $type) {
      settlementDateOrdinary
      settlementDateFast
      sameDayPricing
      showSameDayOption
    }
  }
`;
