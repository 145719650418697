import BaseDialog from 'components/dialogs/BaseDialog';
import useLandaDialogs from 'components/dialogs/hooks/useLandaDialogs';
import ChooseAmount from 'components/dialogs/TransferDialog/components/ChooseAmount';
import ConfirmTransaction from 'components/dialogs/TransferDialog/components/ConfirmTransaction';
import ConflictingOrders from 'components/dialogs/TransferDialog/components/ConflictingOrders';
import TransactionLoading from 'components/dialogs/TransferDialog/components/TransactionLoading';
import TransactionSummary from 'components/dialogs/TransferDialog/components/TransactionSummary';
import { TransactionProvider } from 'components/dialogs/TransferDialog/context';
import { LANDA_DIALOGS } from 'components/dialogs/utils';
import Flow from 'components/generics/flows/Flow';
import useUserCash from 'hooks/requests/portfolio/useUserCash';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import React from 'react';
import { ConflictingOrderType } from 'types/order-types';
import classes from './index.module.scss';

const TRANSACTION_FLOW_COMPONENTS = [
  { component: ChooseAmount },
  {
    component: ConflictingOrders,
    condition: ({
      conflictingOrders,
    }: {
      conflictingOrders: ConflictingOrderType[];
    }) => conflictingOrders.length > 0,
  },
  { component: ConfirmTransaction },
  { component: TransactionLoading },
  { component: TransactionSummary },
];

const TransferDialog = () => {
  const { closeDialog, searchParams } = useLandaDialogs();
  const { request } = useUserCash();

  const onFinish = async () => {
    request && (await request());
    closeDialog();
  };
  return (
    <BaseDialog name={LANDA_DIALOGS.TRANSFER_FUNDS}>
      <TransactionProvider type={searchParams.get('kind') as TransactionType}>
        <Flow
          className={classes.flow}
          components={TRANSACTION_FLOW_COMPONENTS}
          onFinish={onFinish}
        />
      </TransactionProvider>
    </BaseDialog>
  );
};

export default TransferDialog;
