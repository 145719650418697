export const SEARCH_BAR_VARIANTS = {
  opened: {
    flex: 1,
    width: 1,
    opacity: 1,
  },
  closed: {
    flex: 0,
    opacity: 0,
  },
};

export const SEARCH_BAR_EXIT = {
  width: 0,
  opacity: 0,
};
