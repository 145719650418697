import { DEFAULT_MAP_CENTER } from 'components/maps/consts';
import { log } from 'console';
import { ENTITY_DETAILS_INITIAL } from 'consts/kyc/options/entity';
import {
  AFFILIATION_INITIAL,
  POLITICALLY_EXPOSED_PERSON_INITIAL,
} from 'consts/kyc/options/kyc';
import {
  AccountType,
  AddressGeometry,
  CoordinatesType,
  EntityDetails,
  KYCContextType,
  KycFlowState,
  UserInputAddress,
} from 'context/KYC/types';
import dateFormat from 'dateformat';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import useKyc from 'hooks/requests/kyc/useKyc';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Affiliations,
  Employed,
  PlaceDetails,
  PoliticallyExposedPerson,
} from 'types';
import { adjustAddressToBackend } from 'utils/formatters';

const KYCContext = createContext<KYCContextType>({} as KYCContextType);

export const KYCProvider = ({ children }: PropsWithChildren) => {
  const { goBack } = useLandaRouter();

  const [activeStep, setActiveStep] = useState(0);
  const [activeField, setActiveField] = useState(0);
  // All the data that is needed to be sent to the backend
  const [kycFlowData, setKycFlowData] = useState<KycFlowState | null>(null);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [ssn, setSSN] = useState<string>('');
  const [address, setAddress] = useState<UserInputAddress>();
  const [addressGeometry, setAddressGeometry] = useState<AddressGeometry>();
  const [relationship, setRelationship] = useState<string>('');
  const [employment, setEmployment] = useState<string | Employed>();
  const [annualIncome, setAnnualIncome] = useState<string>('');
  const [annualIncomeValue, setAnnualIncomeValue] = useState<string>('');
  const [accreditedInvestor, setAccreditedInvestor] = useState<string>('');
  const [netWorth, setNetWorth] = useState<string>('');
  const [netWorthValue, setNetWorthValue] = useState<string>('');
  const [riskTolerance, setRiskTolerance] = useState<string>('');
  const [fund, setFund] = useState<string>('');
  const [placeDetails, setPlaceDetails] = useState<PlaceDetails | any>();
  const [affiliations, setAffiliations] =
    useState<Affiliations>(AFFILIATION_INITIAL);
  const [stockExchange, setStockExchange] = useState<string>('');
  const [publiclyTradedCompany, setPubliclyTradedCompany] =
    useState<string>('');
  const [politicallyExposedPerson, setPoliticallyExposedPerson] =
    useState<PoliticallyExposedPerson>(POLITICALLY_EXPOSED_PERSON_INITIAL);
  const [accountType, setAccountType] = useState<AccountType>();
  const [entityDetails, setEntityDetails] = useState<EntityDetails>(
    ENTITY_DETAILS_INITIAL
  );
  const [selectedGeoItem, setSelectedGeoItem] = useState<CoordinatesType>({
    longitude: DEFAULT_MAP_CENTER[0],
    latitude: DEFAULT_MAP_CENTER[1],
  });

  // other stuff
  const personalProfileCompleted = !!date && !!ssn && !!address && !!employment;

  const investmentProfileCompleted =
    !!accountType &&
    !!annualIncome &&
    !!netWorth &&
    !!accreditedInvestor &&
    !!riskTolerance;

  // finalize stuff to backed
  const finalAddress = adjustAddressToBackend(address);

  const collectedData = {
    accountKind: accountType,
    email: email,
    phoneNumber: phone,
    address: finalAddress,
    dateOfBirth: date,
    encryptedSsn: ssn,
    selectedGeoItem: selectedGeoItem,
    entityQuestionnaire: entityDetails,
    isSignedTerms: true,
    questionnaire: {
      accreditedType: '',
      adventurousType: riskTolerance,
      annualIncome: annualIncome,
      annualIncomeValue: +annualIncomeValue || '',
      employmentStatus: {
        type: employment,
        jobTitle: (employment as Employed)?.role,
        employerName: (employment as Employed)?.company,
        occupationIndustry: (employment as Employed)?.industry,
      },
      howWillYouFund: fund,
      investorType: accreditedInvestor,
      maritalStatus: relationship,
      netWorth: netWorth,
      netWorthValue: +netWorthValue || '',
      pepAffiliated:
        politicallyExposedPerson !== POLITICALLY_EXPOSED_PERSON_INITIAL
          ? 'yes'
          : 'no',
      pepEmployerName: politicallyExposedPerson.Name,
      pepGovernmentBranch: politicallyExposedPerson['Government branch'],
      pepPosition: politicallyExposedPerson['Job title'],
      pepWealthSource: politicallyExposedPerson['Source of wealth'],
      stockExchangeEmployed: stockExchange ? 'yes' : 'no',
      stockExchangeFirmName: stockExchange,
      tradedCompany: publiclyTradedCompany ? 'yes' : 'no',
      tradedCompanyName: publiclyTradedCompany,
    },
  };

  // Hack to get the date of birth from the kycIdentities and skip this page if it's already filled
  const { kycIdentities, request } = useKyc();

  const steps = useMemo(() => kycFlowData?.getKycFlow?.steps, [kycFlowData]);

  const onGoBackFormState = () => {
    if (activeStep === 0 && activeField === 0) {
      goBack();
      return;
    }
    if (activeField > 0) {
      setActiveField((prev) => prev - 1);
    } else if (activeField === 0 && activeStep !== 0) {
      const fields = steps?.[activeStep - 1]?.fields.length;
      if (!fields) setActiveStep((prev) => prev - 1);
      setActiveField((fields && fields > 0 ? fields : 1) - 1);
    }
  };

  const onSaveValueToFormState = () => {
    if (activeField + 1 === steps?.[activeStep]?.fields?.length) {
      const isHasFields = !!steps[activeStep + 1]?.fields?.length;

      if (isHasFields) {
        setActiveStep((prev) => prev + 1);
      } else {
        const index = steps?.findIndex(
          (item, index) => item?.fields?.length > 0 && index > activeStep
        );
        setActiveStep(index);
      }
      setActiveField(0);
    } else if (activeField + 1 < (steps?.[activeStep]?.fields?.length ?? 0)) {
      setActiveField((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (kycIdentities?.attributes?.date_of_birth) {
      setDate(dateFormat(kycIdentities.attributes.date_of_birth, 'yyyy-mm-dd'));
    }
  }, [kycIdentities?.attributes?.date_of_birth]);

  useEffect(() => {
    if (!annualIncome) {
      setAnnualIncomeValue('');
    }
  }, [annualIncome]);

  useEffect(() => {
    if (!netWorth) {
      setNetWorthValue('');
    }
  }, [netWorth]);

  return (
    <KYCContext.Provider
      value={{
        request,
        steps: steps ?? [],
        onGoBackFormState,
        activeStep,
        activeField,
        activeStepState: steps?.[activeStep],
        activeFieldState: steps?.[activeStep]?.fields?.[activeField],
        setEmail,
        setPhone,
        date,
        setDate,
        ssn,
        setSSN,
        address,
        setAddress,
        addressGeometry,
        setAddressGeometry,
        relationship,
        setRelationship,
        employment,
        setEmployment,
        personalProfileCompleted,
        investmentProfileCompleted,
        annualIncome,
        setAnnualIncome,
        annualIncomeValue,
        setAnnualIncomeValue,
        accreditedInvestor,
        setAccreditedInvestor,
        netWorth,
        setNetWorth,
        netWorthValue,
        setNetWorthValue,
        riskTolerance,
        setRiskTolerance,
        fund,
        setFund,
        affiliations,
        setAffiliations,
        stockExchange,
        setStockExchange,
        publiclyTradedCompany,
        setPubliclyTradedCompany,
        politicallyExposedPerson,
        setPoliticallyExposedPerson,
        collectedData,
        accountType,
        setAccountType,
        entityDetails,
        setEntityDetails,
        selectedGeoItem,
        setSelectedGeoItem,
        placeDetails,
        setPlaceDetails,
        kycFlowData,
        setKycFlowData,
        onSaveValueToFormState,
      }}
    >
      {children}
    </KYCContext.Provider>
  );
};

export default function useKYC() {
  return useContext(KYCContext);
}
