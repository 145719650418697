import classNames from 'classnames';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import React from 'react';
import classes from './index.module.scss';

const SearchBarOverlay = () => {
  const { closeSearch } = useSearchBar();
  return (
    <div
      className={classNames(classes.overlay)}
      onClick={closeSearch}
    />
  );
};

export default SearchBarOverlay;
