import LoggedInStatus from 'components/features/CTAs/UserStatus/components/LoggedInStatus';
import NotLoggedInStatus from 'components/features/CTAs/UserStatus/components/NotLoggedInStatus';
import Button from 'components/generics/buttons/Button';
import useAuth from 'context/Auth';
import useMounted from 'hooks/logics/useMounted';
import useFinancials from 'hooks/requests/financials/useFinancials';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  showButton?: boolean;
}

const UserStatusCTA = ({
  showButton,
  className,
}: Props): JSX.Element | null => {
  const { isLoggedIn, isLoading: authLoading } = useAuth();
  const { loading: detailsLoading } = useUserDetails();
  const { loading: financialsLoading } = useFinancials();
  const mounted = useMounted();
  const loading =
    authLoading || detailsLoading || financialsLoading || !mounted;
  if (!showButton && loading) return null;
  if (loading)
    return (
      <Button
        className={className}
        loading
      />
    );
  if (isLoggedIn) return <LoggedInStatus className={className} />;
  return <NotLoggedInStatus className={className} />;
};

export default UserStatusCTA;
