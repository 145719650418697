import CityDetailsRow from 'components/features/CityDetailsRow';
import PropertyDetailsRow from 'components/features/PropertyDetailsRow';
import { CityType, PropertyType } from 'types/property-types';

const ROW_TYPES = {
  PropertyType: 'PropertyType',
  CityType: 'CityType',
};

export const SEARCH_ROWS = {
  [ROW_TYPES.PropertyType]: (item: PropertyType) => (
    <PropertyDetailsRow property={item} />
  ),
  [ROW_TYPES.CityType]: (item: CityType) => <CityDetailsRow city={item} />,
};
