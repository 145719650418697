import { gql } from '@apollo/client';

export const UNFREEZE_ACCOUNT = gql`
  mutation UnfreezeAccount {
    unfreezeAccount {
      ok
      errors
    }
  }
`;
