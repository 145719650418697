import { SOCIAL_NETWORKS_ITEMS } from 'components/footer/utils';
import Link from 'next/link';
import React from 'react';
import classes from './index.module.scss';

const SocialNetworksLinks = () => {
  return (
    <div className={classes.container}>
      {SOCIAL_NETWORKS_ITEMS.map((items) => {
        return (
          <Link
            key={items.ItemLink}
            href={items?.ItemLink}
            passHref
            className={classes.item}
          >
            <items.Icon alt={items?.title} />
          </Link>
        );
      })}
    </div>
  );
};

export default SocialNetworksLinks;
