import Typography, { TypographyProps } from '../Typography';

const SpanTypography = (props: TypographyProps) => {
  return (
    <Typography
      BaseComponent={'span'}
      {...props}
    />
  );
};

export default SpanTypography;
