import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { globalToken } from 'context/Auth/global-token';
import { APOLLO_CACHE_OBJECT } from 'graphql/utils';
import { errorLink, logLink } from 'hooks/api/graphql/links';

export type ApolloClientsType = {
  authedApolloClient: ApolloClient<NormalizedCacheObject>;
  publicApolloClient: ApolloClient<NormalizedCacheObject>;
};

export type ClientsType = {
  ENDPOINT: string;
  AUTH_REQUIRED: boolean;
  URL_SUFFIX?: string;
  HEADERS?: { [key: string]: string | undefined };
  TOKEN_PREFIX?: string;
};

type CreateClientProps = (props: ClientsType) => ApolloClientsType;

export const createClient: CreateClientProps = ({
  URL_SUFFIX,
  ENDPOINT,
  HEADERS = {},
  AUTH_REQUIRED = false,
  TOKEN_PREFIX = 'Token ',
}) => {
  let suffix = '';
  let publicSuffix = '';

  if (!ENDPOINT.endsWith('graphql')) {
    suffix = URL_SUFFIX ? URL_SUFFIX : '/graphql';
    publicSuffix = URL_SUFFIX ? URL_SUFFIX : '/public_graphql';
  }

  const httpLink = createHttpLink({
    uri: ENDPOINT?.replace(/\/$/, '') + suffix,
  });

  const publicHttpLink = createHttpLink({
    uri: ENDPOINT?.replace(/\/$/, '') + publicSuffix,
  });

  const authLink = setContext((_, { headers }) => {
    const token = globalToken ? `${TOKEN_PREFIX}${globalToken}` : undefined;

    return {
      headers: {
        ...headers,
        Authorization: token,
        ...(HEADERS || {}),
      },
    };
  });

  const authedApolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, logLink, errorLink, httpLink]),
    cache: new InMemoryCache(APOLLO_CACHE_OBJECT),
    queryDeduplication: true,
  });

  const publicApolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, logLink, errorLink, publicHttpLink]),
    cache: new InMemoryCache(APOLLO_CACHE_OBJECT),
  });

  if (AUTH_REQUIRED) {
    return {
      authedApolloClient,
      publicApolloClient,
    };
  } else {
    return {
      authedApolloClient: authedApolloClient,
      publicApolloClient: authedApolloClient,
    };
  }
};
