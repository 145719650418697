import { SpeedOptions } from 'components/dialogs/TransferDialog/context/utils';
import dateFormat from 'dateformat';
import { TRANSACTION_REQUEST_PARAMETERS_MUTATION } from 'graphql/transactions/mutations/TransactionRequestParametersMutation';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import {
  TransactionSpeed,
  TransactionType,
} from 'pages/activity/components/ActivityTable/types/transaction';
import { useMemo, useState } from 'react';

const useTransactionSpeed = (transactionType: TransactionType) => {
  const [transactionRequestParametersMutation] = useLandaApiMutation(
    TRANSACTION_REQUEST_PARAMETERS_MUTATION
  );

  const [speedOptions, setSpeedOptions] = useState<SpeedOptions | undefined>();
  const [speed, setSpeed] = useState<TransactionSpeed>(
    TransactionSpeed.NEXT_DAY
  );

  const getSpeedOptions = async () => {
    const res = await transactionRequestParametersMutation({
      variables: { type: transactionType },
    });
    setSpeedOptions(res?.data?.getTransactionRequestParametersMutation);
  };

  const fastDayText = useMemo(() => {
    if (!speedOptions) return;
    const { settlementDateFast } = speedOptions;
    const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
    const fastDate = new Date(
      new Date(settlementDateFast).setHours(0, 0, 0, 0)
    );
    const difference = fastDate.getTime() - todayDate.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    if (TotalDays === 0) {
      return 'Today';
    } else if (TotalDays === 1) {
      return 'Tomorrow';
    } else {
      return dateFormat(new Date(settlementDateFast), 'dddd');
    }
  }, [speedOptions]);

  const ordinaryDayText = useMemo(() => {
    if (!speedOptions) return;
    const { settlementDateOrdinary } = speedOptions;
    return dateFormat(new Date(settlementDateOrdinary), 'dddd');
  }, [speedOptions]);

  const settlementText = useMemo(
    () => (speed === TransactionSpeed.SAME_DAY ? fastDayText : ordinaryDayText),
    [speed, fastDayText, ordinaryDayText]
  );

  return {
    getSpeedOptions,
    speedOptions,
    speed,
    setSpeed,
    fastDayText,
    ordinaryDayText,
    settlementText,
  };
};

export default useTransactionSpeed;
