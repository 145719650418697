import ConfirmTransactionButtons from 'components/dialogs/TransferDialog/components/ConfirmTransaction/components/ConfirmTransactionButtons';
import ConfirmTransactionDetails from 'components/dialogs/TransferDialog/components/ConfirmTransaction/components/ConfirmTransactionDetails';
import TransactionFormContainer from 'components/dialogs/TransferDialog/components/TransactionFormContainer';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { FlowComponentProps } from 'components/generics/flows/Flow';
import Typography from 'components/generics/texts/Typography';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import React from 'react';
import classes from './index.module.scss';

const ConfirmTransaction = ({ onFinish }: FlowComponentProps) => {
  const { transactionType } = useTransaction();

  return (
    <TransactionFormContainer className={classes.container}>
      <div>
        <Typography className={classes.title}>
          Confirm{' '}
          {transactionType === TransactionType.DEPOSIT
            ? 'Deposit'
            : 'Withdrawal'}
        </Typography>
        <ConfirmTransactionDetails className={classes.table} />
      </div>
      <ConfirmTransactionButtons onFinish={onFinish} />
    </TransactionFormContainer>
  );
};

export default ConfirmTransaction;
