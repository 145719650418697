import { URLS } from 'consts/api/urls';
import { request, Variables } from 'graphql-request';
import { DocumentNode } from 'graphql/language';
import { DEFAULT_HEADERS } from 'hooks/api/SWR/headers';
import { identity, pickBy } from 'lodash';

export const realEstateGraphqlFetcher = (
  [query, variables, headers]: [DocumentNode, object, object],
  arg?: any
) => {
  const defaultHeaders = {
    ...DEFAULT_HEADERS,
    ...headers,
  };
  const cleanedObject = pickBy(defaultHeaders, identity);
  if (!query) return;

  return request<any>(
    URLS.REAL_ESTATE_ENDPOINT,
    query,
    variables as Variables,
    cleanedObject
  );
};
