import { SafeReadonly } from '@apollo/client/cache/core/types/common';

export const isObject = (something: any) => {
  return (
    Object.prototype.toString.call(something) === '[object Object]' &&
    something.constructor.name === 'Object'
  );
};

export const querify = (obj: { [key: string]: any }): string => {
  const shape = [];

  for (const [key, val] of Object.entries(obj)) {
    const _isObject = isObject(val);
    if (_isObject) {
      const result = querify(val);
      if (!result.isEmpty()) {
        shape.push(`${key} { ${result} }`);
      }
    } else {
      shape.push(val ? '' : key);
    }
  }

  return shape.join(' ');
};

export const APOLLO_CACHE_OBJECT = {
  typePolicies: {
    Query: {
      fields: {
        account: {
          merge(
            existing: SafeReadonly<any> | undefined,
            incoming: SafeReadonly<any>
          ) {
            return incoming;
          },
        },
      },
    },
  },
};
