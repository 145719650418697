import React, {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

type Direction = 'row' | 'column';
type Wrap = 'wrap' | 'nowrap';
type Gap = string | number;
type Align = 'flex-start' | 'flex-end' | 'baseline' | 'center' | 'stretch';
type Justify =
  | 'flex-start'
  | 'flex-end'
  | 'stretch'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export interface StackProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  direction?: Direction;
  wrap?: Wrap;
  gap?: Gap;
  align?: Align;
  justify?: Justify;
  flexGrow?: number;
}

const Stack = forwardRef(
  (
    {
      direction = 'row',
      wrap = 'nowrap',
      gap = 0,
      align = 'stretch',
      justify = 'stretch',
      flexGrow,
      style,
      children,
      ...props
    }: PropsWithChildren<StackProps>,
    ref
  ) => {
    return (
      <div
        ref={ref as React.LegacyRef<HTMLDivElement>}
        style={{
          display: 'flex',
          flexDirection: direction,
          flexWrap: wrap,
          gap: gap,
          alignItems: align,
          justifyContent: justify,
          flexGrow: flexGrow,
          ...style,
        }}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default Stack;
