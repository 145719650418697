import { useTransaction } from 'components/dialogs/TransferDialog/context';
import {
  DEPOSIT_ERROR,
  DEPOSIT_STARTED,
  DEPOSIT_SUCCESS,
  DID_FIRST_BIG_DEPOSIT,
  DID_FIRST_DEPOSIT,
  WITHDRAWAL_ERROR,
  WITHDRAWAL_STARTED,
  WITHDRAWAL_SUCCESS,
} from 'consts/analytics/transactionEvents';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import usePortfolio from 'hooks/requests/portfolio/usePortfolio';
import useAppSettings from 'hooks/requests/useAppSettings/useAppSettings';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';

const useTransactionAnalytics = (transactionType: TransactionType) => {
  const { amount, speed, transactionFrequency } = useTransaction();
  const { portfolio, request } = usePortfolio();
  const { appSettings } = useAppSettings();

  const reportStarted = () => {
    analytics.track(
      transactionType === TransactionType.DEPOSIT
        ? DEPOSIT_STARTED
        : WITHDRAWAL_STARTED,
      {
        amount,
        type: transactionType,
        speed,
        frequency: transactionFrequency,
      }
    );
  };

  const reportError = (error?: string, id?: string) => {
    analytics.track(
      transactionType === TransactionType.DEPOSIT
        ? DEPOSIT_ERROR
        : WITHDRAWAL_ERROR,
      {
        id,
        amount,
        type: transactionType,
        speed,
        error,
        frequency: transactionFrequency,
      }
    );
  };

  const reportSuccess = (id?: string) => {
    const isDeposit = transactionType === TransactionType.DEPOSIT;
    if (!portfolio?.didFirstDeposit && isDeposit) {
      analytics?.track?.(DID_FIRST_DEPOSIT, { amount: amount });
    }
    if (
      !portfolio?.didFirstBigDeposit &&
      isDeposit &&
      appSettings?.firstBigTransferThreshold < parseFloat(amount as string)
    ) {
      analytics?.track?.(DID_FIRST_BIG_DEPOSIT, { amount: amount });
    }
    analytics.track(
      transactionType === TransactionType.DEPOSIT
        ? DEPOSIT_SUCCESS
        : WITHDRAWAL_SUCCESS,
      {
        id,
        amount,
        type: transactionType,
        speed,
        frequency: transactionFrequency,
      }
    );
    request && request();
  };

  return { reportStarted, reportError, reportSuccess };
};

export default useTransactionAnalytics;
