import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from 'components/footer/utils';
import Link from 'next/link';
import AppleStore from 'public/assets/images/appstore.svg';
import GoogleStore from 'public/assets/images/googleplay.svg';
import React from 'react';
import classes from './index.module.scss';

const AppStoreGoogleLinks = () => {
  return (
    <div className={classes.container}>
      <Link href={APP_STORE_LINK}>
        <AppleStore className={classes.appstore} />
      </Link>
      <Link href={GOOGLE_PLAY_LINK}>
        <GoogleStore />
      </Link>
    </div>
  );
};

export default AppStoreGoogleLinks;
