import { ApolloClientsType, createClient } from './createClient';
import { CLIENTS } from './config';

class ClientsManager {
  clients: { [key: string]: ApolloClientsType } = {};
  isAuthed = false;

  constructor() {
    this.initializeClients();
  }

  initializeClients = () => {
    Object.entries(CLIENTS).forEach(([key, value]) => {
      this.clients[key] = createClient(value);
    });
  };

  setClientEndpoint = (clientName: string, baseProxyUrl: string) => {
    CLIENTS[clientName]['ENDPOINT'] = baseProxyUrl;
  };

  getClient(clientName: string) {
    const { authedApolloClient, publicApolloClient } = this.clients[clientName];
    if (this.isAuthed) {
      return authedApolloClient;
    } else {
      return publicApolloClient;
    }
  }
  getAuthedClient(clientName: string) {
    const { authedApolloClient } = this.clients[clientName];
    return authedApolloClient;
  }

  setAuthed(isAuthed: boolean) {
    this.isAuthed = isAuthed;
  }
}

const clientsManager = new ClientsManager();
export { clientsManager };

export default clientsManager;
