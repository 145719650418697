import { merge } from 'lodash';
import { createAndResetOnLogout } from 'stores/utils';
import { FinancialConnections } from 'types/financial-connections-types';
import { FinancialIdentities } from 'types/financial-identities-types';
import { persist } from 'zustand/middleware';

type FinancialsIdentitiesStore = {
  financialIdentities?: FinancialIdentities;
  setFinancialIdentities: (financialIdentities: FinancialIdentities) => void;
  financialConnections?: FinancialConnections[];
  setFinancialConnections: (
    financialConnection: FinancialConnections[]
  ) => void;
};

export const useFinancialsStore =
  createAndResetOnLogout<FinancialsIdentitiesStore>()(
    persist(
      (set) => ({
        financialIdentities: undefined,
        financialConnection: undefined,
        setFinancialIdentities: (newFinancialIdentities: FinancialIdentities) =>
          set(({ financialIdentities }) => ({
            financialIdentities: merge(
              financialIdentities,
              newFinancialIdentities
            ),
          })),
        setFinancialConnections: (
          financialConnections: FinancialConnections[]
        ) =>
          set(() => ({
            financialConnections,
          })),
      }),
      { name: 'financials' }
    )
  );
