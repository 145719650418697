import { gql } from '@apollo/client';

export const RECURRING_ACTIONS_FOR_PORTFOLIO_QUERY = gql`
  query getRecurringActionsForPortfolio {
    account {
      recurringActions {
        id
        modelType
        objectId
        nextExecutionDate
        performing
        interval
        object {
          ... on TransactionType {
            amount
          }
        }
      }
    }
  }
`;
