import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
  track_pageview: true,
});

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
}

export const analytics = {
  track: (event: string, properties?: Record<string, any>) => {
    if (typeof window !== 'undefined') {
      mixpanel.track(event, properties);
      posthog.capture(event, properties);
    }
  },
  reset: () => {
    if (typeof window !== 'undefined') {
      mixpanel.reset();
      posthog.reset();
    }
  },
};

const useMixpanel = () => {
  const [aliasCreated, setAliasCreated] = useState(false);
  const { userDetails } = useUserDetails();
  const router = useRouter();

  // Alias anonymous user
  useEffect(() => {
    if (typeof window !== 'undefined' && userDetails?.accountNumber) {
      const distinctId = mixpanel.get_distinct_id();
      mixpanel.alias(distinctId, userDetails?.accountNumber);
      setAliasCreated(true);
    }
  }, [userDetails?.accountNumber, window]);

  // Identify user after alias created
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      userDetails &&
      userDetails.accountNumber &&
      aliasCreated
    ) {
      mixpanel.identify(userDetails?.accountNumber);
      posthog.identify(userDetails?.accountNumber, {
        ...(userDetails?.email && { email: userDetails.email }),
      });
    }
  }, [userDetails, aliasCreated, window]);

  // Listen and report page events
  useEffect(() => {
    if (typeof window !== 'undefined') return;
    const onRouteChangeStarted = (url: string) => {
      url = url.replace(/\/m\//g, '/');
      mixpanel.track('Page View', { url });
      posthog.capture('Page View', { url });
    };
    router.events.on('routeChangeStart', onRouteChangeStarted);
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStarted);
    };
  }, [router, window]);
};

export default singletonHook(undefined, useMixpanel);
