export enum RecurringActionInterval {
  JUST_ONCE = 'just_once',
  WEEKLY = 'weekly',
  TWICE_A_MONTH = 'twice_a_month',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export type RecurringTransactionType = {
  amount: number;
};

export type RecurringAction = {
  id: string;
  modelType: string;
  objectId: string;
  nextExecutionDate: Date;
  performing: boolean;
  interval: RecurringActionInterval;
  object: RecurringTransactionType;
};
