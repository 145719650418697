import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  SEARCH_RESULTS_CONTAINER_EXIT,
  SEARCH_RESULTS_CONTAINER_VARIANTS,
} from 'pages/feed/components/SearchBar/components/SearchResultsContainer/utils';
import { useSearchBar } from 'pages/feed/components/SearchBar/context';
import React, { PropsWithChildren } from 'react';
import classes from './index.module.scss';

const SearchResultsContainer = ({ children }: PropsWithChildren) => {
  const { open } = useSearchBar();

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={classNames(classes.container)}
          variants={SEARCH_RESULTS_CONTAINER_VARIANTS}
          exit={SEARCH_RESULTS_CONTAINER_EXIT}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SearchResultsContainer;
