import useAuth from 'context/Auth';
import { useLandaApiAxios } from 'hooks/api/axios/landa_api';
import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useKycStore } from 'stores/KYCIdentities/kycIdentities';
import { KYCIdentities } from 'types/kyc-identities-types';

const init: {
  kycIdentities?: KYCIdentities;
  request: (() => Promise<void>) | undefined;
  loading: boolean;
} = {
  kycIdentities: undefined,
  request: undefined,
  loading: false,
};

const useKyc = () => {
  const { isLoggedIn } = useAuth();
  const { kycIdentities, setKycIdentities } = useKycStore();
  const [getKYCIdentity, { loading }] = useLandaApiAxios('/kyc_identities');

  const request = async () => {
    const response = await getKYCIdentity();
    setKycIdentities(response.data?.data?.[0]);
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  return {
    request,
    kycIdentities,
    loading: loading && !kycIdentities,
  };
};

export default singletonHook(init, useKyc);
