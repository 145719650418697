import { LANDA_DIALOGS } from 'components/dialogs/utils';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { usePathname, useSearchParams } from 'next/navigation';

const useLandaDialogs = () => {
  const { router } = useLandaRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const dialog = searchParams.get('dialog') as LANDA_DIALOGS;

  const openDialog = (
    dialog: LANDA_DIALOGS,
    params?: { [key: string]: string }
  ) => {
    const searchParams = new URLSearchParams({ dialog, ...params });
    router.push(pathname + `?${searchParams.toString()}`, undefined, {
      shallow: true,
    });
  };

  const closeDialog = () => {
    router.push(router.asPath.split('?')?.[0], undefined, {
      shallow: true,
    });
  };

  return {
    closeDialog,
    openDialog,
    dialog,
    searchParams: searchParams,
  };
};

export default useLandaDialogs;
