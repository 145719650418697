import classNames from 'classnames';
import { Property } from 'csstype';
import { HTMLAttributes, ReactNode } from 'react';
import classes from 'components/generics/texts/Typography/index.module.scss';
import TextAlign = Property.TextAlign;

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'headline'
  | 'bodyTitle'
  | 'body'
  | 'subtext';

export interface TypographyProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  color?: string;
  variant?: Variant;
  fontWeight?: number;
  children?: ReactNode;
  align?: TextAlign;
  decoration?: 'underline' | 'none';
  BaseComponent?: 'div' | 'span';
}

const Typography = ({
  children,
  variant,
  className,
  color,
  align,
  fontWeight,
  BaseComponent = 'div',
  decoration = 'none',
  ...props
}: TypographyProps) => {
  return (
    <BaseComponent
      {...props}
      className={classNames(
        classes.typography,
        classes[`variant-${variant}`],
        className
      )}
      style={{
        color,
        fontWeight,
        textAlign: align,
        textDecoration: decoration,
      }}
    >
      {children}
    </BaseComponent>
  );
};

export default Typography;
