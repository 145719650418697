import LandaDialogs from 'components/dialogs/LandaDialogs';
import HelpScout from 'components/integrations/HelpScout';
import AppLayout from 'components/layouts/AppLayout';
import LandaScripts from 'components/scripts/LandaScripts';
import { AuthProvider } from 'context/Auth';
import FontProvider from 'context/Fonts';
import { KYCProvider } from 'context/KYC';
import LandaProgressBar from 'context/LandaProgressBar';
import LandaToastContainer from 'context/LandaToastContainer';
import LandaIntegrations from 'hooks/integrations';
import 'keen-slider/keen-slider.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { RealViewportProvider } from 'next-real-viewport';
import type { AppProps } from 'next/app';
import 'rc-segmented/assets/index.css';
import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SingletonHooksContainer } from 'react-singleton-hook';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import 'styles/globals.scss';
import 'utils/array.extensions';
import 'utils/date.extensions';
import 'utils/string.extensions';
import 'utils/window.extensions';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
}

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <FontProvider>
      <SkeletonTheme
        baseColor="var(--color-fog)"
        highlightColor="var(--color-cloud)"
      >
        <AuthProvider>
          <KYCProvider>
            <RealViewportProvider>
              <LandaIntegrations />
              <LandaScripts />
              <HelpScout />
              <SingletonHooksContainer />
              <AppLayout route={router.route}>
                <PostHogProvider client={posthog}>
                  <LandaToastContainer />
                  <LandaProgressBar />
                  <Component {...pageProps} />
                  <LandaDialogs />
                </PostHogProvider>
              </AppLayout>
            </RealViewportProvider>
          </KYCProvider>
        </AuthProvider>
      </SkeletonTheme>
    </FontProvider>
  );
}

export default MyApp;
