import Image, { ImageProps as _ImageProps } from 'next/image';
import React from 'react';

export type ImageSize = {
  height: number;
  width: number;
};

export interface ResolutionProps {
  imageSize: ImageSize;
  imageResolution: ImageSize;
}

export interface ImageResolutionProps extends _ImageProps, ResolutionProps {}

const ResolutionImage = ({
  style,
  imageSize,
  imageResolution,
  ...props
}: ImageResolutionProps) => {
  const innerStyle = style ? { ...imageSize, ...style } : imageSize;

  return (
    <Image
      {...props}
      {...imageResolution}
      style={innerStyle}
    />
  );
};

export default ResolutionImage;
