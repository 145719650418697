import { URLS } from 'consts/api/urls';
import { identity, pickBy } from 'lodash';
import { DocumentNode } from 'graphql/language';
import { request, Variables } from 'graphql-request';
import { globalToken } from 'context/Auth/global-token';
import { DEFAULT_HEADERS } from 'hooks/api/SWR/headers';

const GRAPHQL_URL = `${URLS.API_ENDPOINT}/graphql`;

export const authedGraphqlFetcher = ([query, variables, headers]: [
  DocumentNode,
  object,
  object?
]) => {
  const defaultHeaders = {
    authorization: `Token ${globalToken}`,
    ...DEFAULT_HEADERS,
    ...(headers || {}),
  };
  const cleanedObject = pickBy(defaultHeaders, identity);
  return request(GRAPHQL_URL, query, variables as Variables, cleanedObject);
};
