import React, { useMemo, useState } from 'react';
import classes from './index.module.scss';
import { HEADER_MENUS, LINKS } from './consts';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import SubMenu from './components/SubMenu';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { HEADER_MENU } from 'consts/enums/headerMenu';
import { useRouter } from 'next/router';
import { RESIDENTS_HELP_CENTER } from 'consts/routes/urls';

const HeaderMenu = () => {
  const { navigate } = useLandaRouter();
  const [open, setOpen] = useState(false);
  const [menuStatus, setMenuStatus] = useState('');
  const toggleOpen = () => setOpen(!open);

  const isResident = useRouter().pathname.includes('/residents');

  const onMenuClickHandler = (menu: string) => {
    switch (menu) {
      case HEADER_MENU.ABOUT_US:
        navigate({
          pathname: LINKS.ABOUT_US,
        });
        break;
      case HEADER_MENU.HELP:
        if (isResident) {
          navigate({
            pathname: RESIDENTS_HELP_CENTER,
          });
        }
        toggleOpen();
        setMenuStatus(menu);
        break;
      case HEADER_MENU.LEARN:
        toggleOpen();
        setMenuStatus(menu);
        break;
    }
  };

  const filteredHeadersMenu = useMemo(() => {
    if (isResident) {
      return HEADER_MENUS.filter((menu) => menu.text !== HEADER_MENU.LEARN);
    } else {
      return HEADER_MENUS;
    }
  }, [isResident, HEADER_MENUS]);
  return (
    <Typography className={classes.container}>
      {filteredHeadersMenu.map(({ text, Icon, subMenu }, index) => {
        return (
          <Typography key={index.toString()}>
            <Button
              variant={'text'}
              onClick={() => onMenuClickHandler(text)}
            >
              {text}
              {Icon && !isResident && <Icon className={classes.arrow} />}
            </Button>
            {menuStatus === text && open && !isResident && (
              <SubMenu items={subMenu} />
            )}
          </Typography>
        );
      })}
    </Typography>
  );
};

export default HeaderMenu;
