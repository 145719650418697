import classNames from 'classnames';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onFinish: () => void;
  goBack: (numSteps?: number) => void;
  loading?: boolean;
}

const TransactionSummaryBottomSection = ({
  onFinish,
  goBack,
  loading,
  className,
}: Props) => {
  const { response, settlementText } = useTransaction();
  const error = !response?.success;
  const errorMessage = response?.errorMessage;

  const onButtonClick = error ? () => goBack(3) : onFinish;

  return (
    <div className={classNames(classes.container, className)}>
      <Typography className={classes.settlement}>
        {error ? errorMessage : `Settlement ${settlementText}`}
      </Typography>
      <Button
        className={classes.nextButton}
        onClick={onButtonClick}
        loading={loading}
      >
        {error ? 'Try Again' : 'Continue'}
      </Button>
    </div>
  );
};

export default TransactionSummaryBottomSection;
