import classNames from 'classnames';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import Typography from 'components/generics/texts/Typography';
import useBankName from 'hooks/requests/financials/useBankName';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const TransactionSummaryDetails = ({
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  const { transactionType, response } = useTransaction();
  const error = !response?.success;
  const { bankName, accountLastDigits } = useBankName();
  const isDeposit = transactionType === TransactionType.DEPOSIT;
  const title = isDeposit ? 'Deposit' : 'Withdrawal';
  const subtitle = error
    ? "Your transfer didn't go through"
    : bankName + ' ' + accountLastDigits;

  return (
    <div className={classNames(classes.container, className)}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </div>
  );
};

export default TransactionSummaryDetails;
