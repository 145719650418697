export enum FilterTypeKey {
  CATEGORIES = 'categories',
  PROPERTY_TYPES = 'propertyTypes',
  OFFERING_STATUSES = 'offeringStatuses',
  OCCUPANCY_STATUSES = 'occupancyStatuses',
  MARKETS = 'markets',
}

export enum PropertyStatuses {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  PRE_SECONDARY = 'pre_secondary',
  UNASSIGNED = 'unassigned',
  SOLD_FROM_INVENTORY = 'sold_from_inventory',
  INVENTORY = 'inventory',
}

export enum Categories {
  URBAN = 'Urban',
  SAFE_AREA = 'Safe area',
  COUNTRY_SIDE = 'Country side',
  BEST_SCHOOLS = 'Best schools',
  AVAILABLE_SHARES = 'Available Shares',
  RECENTLY_BUILD = 'Recently built',
}

export enum PropertyType {
  SFR = 'Single Family Home',
  MFR = 'Multi-Family Residence',
  DUPLEX = 'Duplex',
  TH = 'Town House',
}

export enum OccupancyStatuses {
  MARKETING = 'marketing',
  MARKETING_AND_WAITING_FOR_PHOTOS = 'marketing_and_waiting_for_photos',
  UNDER_ACQUISITION_REHAB = 'under_acquisition_rehab',
  UNDER_TURNS_REHAB = 'under_turns_rehab',
  EVICTION = 'eviction',
  LEASED = 'leased',
  HOLD_OVER = 'hold_over',
  SIGNED_LEASE = 'signed_lease',
  SIGNED = 'signed',
}
