import NextNProgress from 'nextjs-progressbar';
import React from 'react';

const LandaProgressBar = () => {
  return (
    <NextNProgress
      color="var(--color-basil)"
      height={2}
      options={{ showSpinner: false }}
    />
  );
};

export default LandaProgressBar;
