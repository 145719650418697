import { createAndResetOnLogout } from 'stores/utils';
import { PortfolioType } from 'types/portfolio-types';
import { persist } from 'zustand/middleware';

type PortfolioStore = {
  portfolio?: PortfolioType;
  setPortfolio: (portfolio: PortfolioType) => void;
};

export const usePortfolioStore = createAndResetOnLogout<PortfolioStore>()(
  persist(
    (set) => ({
      portfolio: undefined,
      setPortfolio: (newPortfolio: PortfolioType) =>
        set(() => ({ portfolio: newPortfolio })),
    }),
    { name: 'portfolio' }
  )
);
