import { useState } from 'react';

const useTransactionResponse = () => {
  const [response, setResponse] = useState({
    success: true,
    errorMessage: '',
  });

  return {
    response,
    setResponse,
  };
};

export default useTransactionResponse;
