import {
  Affiliations,
  Employed,
  PoliticallyExposedPerson,
  SelectOption,
} from 'types';

export const RELATIONSHIP_OPTIONS = {
  single_or_dating: 'Single',
  married: 'Married',
  domestic_partner: 'Domestic Partner',
  widowed: 'Widowed',
  divorced: 'Divorced',
};

export const NET_WORTH_OPTIONS = {
  under_5: 'Under $5,000',
  from_5_to_25: '$5,001-$25,000',
  from_25_to_50: '$25,001-$50,000',
  from_50_to_100: '$50,001-$100,000',
  from_100_and_above: 'Over $100,000',
};

export const EMPLOYMENT_OPTIONS = {
  employed: 'Employed',
  self_employed: 'Self-employed',
  unemployed: 'Unemployed',
  retired: 'Retired',
  student: 'Student',
};

export const ANNUAL_INCOME_OPTIONS = {
  under_25: 'Under $25,000',
  from_25_to_75: '$25,001-$75,000',
  from_75_to_100: '$75,001-$100,000',
  from_100_to_250: '$100,001-$250,000',
  joint_250k_and_above: 'Over $250,001',
};

export const RISK_TOLERANCE_OPTIONS = {
  very: 'Conservative - slow but steady',
  somewhat: 'Moderate - it’s all about balance',
  not_so_much: 'Aggressive - pursuing potential',
};

export const FUND_OPTIONS = {
  income: 'Income',
  savings: 'Retirement Savings',
  gift: 'Gift',
  other: 'Other',
};

export const AFFILIATIONS_OPTIONS = {
  PUBLICLY_TRADED_COMPANY:
    'Director, 10% stakeholder, or a senior officer of a publicly traded company',
  STOCK_OR_FINRA:
    'Employed by or associated with a member of either a stock exchange or FINRA',
  POLITICALLY_EXPOSED_PERSON: 'Politically exposed person',
  NONE: `These don't apply to me`,
};

export const INDUSTRY_OPTIONS = {
  UTILITIES: 'utilities',
  CONSTRUCTION: 'construction',
  MANUFACTURING: 'manufacturing',
  WHOLESALE_TRADE: 'wholesale_trade',
  RETAIL_TRADE: 'retail_trade',
  INFORMATION: 'information',
  FINANCE_AND_INSURANCE: 'finance_and_insurance',
  EDUCATIONAL_SERVICES: 'educational_services',
  ACCOMMODATION_AND_FOOD_SERVICES: 'accommodation_and_food_services',
  PUBLIC_ADMINISTRATION: 'public_administration',
  TRANSPORTATION_AND_WAREHOUSING: 'transportation_and_warehousing',
  HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'health_care_and_social_assistance',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'arts_entertainment_and_recreation',
  REAL_ESTATE_AND_RENTAL_AND_LEASING: 'real_estate_and_rental_and_leasing',
  ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES:
    'administrative_and_support_and_waste_management_and_remediation_services',
  AGRICULTURE_FORESTRY_FISHING_AND_HUNTING:
    'agriculture_forestry_fishing_and_hunting',
  MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION:
    'mining_quarrying_and_oil_and_gas_extraction',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES:
    'professional_scientific_and_technical_services',
  MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES:
    'management_of_companies_and_enterprises',
};

export const INDUSTRY_DISPLAY_OPTIONS = {
  UTILITIES: 'Utilities',
  CONSTRUCTION: 'Construction',
  MANUFACTURING: 'Manufacturing',
  WHOLESALE_TRADE: 'Wholesale trade',
  RETAIL_TRADE: 'Retail trade',
  INFORMATION: 'Information',
  EDUCATIONAL_SERVICES: 'Educational services',
  AGRICULTURE_FORESTRY_FISHING_AND_HUNTING:
    'Agriculture, Forestry, Fishing and Hunting',
  MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION:
    'Mining, Quarrying, and Oil and Gas Extraction',
  TRANSPORTATION_AND_WAREHOUSING: 'Transportation and Warehousing',
  FINANCE_AND_INSURANCE: 'Finance and Insurance',
  REAL_ESTATE_AND_RENTAL_AND_LEASING: 'Real Estate and Rental and Leasing',
  PUBLIC_ADMINISTRATION: 'Public Administration',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES:
    'Professional, Scientific, and Technical Services',
  MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES:
    'Management of Companies and Enterprises',
  HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'Health Care and Social Assistance',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, Entertainment, and Recreation',
  ACCOMMODATION_AND_FOOD_SERVICES: 'Accommodation and Food Services',
  ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES:
    'Administrative and Support and Waste Management and Remediation Services',
};

export const ROLE_OPTIONS = {
  ACCOUNTANT_CPA_BOOKKEEPER_CONTROLLER: 'accountant_cpa_bookkeeper_controller',
  ACTUARY: 'actuary',
  ADJUSTER: 'adjuster',
  ADMINISTRATOR: 'administrator',
  ADVERTISER_MARKETER_PR_PROFESSIONAL: 'advertiser_marketer_pr_professional',
  AGENT: 'agent',
  AIR_TRAFFIC_CONTROLLER: 'air_traffic_controller',
  AMBASSADOR_CONSULATE_PROFESSIONAL: 'ambassador_consulate_professional',
  ANALYST: 'analyst',
  APPRAISER: 'appraiser',
  ARCHITECT_DESIGNER: 'architect_designer',
  ARTIST_PERFORMER_ACTOR_DANCER: 'artist_performer_actor_dancer',
  ASSISTANT: 'assistant',
  ATHLETE: 'athlete',
  ATTENDANT: 'attendant',
  ATTORNEY_JUDGE_LEGAL_PROFESSIONAL: 'attorney_judge_legal_professional',
  AUCTIONEER: 'auctioneer',
  AUDITOR: 'auditor',
  BARBER_BEAUTICIAN_HAIRSTYLIST: 'barber_beautician_hairstylist',
  BROKER: 'broker',
  BUSINESS_EXECUTIVE_VP_DIRECTOR_ETC: 'business_executive_vp_director_etc',
  BUSINESS_OWNER: 'business_owner',
  CAREGIVER: 'caregiver',
  CARPENTER_CONSTRUCTION_WORKER: 'carpenter_construction_worker',
  CASHIER: 'cashier',
  CHEF_COOK: 'chef_cook',
  CHIROPRACTOR: 'chiropractor',
  CIVIL_SERVANT: 'civil_servant',
  CLERGY: 'clergy',
  CLERK: 'clerk',
  COMPLIANCE_REGULATORY_PROFESSIONAL: 'compliance_regulatory_professional',
  CONSULTANT: 'consultant',
  CONTRACTOR: 'contractor',
  COUNSELOR_THERAPIST: 'counselor_therapist',
  CUSTOMER_SERVICE_REPRESENTATIVE: 'customer_service_representative',
  DEALER: 'dealer',
  DEVELOPER: 'developer',
  DISTRIBUTOR: 'distributor',
  DOCTOR_DENTIST_VETERINARIAN_SURGEON: 'doctor_dentist_veterinarian_surgeon',
  DRIVER: 'driver',
  ENGINEER: 'engineer',
  EXAMINER: 'examiner',
  EXTERMINATOR: 'exterminator',
  FACTORY_WAREHOUSE_WORKER: 'factory_warehouse_worker',
  FARMER_RANCHER: 'farmer_rancher',
  FINANCIAL_PLANNER: 'financial_planner',
  FISHERMAN: 'fisherman',
  FLIGHT_ATTENDANT: 'flight_attendant',
  HUMAN_RESOURCES_PROFESSIONAL: 'human_resources_professional',
  IMPORTER_EXPORTER: 'importer_exporter',
  INSPECTOR_INVESTIGATOR: 'inspector_investigator',
  INTERN: 'intern',
  INVESTMENT_ADVISOR_INVESTMENT_MANAGER:
    'investment_advisor_investment_manager',
  INVESTOR: 'investor',
  IT_PROFESSIONAL_IT_ASSOCIATE: 'it_professional_it_associate',
  JANITOR: 'janitor',
  JEWELER: 'jeweler',
  LABORER: 'laborer',
  LANDSCAPER: 'landscaper',
  LENDING_PROFESSIONAL: 'lending_professional',
  MANAGER: 'manager',
  MECHANIC: 'mechanic',
  MILITARY_OFFICER_OR_ASSOCIATED: 'military_officer_or_associated',
  MORTICIAN_FUNERAL_DIRECTOR: 'mortician_funeral_director',
  NURSE: 'nurse',
  NUTRITIONIST: 'nutritionist',
  OFFICE_ASSOCIATE: 'office_associate',
  PHARMACIST: 'pharmacist',
  PHYSICAL_THERAPIST: 'physical_therapist',
  PILOT: 'pilot',
  POLICE_OFFICER_FIREFIGHTER_LAW_ENFORCEMENT_PROFESSIONAL:
    'police_officer_firefighter_law_enforcement_professional',
  POLITICIAN: 'politician',
  PROJECT_MANAGER: 'project_manager',
  REGISTERED_REP: 'registered_rep',
  RESEARCHER: 'researcher',
  SAILOR_SEAMAN: 'sailor_seaman',
  SALESPERSON: 'salesperson',
  SCIENTIST: 'scientist',
  SEAMSTRESS_TAILOR: 'seamstress_tailor',
  SECURITY_GUARD: 'security_guard',
  SOCIAL_WORKER: 'social_worker',
  TEACHER_PROFESSOR: 'teacher_professor',
  TECHNICIAN: 'technician',
  TELLER: 'teller',
  TRADESPERSON_CRAFTSPERSON: 'tradesperson_craftsperson',
  TRAINER_INSTRUCTOR: 'trainer_instructor',
  TRANSPORTER: 'transporter',
  UNDERWRITER: 'underwriter',
  WRITER_JOURNALIST_EDITOR: 'writer_journalist_editor',
};

export const ROLE_DISPLAY_OPTIONS = {
  ACCOUNTANT_CPA_BOOKKEEPER_CONTROLLER: 'Accountant cpa bookkeeper controller',
  ACTUARY: 'Actuary',
  ADJUSTER: 'Adjuster',
  ADMINISTRATOR: 'Administrator',
  ADVERTISER_MARKETER_PR_PROFESSIONAL: 'Advertiser marketer pr professional',
  AGENT: 'Agent',
  AIR_TRAFFIC_CONTROLLER: 'Air traffic controller',
  AMBASSADOR_CONSULATE_PROFESSIONAL: 'Ambassador consulate professional',
  ANALYST: 'Analyst',
  APPRAISER: 'Appraiser',
  ARCHITECT_DESIGNER: 'Architect designer',
  ARTIST_PERFORMER_ACTOR_DANCER: 'Artist performer actor dancer',
  ASSISTANT: 'Assistant',
  ATHLETE: 'Athlete',
  ATTENDANT: 'Attendant',
  ATTORNEY_JUDGE_LEGAL_PROFESSIONAL: 'Attorney judge legal professional',
  AUCTIONEER: 'Auctioneer',
  AUDITOR: 'Auditor',
  BARBER_BEAUTICIAN_HAIRSTYLIST: 'Aarber beautician hairstylist',
  BROKER: 'Broker',
  BUSINESS_EXECUTIVE_VP_DIRECTOR_ETC: 'Business executive vp director etc',
  BUSINESS_OWNER: 'Business owner',
  CAREGIVER: 'Caregiver',
  CARPENTER_CONSTRUCTION_WORKER: 'Carpenter construction worker',
  CASHIER: 'Cashier',
  CHEF_COOK: 'Chef cook',
  CHIROPRACTOR: 'Chiropractor',
  CIVIL_SERVANT: 'Civil servant',
  CLERGY: 'Clergy',
  CLERK: 'Clerk',
  COMPLIANCE_REGULATORY_PROFESSIONAL: 'Compliance regulatory professional',
  CONSULTANT: 'Consultant',
  CONTRACTOR: 'Contractor',
  COUNSELOR_THERAPIST: 'Counselor therapist',
  CUSTOMER_SERVICE_REPRESENTATIVE: 'Customer service representative',
  DEALER: 'Dealer',
  DEVELOPER: 'Developer',
  DISTRIBUTOR: 'Distributor',
  DOCTOR_DENTIST_VETERINARIAN_SURGEON: 'Doctor dentist veterinarian surgeon',
  DRIVER: 'Driver',
  ENGINEER: 'Engineer',
  EXAMINER: 'Examiner',
  EXTERMINATOR: 'Exterminator',
  FACTORY_WAREHOUSE_WORKER: 'Factory warehouse worker',
  FARMER_RANCHER: 'Farmer rancher',
  FINANCIAL_PLANNER: 'Financial planner',
  FISHERMAN: 'Fisherman',
  FLIGHT_ATTENDANT: 'Flight attendant',
  HUMAN_RESOURCES_PROFESSIONAL: 'Human resources professional',
  IMPORTER_EXPORTER: 'Importer exporter',
  INSPECTOR_INVESTIGATOR: 'Inspector investigator',
  INTERN: 'Intern',
  INVESTMENT_ADVISOR_INVESTMENT_MANAGER:
    'Investment advisor investment manager',
  INVESTOR: 'Investor',
  IT_PROFESSIONAL_IT_ASSOCIATE: 'IT professional IT associate',
  JANITOR: 'Janitor',
  JEWELER: 'Jeweler',
  LABORER: 'Laborer',
  LANDSCAPER: 'Landscaper',
  LENDING_PROFESSIONAL: 'Lending professional',
  MANAGER: 'Manager',
  MECHANIC: 'Mechanic',
  MILITARY_OFFICER_OR_ASSOCIATED: 'Military officer or associated',
  MORTICIAN_FUNERAL_DIRECTOR: 'Mortician funeral director',
  NURSE: 'Nurse',
  NUTRITIONIST: 'Nutritionist',
  OFFICE_ASSOCIATE: 'Office associate',
  PHARMACIST: 'Pharmacist',
  PHYSICAL_THERAPIST: 'Physical therapist',
  PILOT: 'Pilot',
  POLICE_OFFICER_FIREFIGHTER_LAW_ENFORCEMENT_PROFESSIONAL:
    'Police officer firefighter law enforcement professional',
  POLITICIAN: 'Politician',
  PROJECT_MANAGER: 'Project manager',
  REGISTERED_REP: 'Registered rep',
  RESEARCHER: 'Researcher',
  SAILOR_SEAMAN: 'Sailor seaman',
  SALESPERSON: 'Salesperson',
  SCIENTIST: 'Scientist',
  SEAMSTRESS_TAILOR: 'Seamstress tailor',
  SECURITY_GUARD: 'Security guard',
  SOCIAL_WORKER: 'Social worker',
  TEACHER_PROFESSOR: 'Teacher professor',
  TECHNICIAN: 'Technician',
  TELLER: 'Teller',
  TRADESPERSON_CRAFTSPERSON: 'Tradesperson craftsperson',
  TRAINER_INSTRUCTOR: 'Trainer instructor',
  TRANSPORTER: 'Transporter',
  UNDERWRITER: 'Underwriter',
  WRITER_JOURNALIST_EDITOR: 'Writer journalist editor',
};

export const INITIAL_EMPLOYMENT_INITIAL: Employed = {
  company: '',
  role: '',
  industry: '',
};
export const AFFILIATION_INITIAL: Affiliations = {
  POLITICALLY_EXPOSED_PERSON: false,
  PUBLICLY_TRADED_COMPANY: false,
  STOCK_OR_FINRA: false,
};
export const POLITICALLY_EXPOSED_PERSON_INITIAL: PoliticallyExposedPerson = {
  Name: '',
  'Job title': '',
  'Source of wealth': '',
  'Government branch': '',
};

export const industrySelectOptions: Array<SelectOption> = Object.entries(
  INDUSTRY_DISPLAY_OPTIONS
).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const roleSelectOptions: Array<SelectOption> = Object.entries(
  ROLE_DISPLAY_OPTIONS
).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const ACCREDITED_INVESTOR_OPTIONS = {
  accredited: 'Yes',
  non_accredited: 'No',
};

export const GOVERNMENT_BRANCH = {
  EXECUTIVE: 'executive',
  LEGISLATIVE: 'legislative',
  JUDICIAL: 'judicial',
  ADMINISTRATIVE: 'administrative',
  MILITARY: 'military',
  SENIOR_OFFICIAL_OF_A_MAJOR_POLITICAL_PARTY:
    'senior_official_of_a_major_political_party',
  SENIOR_EXECUTIVE_OF_A_FOREIGN_GOVERNMENT_OWNED_CORPORATION:
    'senior_executive_of_a_foreign_government_owned_corporation',
};

export const GOVERNMENT_BRANCH_OPTIONS = {
  [GOVERNMENT_BRANCH.ADMINISTRATIVE]: 'Administrative',
  [GOVERNMENT_BRANCH.JUDICIAL]: 'Judicial',
  [GOVERNMENT_BRANCH.LEGISLATIVE]: 'Legislative',
  [GOVERNMENT_BRANCH.MILITARY]: 'Military',
  [GOVERNMENT_BRANCH.SENIOR_EXECUTIVE_OF_A_FOREIGN_GOVERNMENT_OWNED_CORPORATION]:
    'Senior Executive of a Foreign Government-Owned Corporation',
  [GOVERNMENT_BRANCH.SENIOR_OFFICIAL_OF_A_MAJOR_POLITICAL_PARTY]:
    'Senior Official of a Major Political Party',
};

export const govermentSelectOptions: Array<SelectOption> = Object.entries(
  GOVERNMENT_BRANCH_OPTIONS
).map(([key, value]) => ({
  value: key,
  label: value,
}));
