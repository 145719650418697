import useLandaDialogs from 'components/dialogs/hooks/useLandaDialogs';
import { LANDA_DIALOGS } from 'components/dialogs/utils';
import { AnimatePresence, motion } from 'framer-motion';
import useDisableBodyScroll from 'hooks/logics/useDisableBodyScroll';
import useMounted from 'hooks/logics/useMounted';
import React from 'react';
import classes from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  name: LANDA_DIALOGS;
}

const BaseDialog = ({ name, children }: Props) => {
  const { dialog } = useLandaDialogs();
  useDisableBodyScroll(dialog === name);
  const mounted = useMounted();
  if (!mounted) return null;

  return (
    <AnimatePresence>
      {dialog === name && (
        <motion.div
          initial={{ y: '100vh' }}
          exit={{ y: '100vh' }}
          animate={{ y: 0 }}
          className={classes.container}
          transition={{ duration: 0.3, stiffness: 1000 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BaseDialog;
