import axios from 'axios';
import useOldAxios from 'hooks/api/old/useOldAxios';
import { HookReturnType, RequestConfig } from 'types/axios-types';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;

const useOldAuthedAxios = <BodyDataType>(): HookReturnType<BodyDataType> => {
  const { fetchData, response, error, isLoading } = useOldAxios();

  const authedFetchData = async (config: RequestConfig<BodyDataType>) => {
    const authedConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    };
    return fetchData(authedConfig);
  };

  return {
    error,
    response,
    isLoading,
    fetchData: authedFetchData,
  };
};

export default useOldAuthedAxios;
