import classNames from 'classnames';
import Stack, { StackProps } from 'components/containers/Stack/Stack';
import { PropsWithChildren } from 'react';
import classes from './index.module.scss';

const IntroLayout = ({
  children,
  className,
  ref,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <Stack
      ref={() => ref}
      direction="column"
      className={classNames(classes.layout, className)}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default IntroLayout;
