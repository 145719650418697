import React from 'react';
import { ToastContainer } from 'react-toastify';

const LandaToastContainer = () => {
  return (
    <ToastContainer
      limit={1}
      theme="dark"
      hideProgressBar
      closeButton={false}
      position="bottom-center"
      toastClassName={'toast'}
    />
  );
};

export default LandaToastContainer;
