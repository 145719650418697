import Typography, {
  TypographyProps,
} from 'components/generics/texts/Typography';
import { SkeletonLine } from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyCardLoader';
import { FC, ReactElement } from 'react';
import {
  DEFAULT_SKELETON_HEIGHT,
  DEFAULT_SKELETON_WIDTH,
} from 'styles/style_consts';
import { parseMoney } from 'utils/money_utils';
import classes from './index.module.scss';

interface Props extends Omit<TypographyProps, 'prefix'> {
  value: number | null | undefined;
  prefix?: string | FC | ReactElement;
  suffix?: string | FC | ReactElement;
  withDecimal?: boolean;
  round?: boolean;
  skeletonWidth?: string;
  skeletonHeight?: string;
  loading?: boolean;
}

const Money = ({
  value,
  prefix,
  suffix,
  withDecimal = false,
  round = false,
  loading = false,
  skeletonWidth = DEFAULT_SKELETON_WIDTH,
  skeletonHeight = DEFAULT_SKELETON_HEIGHT,
  ...props
}: Props) => {
  if (round) {
    value = value && Math.round(value);
  }

  return (
    <Typography
      {...props}
      style={{ display: 'flex' }}
    >
      {value === undefined || value === null || isNaN(value) || loading ? (
        <SkeletonLine
          containerClassName={classes.loading}
          width={skeletonWidth}
          height={skeletonHeight}
        />
      ) : (
        <>
          {prefix}
          {parseMoney(value, { forceDecimal: withDecimal })}
          {suffix}
        </>
      )}
    </Typography>
  );
};

export default Money;
