import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { fontsClassName } from 'styles/fonts';

export const APP_CONTAINER = 'APP_CONTAINER';
const FontProvider = ({
  children,
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={fontsClassName}
      id={APP_CONTAINER}
    >
      {children}
    </div>
  );
};

export default FontProvider;
