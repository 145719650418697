import classNames from 'classnames';
import ResolutionImage, {
  ResolutionProps,
} from 'components/generics/images/ResolutionImage';
import Typography from 'components/generics/texts/Typography';
import { HTMLAttributes } from 'react';
import { CityType } from 'types/property-types';
import classes from './index.module.scss';

const IMAGE_SIZE = { height: 3 * 16, width: 3 * 16 };
const IMAGE_RESOLUTION = { height: 8 * 16, width: 8 * 16 };

interface Props
  extends HTMLAttributes<HTMLDivElement>,
    Partial<ResolutionProps> {
  city: CityType;
}

const CityDetailsRow = ({
  city,
  className,
  imageSize,
  imageResolution,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(classes.container, className)}
      {...props}
    >
      <ResolutionImage
        className={classes.image}
        alt={city.name}
        src={city.photos[0].uri}
        imageSize={imageSize || IMAGE_SIZE}
        imageResolution={imageResolution || IMAGE_RESOLUTION}
      />
      <div className={classes.detailsContainer}>
        <Typography className={classes.title}>{city.name}</Typography>
      </div>
    </div>
  );
};

export default CityDetailsRow;
