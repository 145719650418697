import { createAndResetOnLogout } from 'stores/utils';
import { PropertyType } from 'types/property-types';

type PropertyObject = {
  [key: string]: PropertyType;
};
export type PropertyStore = {
  properties: PropertyObject;
  setProperties: (properties: PropertyType[]) => void;
  setProperty: (property: PropertyType) => void;
};

export const usePropertiesStore = createAndResetOnLogout<PropertyStore>(
  (set) => ({
    properties: {},
    setProperties: (properties: PropertyType[]) => {
      set(() => ({
        properties: properties.reduce(
          (propertyObj: PropertyObject, property) => {
            propertyObj[property.ticker] = property;
            return propertyObj;
          },
          {}
        ),
      }));
    },
    setProperty: (property: PropertyType) => {
      set((state) => {
        return {
          properties: {
            ...state.properties,
            [property.ticker]: {
              ...state.properties[property.ticker],
              ...property,
            },
          },
        };
      });
    },
  })
);
