import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { USER_DETAILS_QUERY } from 'graphql/account/queries/userDetailsQuery';
import { OptionsType } from 'hooks/api/graphql/functions/useLandaLazyQuery';
import { useLandaAuthedApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { CreateOrUpdateAppInfo } from 'stores/UserDetails/actions';
import useUserDetailsStore from 'stores/UserDetails/userDetails';
import { UserDetailsTypes } from 'types/user-details-types';

const init: {
  userDetails?: UserDetailsTypes;
  loading: boolean;
  request?: (() => Promise<QueryResult | null>) | undefined;
  initRequest?: (() => Promise<QueryResult | null>) | undefined;
  isTenant?: boolean;
  isActiveTenant?: boolean;
} = {
  userDetails: undefined,
  loading: true,
  request: undefined,
  initRequest: undefined,
  isTenant: false,
  isActiveTenant: false,
};

const useUserDetails = () => {
  const [initialRequestMade, setInitialRequestMade] = useState(false);
  const { isLoggedIn } = useAuth();
  const { userDetails, setUserDetails } = useUserDetailsStore();
  const [innerRequest, { loading }] = useLandaAuthedApiLazyQuery(
    USER_DETAILS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const isTenant = useMemo(() => !!userDetails?.isTenant, [userDetails]);

  const isActiveTenant = useMemo(
    () => !!userDetails?.isActiveTenant,
    [userDetails]
  );

  const request = useCallback(
    async (innerConfig: OptionsType = {}) => {
      if (!isLoggedIn) {
        return null;
      }

      try {
        const response = await innerRequest(innerConfig);

        if (response?.data?.account?.userDetails) {
          setUserDetails({
            ...response.data.account.userDetails,
            rentUser: { ...response.data.account.rentUser },
          });
          await CreateOrUpdateAppInfo(response.data.account.userDetails);
        }
        return response;
      } catch (error) {
        console.error('Error executing request:', error);
        return null;
      }
    },
    [isLoggedIn, innerRequest, setUserDetails]
  );

  const initRequest = useCallback(async () => {
    if (!isLoggedIn) return null;
    const response = await request();
    setInitialRequestMade(true);
    return response;
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !initialRequestMade) {
      initRequest();
    } else if (!isLoggedIn) {
      setInitialRequestMade(false);
    }
  }, [isLoggedIn]);

  return {
    request,
    initRequest,
    loading: loading && !userDetails,
    userDetails,
    isTenant,
    isActiveTenant,
  };
};

export default singletonHook(init, useUserDetails);
