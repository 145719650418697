import { gql } from '@apollo/client';

export const PERSONAL_INFORMATION_QUERY = gql`
  query PersonalInformationQuery {
    kycIdentity {
      name
      email
      phoneNumber {
        number
      }
      address {
        displayAddress
      }
    }
  }
`;
