import classNames from 'classnames';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import CheckMark from 'public/assets/icons/checkmark.svg';
import EscBig from 'public/assets/icons/esc_Big.svg';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

const TransactionSummaryCircle = ({
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  const { transactionType, response } = useTransaction();
  const error = !response?.success;
  const isDeposit = transactionType === TransactionType.DEPOSIT;
  const circleColor = error
    ? 'var(--color-black)'
    : isDeposit
    ? 'var(--color-basil)'
    : 'var(--color-tiger)';
  const MarkComponent = error ? EscBig : CheckMark;

  return (
    <div
      className={classNames(classes.container, className)}
      style={{ backgroundColor: circleColor }}
    >
      <MarkComponent />
    </div>
  );
};

export default TransactionSummaryCircle;
