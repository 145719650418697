import {
  defaultDescription,
  defaultThumbnail,
  defaultTitle,
  hostname,
} from 'consts/metaData';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

const Head: FC<{
  title?: string;
  description?: string;
  thumbnail?: string;
  themeColor?: string;
}> = ({
  title = defaultTitle,
  description = defaultDescription,
  thumbnail = defaultThumbnail,
  themeColor = '#ffffff',
}) => {
  const router = useRouter();

  return (
    <NextHead>
      <title>{`Landa | ${title}`}</title>
      <meta
        name="theme-color"
        //only pure hex color
        content={themeColor}
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, viewport-fit=cover"
        key="viewport"
      />
      <meta
        name="description"
        content={description}
        key="description"
      />
      <meta
        name="google"
        content="nositelinkssearchbox"
        key="google"
      />
      <meta
        property="og:url"
        content={`${hostname}${router.pathname}`}
        key="og:url"
      />
      <meta
        property="og:type"
        content="website"
        key="og:type"
      />
      <meta
        property="og:title"
        content={title}
        key="og:title"
      />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta
        property="og:image"
        content={`${hostname}/assets/${thumbnail}`}
        key="og:image"
      />
      {/* twitter */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        property="twitter:domain"
        content="landa.app"
        key="twitter:domain"
      />
      <meta
        property="twitter:url"
        content={`${hostname}${router.pathname}`}
        key="twitter:url"
      />
      <meta
        name="twitter:title"
        content={title}
        key="twitter:title"
      />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta
        name="twitter:image"
        content={`${hostname}/assets/${thumbnail}`}
        key="twitter:image"
      />
      <link
        rel="manifest"
        href="/manifest.json"
        key="manifest"
      />
      {/*----------------------------------FAVICON------------------------*/}
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicon/apple-icon-57x57.png"
        key="apple-touch-icon1"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicon/apple-icon-60x60.png"
        key="apple-touch-icon2"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicon/apple-icon-72x72.png"
        key="apple-touch-icon3"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicon/apple-icon-76x76.png"
        key="apple-touch-icon4"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/apple-icon-114x114.png"
        key="apple-touch-icon5"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/apple-icon-120x120.png"
        key="apple-touch-icon6"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/apple-icon-144x144.png"
        key="apple-touch-icon7"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/apple-icon-152x152.png"
        key="apple-touch-icon8"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-icon-180x180.png"
        key="apple-touch-icon9"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/android-icon-192x192.png"
        key="icon1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
        key="icon2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon/favicon-96x96.png"
        key="icon3"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
        key="icon4"
      />
      <meta
        name="msapplication-TileColor"
        content="#ffffff"
        key="msapplication-TileColor"
      />
      <meta
        name="msapplication-TileImage"
        content="favicon/ms-icon-144x144.png"
        key="msapplication-TileImage"
      />
      {/*----------------------------------SPLASH------------------------*/}
      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
        key="apple-mobile-web-app-capable"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-2048-2732.jpg"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image1"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1668-2388.jpg"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image2"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1536-2048.jpg"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image3"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1668-2224.jpg"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image4"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1620-2160.jpg"
        media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image5"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1290-2796.jpg"
        media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image6"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1179-2556.jpg"
        media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image7"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1284-2778.jpg"
        media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image8"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1170-2532.jpg"
        media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image9"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1125-2436.jpg"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image10"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1242-2688.jpg"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image11"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-828-1792.jpg"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image12"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-1242-2208.jpg"
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        key="apple-touch-startup-image13"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-750-1334.jpg"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image14"
      />
      <link
        rel="apple-touch-startup-image"
        href="/splash/apple-splash-640-1136.jpg"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        key="apple-touch-startup-image15"
      />
      {/*----------------------------------CANONICAL------------------------*/}
      <link
        rel={'canonical'}
        href={hostname + router.asPath.split('?')[0]}
      />
    </NextHead>
  );
};

export default Head;
