import { BankConnection, BankDetailsRows } from './types/bank_connection';

export const BANK_CONNECTION_OPTIONS = [
  {
    label: 'Checking',
    value: BankConnection.CHECKING,
  },
  {
    label: 'Savings',
    value: BankConnection.SAVINGS,
  },
];

export const BANK_DETAILS_ROWS = [
  {
    id: BankDetailsRows.ACCOUNT_NUMBER,
    placeholder: 'Account Number',
    type: 'number',
    validation: (value: string) => value.length >= 8 && value.length <= 17, // Allow 8-17 digits
    maxLength: 17, // Set maxLength to 17
    needEncryption: true,
    errorMessage: 'Account number must be between 8 and 17 characters',
  },
  {
    id: BankDetailsRows.ROUTING_NUMBER,
    placeholder: 'Routing Number',
    type: 'number',
    validation: (value: string) => value.length === 9,
    maxLength: 9,
    needEncryption: true,
    errorMessage: 'Routing number must be 9 characters',
  },
  {
    id: BankDetailsRows.ACCOUNT_NAME,
    placeholder: 'Account Name',
    type: 'default',
    validation: (value: string) => /^[a-zA-Z0-9 ]{1,22}$/.test(value),
    needEncryption: false,
    maxLength: 22,
    errorMessage: 'You can use only letters and numbers',
  },
];
