import { useAppSettingsLazyQuery } from 'hooks/api/graphql/app_settings';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import {
  AppSettings,
  DEFAULT_SETTINGS,
  init,
  query,
} from 'hooks/requests/useAppSettings/utils';
import { useCallback, useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const useAppSettings = () => {
  const { navigate } = useLandaRouter();
  const [appSettings, setAppSettings] = useState<AppSettings>(DEFAULT_SETTINGS);
  const [request] = useAppSettingsLazyQuery(query);
  const checkAppSettingsStatus = useCallback(async () => {
    try {
      const response = await request();
      const newAppSettings = response?.data?.getAppSettings;
      setAppSettings(newAppSettings);
      if (newAppSettings?.isDuringMaintenance) {
        navigate('/maintenance');
      }
    } catch (err) {
      console.error('Error fetching app settings', err);
    }
  }, []);

  useEffect(() => {
    checkAppSettingsStatus();
  }, []);

  return {
    appSettings,
    loading: appSettings === undefined,
  };
};

export default singletonHook(init, useAppSettings);
