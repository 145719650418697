import { gql } from '@apollo/client';

export const CREATE_MICRO_DEPOSIT = gql`
  mutation VerifyMicroDeposits(
    $firstDeposit: Float
    $secondDeposit: Float
    $bankComment: String
  ) {
    verifyMicroDeposits(
      firstDeposit: $firstDeposit
      secondDeposit: $secondDeposit
      bankComment: $bankComment
    ) {
      ok
    }
  }
`;
