export enum HEADER_MENU {
  ABOUT_US = 'About us',
  HELP = 'Help',
  LEARN = 'Learn',
}

export enum HEADER_HELP_SUB_MENU {
  LEGAL = 'Legal',
  SEC_FILINGS = 'SEC Filings ',
  CONTACT_US = 'Contact Us',
  CAREERS = 'Careers',
}

export enum HEADER_LEARN_SUB_MENU {
  EDUCATION_CENTER = 'Education Center',
  HELP_CENTER = 'Help Center',
  BLOG = 'Blog',
  DICTIONARY = 'Dictionary',
  MORE_REAL_ESTATE = 'More Real Estate',
}
