import { authedGraphqlFetcher } from 'hooks/api/SWR/graphql/fetchers/authedGraphqlFetcher';
import { graphqlFetcher } from 'hooks/api/SWR/graphql/fetchers/graphqlFetcher';
import { realEstateGraphqlFetcher } from 'hooks/api/SWR/graphql/fetchers/realEstateGraphqlFetcher';

export type LandaGQLFetcher = any;

export const GQL_FETCHERS = {
  GQL_FETCHER: graphqlFetcher,
  AUTHED_GQL_FETCHER: authedGraphqlFetcher,
  REAL_ESTATE_GQL_FETCHER: realEstateGraphqlFetcher,
};
