import classNames from 'classnames';
import classes from 'components/generics/buttons/IconButton/index.module.scss';
import ArrowBackIcon from 'public/assets/icons/chevron_left.svg';
import InfoIcon from 'public/assets/icons/circled_info.svg';
import DownloadIcon from 'public/assets/icons/download.svg';
import CloseIcon from 'public/assets/icons/esc.svg';
import FilterIcon from 'public/assets/icons/filter.svg';
import MapIcon from 'public/assets/icons/map_fold.svg';
import SearchIcon from 'public/assets/icons/search.svg';
import SendIcon from 'public/assets/icons/send_up.svg';
import { ButtonHTMLAttributes, DetailedHTMLProps, memo, useMemo } from 'react';
import { SVGComponent } from 'types';

type IconDirection = 'top' | 'bottom' | 'left' | 'right';
type IconButtonVariants =
  | 'back'
  | 'search'
  | 'download'
  | 'share'
  | 'send'
  | 'close'
  | 'filter'
  | 'map'
  | 'info';

const ICON_SRC: Record<IconButtonVariants, SVGComponent> = {
  back: ArrowBackIcon,
  search: SearchIcon,
  close: CloseIcon,
  send: SendIcon,
  download: DownloadIcon,
  filter: FilterIcon,
  share: SearchIcon,
  map: MapIcon,
  info: InfoIcon,
};

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: keyof typeof ICON_SRC;
  direction?: IconDirection;
  iconClassName?: string;
}

const IconButton = ({
  variant = 'back',
  direction = 'left',
  onClick,
  className,
  iconClassName,
  color,
  ...props
}: Props) => {
  const Icon: SVGComponent = useMemo(() => ICON_SRC[variant], [variant]);

  return (
    <button
      {...props}
      color={color}
      className={classNames(classes.container, className)}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'unset' }}
    >
      <Icon
        className={classNames(classes[`direction-${direction}`], iconClassName)}
        fill={color}
      />
    </button>
  );
};

export default memo(IconButton);
