import { create } from 'zustand';

interface LayoutStoreProps {
  navBarHeight?: number;
  setNavBarHeight: (height: number) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

const useLayoutStore = create<LayoutStoreProps>((set) => {
  return {
    navBarHeight: undefined,
    drawerOpen: true,
    setNavBarHeight: (height) => {
      set(() => ({
        navBarHeight: height,
      }));
    },
    setDrawerOpen: (open) => {
      set(() => ({
        drawerOpen: open,
      }));
    },
  };
});

export default useLayoutStore;
