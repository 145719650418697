import * as Sentry from '@sentry/nextjs';
import { ModeOfOperation, utils } from 'aes-js';
import { SSN_FAILED, SSN_OR_EIN_COMPLETED } from 'consts/analytics/KYCEvents';
import { RequestMethods } from 'consts/enums/requests';
import { SIGNUP_ROUTE } from 'consts/routes/urls';
import useAuth from 'context/Auth';
import useOldAuthedAxios from 'hooks/api/old/useOldAuthedAxios';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import { pbkdf2Sync } from 'pbkdf2';
import { useState } from 'react';

const AES_ENCRYPTION_LENGTH = 16;
const AES_ENCRYPTION_COST = 2500;
const VALIDATE_SSN_PATH = 'api/check_ssn_availability';

export const useValidateSSN = () => {
  const [ssnEncryptionError, setSsnEncryptionError] = useState<string | null>(
    null
  );
  const { token } = useAuth();
  const { navigate } = useLandaRouter();
  const { userDetails } = useUserDetails();
  const {
    fetchData: validateSSN,
    error: ssnValidationError,
    isLoading: isValidating,
  } = useOldAuthedAxios();

  const encrypt = (ssn: string) => {
    if (!token || !userDetails?.username) {
      setSsnEncryptionError('You are not signed in');
      return navigate(SIGNUP_ROUTE);
    }
    const byteSSN = utils.utf8.toBytes(ssn);
    const byteKey = pbkdf2Sync(
      token,
      userDetails?.username,
      AES_ENCRYPTION_COST,
      AES_ENCRYPTION_LENGTH
    );
    const aesCtr = new ModeOfOperation.ctr(byteKey);
    const encryptedBytes = aesCtr.encrypt(byteSSN);
    return utils.hex.fromBytes(encryptedBytes);
  };

  const validate = async (encryptedSSN: string) => {
    try {
      const response = await validateSSN({
        method: RequestMethods.POST,
        url: VALIDATE_SSN_PATH,
        data: {
          data: {
            type: 'check_ssn_availability',
            attributes: {
              ssn_or_ein: { cipher: encryptedSSN },
            },
          },
        },
      });
      if (response?.status === 200) {
        analytics.track(SSN_OR_EIN_COMPLETED);
        return true;
      } else {
        analytics.track(SSN_FAILED);
        setSsnEncryptionError('Invalid SSN');
        return false;
      }
    } catch (error) {
      analytics.track(SSN_FAILED);
      Sentry.captureException(error);
    }
  };

  return {
    encrypt,
    validate,
    ssnEncryptionError,
    ssnValidationError,
    isValidating,
  };
};
