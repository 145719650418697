import {
  abbreviateNumber,
  roundDecimal,
  roundToThousands,
} from 'utils/number.extensions';

type Params = {
  withCentsDisplay?: boolean;
  abbreviate?: boolean;
  showDecimal?: boolean;
  forceDecimal?: boolean;
  withPlusSign?: boolean;
  noParse?: boolean;
  originalString?: boolean;
  removeMoneySign?: boolean;
  isDividend?: boolean;
};

const defaultOptions: Params = {
  withCentsDisplay: false,
  abbreviate: false,
  showDecimal: true,
  forceDecimal: true,
  withPlusSign: false,
  noParse: false,
  originalString: false,
  removeMoneySign: false,
  isDividend: false,
};

export const parseMoney = (amount = 0, options: Params = {}) => {
  const innerOptions = { ...defaultOptions, ...options };
  let innerAmount = 0;
  let suffix = null;
  let isNegative;
  let isCents;
  let stringAmount;
  const absAmount = Math.abs(amount) || 0;

  if (!innerOptions?.withCentsDisplay || absAmount >= 1 || absAmount === 0) {
    innerAmount = absAmount;
    isNegative = amount <= -0.01;
    isCents = false;
  } else {
    innerAmount = (absAmount * 100).floorToCents();
    isNegative = amount <= -0.01;
    isCents = true;
  }

  if (innerOptions?.abbreviate && !isCents) {
    const { amount: abbAmount, suffix: abbAppendix } = abbreviateNumber(
      absAmount,
      true
    );
    innerAmount = abbAmount;
    suffix = abbAppendix;
  }

  const flooredAmount = isCents ? innerAmount : roundDecimal(innerAmount || 0);
  const flooretToThousand = innerOptions.isDividend
    ? roundToThousands(innerAmount || 0).toFixed(3)
    : innerAmount.toFixed(2);

  if (innerOptions?.originalString) {
    stringAmount = amount.toString();
  } else if (innerOptions?.noParse) {
    stringAmount = flooredAmount.toString();
  } else if (innerOptions?.showDecimal && flooredAmount % 1 !== 0) {
    stringAmount = flooretToThousand;
  } else if (innerOptions?.forceDecimal) {
    stringAmount = flooretToThousand;
  } else {
    stringAmount = parseInt(flooredAmount.toString()).toString();
  }

  stringAmount = stringAmount.addCommaToNumbers();

  if (!innerOptions?.removeMoneySign) {
    if (isCents) {
      stringAmount += '¢';
    } else {
      stringAmount = '$' + stringAmount;
    }
  }

  if (isNegative) {
    stringAmount = '-' + stringAmount;
  } else if (innerOptions?.withPlusSign) {
    stringAmount = '+' + stringAmount;
  }

  if (suffix) {
    stringAmount += suffix;
  }

  return stringAmount;
};
