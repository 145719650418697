import { KeyCodes } from 'hooks/logics/useOnKeyPress/utils';
import { useEffect } from 'react';

export const useOnKeyPress = (callback: () => any, key?: KeyCodes) => {
  if (!key) key = KeyCodes.Enter;

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) =>
      event.key === key && callback();

    window?.addEventListener('keypress', onKeyPress);
    return () => window?.removeEventListener('keypress', onKeyPress);
  }, [callback]);
};
