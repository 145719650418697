import useAuth from 'context/Auth';
import { useLandaApiAxios } from 'hooks/api/axios/landa_api';
import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { FinancialConnections } from 'types/financial-connections-types';
import { useFinancialsStore } from '../../../stores/FinancialIdentities/financialIdentities';
import { FinancialIdentities } from '../../../types/financial-identities-types';

const init: {
  financialConnections?: FinancialConnections[];
  financialIdentities?: FinancialIdentities;
  request: (() => Promise<void>) | undefined;
  loading: boolean;
  loadingBankConnection: boolean;
} = {
  financialConnections: undefined,
  financialIdentities: undefined,
  request: undefined,
  loading: false,
  loadingBankConnection: false,
};

const useFinancials = () => {
  const { isLoggedIn } = useAuth();
  const {
    financialIdentities,
    setFinancialIdentities,
    financialConnections,
    setFinancialConnections,
  } = useFinancialsStore();

  const [requestIdentities, { loading: loadingIdentities }] = useLandaApiAxios(
    '/v2/financial_identities'
  );
  const [requestConnections, { loading: loadingConnections }] =
    useLandaApiAxios('/financial_connections');

  const initializeIdentities = async () => {
    const response = await requestIdentities();
    setFinancialIdentities(response.data?.data?.[0]);
  };

  const initializeConnections = async () => {
    const response = await requestConnections();
    setFinancialConnections(response.data?.data);
  };

  const request = async () => {
    await initializeIdentities();
    await initializeConnections();
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  return {
    financialConnections,
    financialIdentities,
    request,
    loadingBankConnection:
      loadingConnections ||
      loadingIdentities ||
      !financialConnections ||
      !financialIdentities,
    loading:
      (loadingConnections || loadingIdentities) &&
      !financialConnections &&
      !financialIdentities,
  };
};

export default singletonHook(init, useFinancials);
