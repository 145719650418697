import useFinancials from 'hooks/requests/financials/useFinancials';

const useHasBankConnection = () => {
  const { financialIdentities, loading } = useFinancials();
  const hasBankConnection =
    !!financialIdentities?.relationships?.default_external_financial_connection
      ?.data;
  return { hasBankConnection, loading };
};

export default useHasBankConnection;
